import cn from 'classnames'
import React, {useState} from 'react'

import {ErrorMessage} from '../../index'
import st from '../inputs.module.scss'

interface SelectProps {
  className?: string
  selectContainerClassName?: string
  placeholder?: string
  id: string
  label?: string
  error?: string
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  value?: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLSelectElement>) => void
  options: {name: string; value: string}[]
  withFormik?: boolean
  labelClassName?: string
}

const Select = ({
  className,
  selectContainerClassName,
  label,
  labelClassName,
  placeholder,
  error,
  id,
  options,
  value,
  onChange,
  onBlur,
  ...attrs
}: SelectProps) => {
  const [isDefault, setIsDefault] = useState(!value)
  return (
    <div className={cn(st.commonContainer, selectContainerClassName)}>
      {label && (
        <label htmlFor={id} className={cn(st.label, labelClassName)}>
          {label}
        </label>
      )}
      <div className={st.commonWrap}>
        <select
          id={id}
          className={cn(st.common, className, isDefault && st.commonDefault)}
          value={value}
          onChange={(e) => {
            onChange(e)
            setIsDefault(false)
          }}
          onBlur={onBlur}
          {...attrs}
        >
          <option value="" disabled className={st.placeholder}>
            {placeholder}
          </option>
          {options.map((option, i) => {
            return (
              <option className={cn(st.common, className)} key={i} value={option.value}>
                {option.name}
              </option>
            )
          })}
        </select>
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

export default Select
