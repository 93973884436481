const endorsements = [
  {
    id: 'ethiopia',
    description: 'Federal Ministry of Health, Ethiopia',
    imageUrl: `${process.env.PUBLIC_URL}./images/guidelines/moh_ethiopia.png`,
  },
  {
    id: 'malawi',
    description: 'Government of Malawi',
    imageUrl: `${process.env.PUBLIC_URL}./images/guidelines/malawi.png`,
  },
  {
    id: 'nigeria',
    description: 'Federal Ministry of Health, Nigeria',
    imageUrl: `${process.env.PUBLIC_URL}./images/guidelines/nigeria.png`,
  },
  {
    id: 'tanzania',
    description: 'Ocean Road Cancer Institute, Tanzania',
    imageUrl: `${process.env.PUBLIC_URL}./images/guidelines/ocean-road-cancer-in.png`,
  },
  {
    id: 'uganda',
    description: 'Uganda Cancer Institute',
    imageUrl: `${process.env.PUBLIC_URL}./images/guidelines/uci-logo.png`,
  },
  {
    id: 'zambia',
    description: 'Cancer Diseases Hospital, Ministry of Health, Zambia',
    imageUrl: `${process.env.PUBLIC_URL}./images/guidelines/zambia-cdh-logo.png`,
  },
]

export {endorsements}
