import React from 'react'

interface DragEmitterProps {
  isDragging: boolean
}

export const DragEmitter = ({isDragging}: DragEmitterProps) => {
  return (
    <svg width="15px" height="25px" viewBox="0 0 15 25" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <title>Group</title>
      <g id="Screens-Android" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="admin-entry" transform="translate(-321.000000, -457.000000)" fill={isDragging ? '#B35715' : '#868C91'}>
          <g id="Group" transform="translate(321.000000, 457.000000)">
            <circle id="Oval" cx="2.5" cy="2.5" r="2.5" />
            <circle id="Oval" cx="12.5" cy="2.5" r="2.5" />
            <circle id="Oval" cx="2.5" cy="12.5" r="2.5" />
            <circle id="Oval" cx="2.5" cy="22.5" r="2.5" />
            <circle id="Oval" cx="12.5" cy="22.5" r="2.5" />
            <circle id="Oval" cx="12.5" cy="12.5" r="2.5" />
          </g>
        </g>
      </g>
    </svg>
  )
}
