export const homeDescriptionText =
  'At the center of our work is the African oncology community, who sets our agenda and priorities. The\n' +
  'African Cancer Coalition is a growing body of over 100 oncology experts representing 13 countries in\n' +
  'Sub-Saharan Africa. These leaders provide expertise and drive local and regional adoption of quality\n' +
  'improvement initiatives.'

export const pagePreviewText =
  'Allied Against Cancer works to improve evidence-based practice by putting technical resources in the hands\n' +
  'of practitioners.'

export const ourImpactText = {
  firstColumn:
    'African health workers trained in assessment and basic pain management according to the World Health\n' +
    'Organization guidelines',
  secondColumn:
    'NCCN Harmonized Guidelines™ for Sub-Saharan Africa,  which cover >95% of cancer incidence in Sub-Saharan\n' +
    'Africa',
  thirdColumn:
    'Countries with 81% of all cancer incidence in Sub-Saharan Africa able to access quality affordable medicines through the Cancer Access Partnership',
}
