import React, {useState} from 'react'
import Modal from 'react-modal'
import cx from 'classnames'
import {Close as CloseIcon} from '@material-ui/icons'
import {FormControl, MenuItem, Select, TextField, Typography, Box} from '@material-ui/core'
import Loader from 'react-loader-spinner'
import {Button} from '../../../../../components/buttons'
import {MidtierService} from '../../../../../services'
import {validator} from '../../../../../utils/validation/validator'
import {useStyles} from './styles'

interface ResourceModal {
  isOpen: boolean
  closeModal: () => void
  updateTable: () => void
}

const modal = {
  content: {
    top: '5%',
    left: '30%',
    right: '20%',
    height: 'auto',
    minHeight: '390px',
  },
}

const options = [
  {name: 'Reference', value: 'reference'},
  {name: 'Resource', value: 'Resource'},
]

interface ReferenceAndResource {
  closeModal: () => void
  updateTable: () => void
}

interface ErrorProps {
  resourceName?: string
  resourceFile?: string
  referenceName?: string
  referenceUrl?: string
}

const Reference = ({closeModal, updateTable}: ReferenceAndResource) => {
  const classes = useStyles()
  const [referenceName, setReferenceName] = useState<string>('')
  const [referenceUrl, setReferenceUrl] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  const errors: ErrorProps = validator.rules({
    referenceName: validator.validate(referenceName).required(),
    referenceUrl: validator.validate(referenceUrl).required().url(),
  })

  const addReference = async () => {
    try {
      setIsLoading(true)
      await MidtierService.saveReference({
        fileLocation: encodeURI(referenceUrl),
        fileName: referenceName,
      })
      setIsLoading(false)
      updateTable()
      closeModal()
    } catch (e) {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.referenceName})}>
        Reference Name
      </Typography>
      <FormControl className={classes.inputContainer}>
        <TextField
          className={classes.input}
          variant="outlined"
          value={referenceName}
          onChange={(e: any) => setReferenceName(e.target.value)}
        />
      </FormControl>
      <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.referenceUrl})}>
        Reference Url
      </Typography>
      <FormControl className={classes.inputContainer}>
        <TextField
          className={classes.input}
          variant="outlined"
          value={referenceUrl}
          onChange={(e: any) => setReferenceUrl(e.target.value)}
        />
      </FormControl>
      <Box className={classes.button}>
        <Button
          label={
            isLoading ? (
              <Loader className={classes.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
            ) : (
              'Add Reference'
            )
          }
          onClick={addReference}
          disabled={Object.keys(errors).some((fieldName) => errors[fieldName])}
        />
      </Box>
    </Box>
  )
}

const Resource = ({closeModal, updateTable}: ReferenceAndResource) => {
  const classes = useStyles()
  const [resourceName, setResourceName] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined)

  const errors: ErrorProps = validator.rules({
    resourceName: validator.validate(resourceName).required(),
    resourceFile: validator.validate(resourceFile).required(),
  })

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file) {
      setResourceFile(file)
    }
  }

  const addResource = async () => {
    try {
      setIsLoading(true)
      resourceFile &&
        (await MidtierService.saveResource({
          fileName: resourceName,
          file: resourceFile,
        }))
      setIsLoading(false)
      updateTable()
      closeModal()
    } catch (e) {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.resourceName})}>
        Resource Name
      </Typography>
      <FormControl className={classes.inputContainer}>
        <TextField
          className={classes.input}
          variant="outlined"
          value={resourceName}
          onChange={(e: any) => setResourceName(e.target.value)}
        />
      </FormControl>
      <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.resourceFile})}>
        Resource PDF
      </Typography>
      <input type="file" accept="application/pdf" onChange={onFileChange} />
      <Box className={classes.button}>
        <Button
          label={
            isLoading ? (
              <Loader className={classes.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
            ) : (
              'Add Resource'
            )
          }
          onClick={addResource}
          disabled={resourceName.length === 0 || resourceFile === undefined}
        />
      </Box>
    </Box>
  )
}

const ResourceModal = ({isOpen, closeModal, updateTable}: ResourceModal) => {
  const classes = useStyles()
  const [type, setType] = useState<'reference' | 'resource'>('reference')
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={modal}
      contentLabel="Add Resource or Reference"
      ariaHideApp={false}
    >
      <Box className={classes.container}>
        <button className={classes.iconButtonStyle} onClick={closeModal}>
          <CloseIcon />
        </button>
        <Typography variant="body2" className={classes.typeTitle}>
          Choose Type
        </Typography>
        <Select
          id="table-data"
          value={type}
          onChange={(e: any) => setType(e.target.value)}
          placeholder="Type"
          variant="outlined"
          className={classes.select}
        >
          {options.map((option, i) => {
            return (
              <MenuItem key={i} value={option.value}>
                {option.name}
              </MenuItem>
            )
          })}
        </Select>
        {type === 'reference' ? (
          <Reference closeModal={closeModal} updateTable={updateTable} />
        ) : (
          <Resource closeModal={closeModal} updateTable={updateTable} />
        )}
      </Box>
    </Modal>
  )
}

export default ResourceModal
