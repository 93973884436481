import React from 'react'

import {Descriptive, Link, List, PagePreview, RightColumn, Text, Title} from '../../components'
import st from './PainManagement.module.scss'
import {painManagementText} from './constans'

const PainManagement = () => {
  return (
    <div className={st.painManagement}>
      <PagePreview
        title="Pain Management"
        description="Technical support to hospitals and health ministries to improve patient access to essential pain medicines."
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/pain_management.jpg`}
      />
      <Descriptive>
        <Text>{painManagementText}</Text>
      </Descriptive>
      <div
        className={st.painFooter}
        style={{backgroundImage: `url(${process.env.PUBLIC_URL}./images/management/pain_management_hans_eiskonen.jpg)`}}
      >
        <RightColumn
          title="25K"
          text="Health workers trained in assessment and basic pain management according to WHO guidelines"
        />
        <RightColumn title="56%" text="Decrease in patients reporting severe pain across 33 implementing hospitals" />
        <RightColumn
          title="300"
          text="Hospitals in Ethiopia identified by the Federal Ministry of Health to receive government funding to expand the Pain-Free Hospital Initiative"
        />
      </div>
    </div>
  )
}

export default PainManagement
