import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  adminTitle: {
    color: '#000000',
    marginBottom: '14px',
  },
  adminText: {
    textAlign: 'justify',
    lineHeight: '1.6',
  },
}))
