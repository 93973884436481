import React, {ChangeEvent} from 'react'
import {TextField} from '@material-ui/core'
import classNames from 'classnames'
import {useStyles} from './styles'
interface TextInputProps {
  isQuestionText?: boolean
  mode: string
  text: string
  onChange?: (e: ChangeEvent<any>) => void
  error?: boolean | undefined
  id?: string
  helperText?: React.ReactNode
  autoFocus?: boolean
  margin?: 'none' | 'normal' | 'dense' | undefined
}

const TextInput = ({
  isQuestionText,
  mode,
  text,
  onChange,
  error,
  id,
  helperText,
  autoFocus,
  margin,
}: TextInputProps) => {
  const classes = useStyles()
  return (
    <TextField
      id={id}
      onChange={onChange}
      value={text}
      disabled={mode !== 'edit'}
      multiline
      autoFocus={autoFocus}
      error={error}
      helperText={helperText}
      margin={margin || 'none'}
      className={classNames(isQuestionText ? classes['question-main-text-input'] : classes.textInput)}
    />
  )
}

export default TextInput
