import React from 'react'

import {Form as FormikForm, FormikProps, withFormik} from 'formik'
import cn from 'classnames'
import Loader from 'react-loader-spinner'
import {connect} from 'react-redux'
import {Button, Checkbox, Descriptive, Input, Select, Text, TextArea, Title} from '../../components'
import {validator} from '../../utils/validation/validator'
import {MidtierService} from '../../services'
import {addMessage} from '../../redux/alerts/actions'
import generateId from '../../utils/generateId'
import countries from '../../constants/countries'
import st from './ContactUs.module.scss'
import {commentType} from './constants'

interface ContactUsProps {
  name: string
  country: string
  email: string
  institution: string
  commentType: string
  comment: string
  approved: boolean
  addMessage: typeof addMessage
}

interface ContactUsValues {
  name: string
  country: string
  email: string
  institution: string
  commentType: string
  comment: string
  approved: boolean
}

// eslint-disable-next-line complexity
const Coalition = (props: FormikProps<ContactUsValues>) => {
  const submitForm = (event: {preventDefault: () => void}) => {
    event.preventDefault()
    props.submitForm()
  }

  return (
    <div className={st.contactUs}>
      <div className={st.overlay} />
      <Descriptive className={st.descriptive}>
        <div className={st.contactUsContent}>
          <div className={st.pageDescriptionBlock}>
            <div className={st.pageDescriptionInnerWrapper}>
              <Title className={st.descriptiveTitle} type="h4" color="white">
                Contact us
              </Title>
              <Text className={st.descriptionText}>Do you have any questions? We would love to hear from you.</Text>
            </div>
          </div>
          <div className={st.formBlock}>
            <FormikForm className={st.profileInfoForm}>
              <div className={cn(st.inputGroup)}>
                <Input
                  id="name"
                  value={props.values.name}
                  onChange={props.handleChange}
                  label="Name *"
                  onBlur={props.handleBlur}
                  labelClassName={st.inputLabel}
                  placeholder="Enter your name"
                  inputContainerClassName={st.inputContainer}
                  error={(props.errors && props.touched.name && props.errors.name) || ''}
                />
                <Select
                  id="country"
                  label="Country *"
                  labelClassName={st.selectLabel}
                  value={props.values.country}
                  placeholder="Choose your country"
                  selectContainerClassName={st.inputContainer}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  options={[...countries].sort((a, b) => a.name.localeCompare(b.name))}
                  error={(props.errors && props.touched.country && props.errors.country) || ''}
                />
              </div>
              <div className={cn(st.inputGroup)}>
                <Input
                  id="email"
                  value={props.values.email}
                  onChange={props.handleChange}
                  label="Email *"
                  onBlur={props.handleBlur}
                  labelClassName={st.inputLabel}
                  placeholder="Enter your email"
                  inputContainerClassName={st.inputContainer}
                  error={(props.errors && props.touched.email && props.errors.email) || ''}
                />
              </div>
              <div className={cn(st.inputGroup)}>
                <Input
                  id="institution"
                  value={props.values.institution}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Institution"
                  labelClassName={st.inputLabel}
                  placeholder="Enter your institution"
                  inputContainerClassName={st.inputContainer}
                  error={(props.errors && props.touched.institution && props.errors.institution) || ''}
                />
                <Select
                  id="commentType"
                  label="Comment type"
                  value={props.values.commentType}
                  labelClassName={st.selectLabel}
                  placeholder="Choose comment type"
                  selectContainerClassName={st.inputContainer}
                  onChange={props.handleChange}
                  options={commentType}
                  error={(props.errors && props.touched.commentType && props.errors.commentType) || ''}
                />
              </div>
              <div className={cn(st.inputGroup)}>
                <TextArea
                  id="comment"
                  value={props.values.comment}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Comment *"
                  labelClassName={st.inputLabel}
                  placeholder="Share your comment here"
                  textAreaContainerClassName={st.textAreaContainer}
                  error={(props.errors && props.touched.comment && props.errors.comment) || ''}
                />
              </div>
              <div className={st.checkBoxGroup}>
                <div className={st.checkboxWrap}>
                  <Checkbox
                    id="approved"
                    checked={props.values.approved}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    label={
                      <span className={st.checkBoxLabel}>
                        By checking this box, I agree to the processing of my personal data.
                      </span>
                    }
                    inputContainerClassName={st.checkboxContainer}
                    error={(props.errors && props.touched.approved && props.errors.approved) || ''}
                  />
                </div>
              </div>
              <div className={st.buttonsContainer}>
                <Button
                  type="submit"
                  onClick={submitForm}
                  label={
                    props.isSubmitting ? (
                      <Loader
                        className={st.spinner}
                        type="TailSpin"
                        color="white"
                        height={24}
                        width={24}
                        visible={true}
                      />
                    ) : (
                      'Submit'
                    )
                  }
                  className={st.buttons}
                  disabled={props.isSubmitting || !props.isValid}
                />
              </div>
            </FormikForm>
          </div>
        </div>
      </Descriptive>
    </div>
  )
}

const WithFormik = withFormik<ContactUsProps, ContactUsValues>({
  enableReinitialize: true,

  handleSubmit: async (values, {props, setSubmitting, resetForm}) => {
    try {
      const {name, country, email, institution, commentType, comment} = values
      setSubmitting(true)
      await MidtierService.sendContactUs({name, comment, commentType, country, institution, email})
      props.addMessage({message: 'Message has been sent.', id: generateId(), type: 'success'})
      setSubmitting(false)
      const resetData: ContactUsValues = {
        name: '',
        country: '',
        email: '',
        institution: '',
        commentType: '',
        comment: '',
        approved: false,
      }
      resetForm({values: resetData})
    } catch (e) {
      setSubmitting(false)
    }
  },

  mapPropsToValues: (props) => {
    return {
      name: props.name || '',
      country: props.country || '',
      email: props.email || '',
      institution: props.institution || '',
      commentType: props.commentType || '',
      comment: props.comment || '',
      approved: props.approved || false,
    }
  },

  validate: (values) => {
    return validator.rules({
      name: validator.validate(values.name).required().name(),
      country: validator.validate(values.country).required(),
      email: validator.validate(values.email).required().email(),
      institution: validator.validate(values.institution).optional(),
      commentType: validator.validate(values.commentType).optional(),
      comment: validator.validate(values.comment).required(),
      approved: validator.validate(values.approved).boolean(),
    })
  },
})(Coalition)

export default connect(null, {addMessage})(WithFormik)
