import {User} from '../../services/types'
import {AdminActionTypes, DEL_USERS, SET_ADMIN_EDIT_USER, SET_COALITION_REQUESTS, SET_USERS} from './types'

export function setUsers(users: User[]): AdminActionTypes {
  return {
    type: SET_USERS,
    payload: users,
  }
}

export function delUsers(): AdminActionTypes {
  return {
    type: DEL_USERS,
  }
}

export function setCoalitionRequests(coalitionRequests: number): AdminActionTypes {
  return {
    type: SET_COALITION_REQUESTS,
    payload: coalitionRequests,
  }
}

export function setAdminEditUser(isAdminEditUser: boolean): AdminActionTypes {
  return {
    type: SET_ADMIN_EDIT_USER,
    payload: isAdminEditUser,
  }
}
