import {AxiosInstance, AxiosRequestConfig, AxiosResponse} from 'axios'
import {DataTypes} from './MidtierService'
import {midtierAxios} from './api'

type MethodTypes = 'put' | 'post' | 'get' | 'delete'

export abstract class ServiceAbstract {
  protected static readonly axiosInstance: AxiosInstance = midtierAxios

  // TODO: make it generic instead of collecting all DataTypes
  protected static templateMethod = ({
    url,
    methodType,
    data,
    config,
  }: {
    url: string
    methodType: MethodTypes
    data?: DataTypes
    config?: AxiosRequestConfig
  }): Promise<AxiosResponse> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await ServiceAbstract.axiosInstance({
          ...config,
          method: methodType,
          url,
          data,
        })
        resolve(response)
      } catch (error) {
        reject(error)
      }
    })
  }
}
