import React from 'react'
import cn from 'classnames'
import st from './SideBar.module.scss'

interface SideBarProps {
  children?: React.ReactNode
  className?: string
}

const SideBar = ({children, className}: SideBarProps) => {
  return <div className={cn(st.sideBar, className)}>{children}</div>
}

export default SideBar
