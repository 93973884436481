import cn from 'classnames'
import React from 'react'

import st from './Text.module.scss'

interface TextProps {
  children: React.ReactNode
  className?: string
  styles?: React.CSSProperties
  alt?: boolean
  emphasis?: boolean
  center?: boolean
  error?: boolean
  success?: boolean
}
const Text = ({alt, className, emphasis, center, error, success, ...attrs}: TextProps) => {
  return (
    <p
      className={cn(
        className,
        st.text,
        {[st.alt]: alt},
        {[st.emphasis]: emphasis},
        {[st.center]: center},
        {[st.error]: error, [st.success]: success},
      )}
      style={attrs.styles}
    >
      {attrs.children}
    </p>
  )
}

Text.defaultProps = {
  center: false,
  emphasis: false,
  alt: false,
  styles: {},
}

export default Text
