import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
  },

  center: {
    display: 'flex',
    justifyContent: 'center',
  },

  iconButtonStyle: {
    border: 'none',
    background: 'none',
  },
})
