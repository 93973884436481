import {store} from '../index'
import {DEL_MESSAGE, ADD_MESSAGE, Message, AlertsActionTypes} from './types'

export function addMessage(message: Message): AlertsActionTypes {
  setTimeout(() => {
    store.dispatch(delMessage(message.id))
  }, 5000)
  return {
    type: ADD_MESSAGE,
    payload: message,
  }
}

export function delMessage(id: string): AlertsActionTypes {
  return {
    type: DEL_MESSAGE,
    payload: {id},
  }
}
