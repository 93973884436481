import React from 'react'
import {Button, DropdownContainer} from '../../../index'

import Text from '../../../Text'
import st from './BellDropdown.module.scss'

interface Props {
  closeDropdown: () => void
  coalitionRequests: number
}

const BellDropdown = ({closeDropdown, coalitionRequests}: Props) => {
  const isHaveCoalitionRequests = coalitionRequests > 0
  return (
    <DropdownContainer closeDropdown={closeDropdown} className={st.dropDownContainerStyles}>
      <div className={st.container}>
        {isHaveCoalitionRequests ? (
          <div className={st.container}>
            <Text className={st.font}>
              You have <span className={st.coalitionRequests}>{coalitionRequests}</span> new AAC members requests.
            </Text>
            <Button
              containerStyle={{
                marginLeft: '35px',
                marginBottom: '15px',
              }}
              label="Go to admin page"
              to="/profile/adminArea"
              external
            />
          </div>
        ) : (
          <Text className={st.font}>There are no new ACC members requests.</Text>
        )}
      </div>
    </DropdownContainer>
  )
}

export default BellDropdown
