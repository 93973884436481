import React from 'react'
import {connect} from 'react-redux'

import {Button, Descriptive} from '../../../components'
import {MyProfile} from '../../../pages'
import {delUser} from '../../../redux/user/actions'
import {delTokens} from '../../../utils/tokens'
import st from './Logoff.module.scss'

interface LogoffProps {
  delUser: typeof delUser
}

const Logoff = ({delUser}: LogoffProps) => {
  return (
    <Descriptive>
      <MyProfile.Title>Log off</MyProfile.Title>
      <Button
        label="Log Off"
        className={st.button}
        onClick={async () => {
          delUser()
          await delTokens()
        }}
      >
        Log off
      </Button>
    </Descriptive>
  )
}

export default connect(null, {delUser})(Logoff)
