import React from 'react'

import {Link} from '../../../../index'
import st from './OurWorkSubmenu.module.scss'

interface Props {
  onClick: () => void
}

const OurWorkSubmenu = ({onClick}: Props) => {
  return (
    <div className={st.submenuContainer}>
      <ul className={st.submenu}>
        <li className={st.listItem}>
          <Link to="/guidelines" className={st.link} onClick={onClick}>
            NCCN Harmonized Guidelines <span className={st.tm}>TM</span> for Sub-Saharan Africa
          </Link>
        </li>
        <li className={st.listItem}>
          <Link to="/chemosafe" className={st.link} onClick={onClick}>
            Safe handling of chemotherapy
          </Link>
        </li>
        <li className={st.listItem}>
          <Link to="/access-partnership" className={st.link} onClick={onClick}>
            Cancer Access Partnership
          </Link>
        </li>
        <li className={st.listItem}>
          <Link to="/pain-management" className={st.link} onClick={onClick}>
            Pain management
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default OurWorkSubmenu
