import cn from 'classnames'
import React from 'react'
import Slider from 'react-slick'
import {
  Button,
  Centered,
  Colored,
  Columns,
  Descriptive,
  ImageItem,
  Link,
  PagePreview,
  Parted,
  Text,
  Title,
} from '../../components'
import {homeDescriptionText, pagePreviewText, ourImpactText} from './constants'
import st from './Home.module.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

interface Props {
  style?: React.CSSProperties | undefined
}
const CustomNextArrow = (props: Props) => {
  return <div style={{...props.style}} {...props} className={st.customSclickNext} />
}
const CustomPrevArrow = (props: Props) => {
  return <div style={{...props.style}} {...props} className={st.customSclickPrev} />
}
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  nextArrow: <CustomNextArrow />,
  prevArrow: <CustomPrevArrow />,
  appendDots: (dots: '') => (
    <div
      style={{
        background: 'none',
        borderRadius: '10px',
        padding: '10px',
      }}
    >
      <ul style={{margin: '0px'}}> {dots} </ul>
    </div>
  ),
  customPaging: () => <button className={st.customDots} />,
}

const Carousel = () => {
  return (
    <Slider {...settings}>
      <div>
        <PagePreview
          className={st.pagePreview}
          title="Empowering the African oncology community to deliver high-quality cancer care."
          description={
            <>
              <p className={st.pagePreviewText}>{pagePreviewText}</p>
              <Button to="/programs" label="View Our Programs" />
            </>
          }
          bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/home.jpeg`}
        />
      </div>
      <div>
        <PagePreview
          className={st.pagePreview}
          title="New Cancer Access Partnership brings together Allied Against Cancer and pharmaceutical companies to provide world-class medications at affordable prices across sub-Saharan Africa."
          description={<Button to="/access-partnership" label="Read More" />}
          bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/home4.jpg`}
        />
      </div>
      <div>
        <PagePreview
          className={st.pagePreview}
          title="Safe handling of chemotherapy trainings are now available for download!"
          description={<Button to="/chemosafe" label="Download Trainings" />}
          bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/home2.jpg`}
        />
      </div>
      <div>
        <PagePreview
          className={st.pagePreview}
          titleStyle={{
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}
          title="Allied Against Cancer was highlighted in Fast Company’s 2020 World Changing Ideas for Europe, Middle East, and Africa. "
          description={
            <Button
              onClick={() =>
                window.open(
                  'https://www.fastcompany.com/90492169/world-changing-ideas-awards-2020-europe-middle-east-and-africa-finalists-and-honorable-mentions',
                  '_blank',
                )
              }
              label="Read More"
            />
          }
          bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/home3.jpg`}
        />
      </div>
    </Slider>
  )
}
//TODO  Check `Chemosafe mobile app` link
const Home = () => {
  const mailWindowOpened = () => window.open('mailto:allied@cancer.org', '_blank')

  return (
    <div className={st.home}>
      <Carousel />
      <Parted
        reverse
        imageUrl={`${process.env.PUBLIC_URL}./images/hero/who_we_are.jpeg`}
        description={
          <div className={cn(st.decsriptionInnerWrap, st.reverse)}>
            <Title type="h3">African Cancer Coalition</Title>
            <Text>{homeDescriptionText}</Text>
            <div className={st.buttons}>
              <Button label="View Members" to="/coalition" />
            </div>
          </div>
        }
      />
      <Parted
        imageUrl={`${process.env.PUBLIC_URL}./images/hero/what_we_work_on.jpeg`}
        description={
          <div className={st.decsriptionInnerWrap}>
            <Title type="h3">Tools and Resources</Title>
            <Text>
              <Link to="/guidelines" className={st.link}>
                NCCN Harmonized Guidelines <span className={st.tm}>TM</span>
              </Link>{' '}
              for Sub-Saharan Africa present optimal care and pragmatic approaches for treatment in resource-constrained
              settings.
            </Text>
            <Text>
              <Link to="/chemosafe" className={st.link}>
                ChemoSafe
              </Link>{' '}
              promotes safety and quality improvement for hazardous drug handling in health centers.
            </Text>
            <Text>
              {' '}
              <Link to="/chemosafe#mobile" className={st.link} section={true}>
                ChemoSafe mobile app
              </Link>{' '}
              enables hospitals to self-assess chemotherapy safety practices.
            </Text>
          </div>
        }
      />
      <Colored>
        <Colored.Title type="h2" alt>
          Our Impact
        </Colored.Title>
        <Columns number="3">
          <div>
            <Colored.Title type="h5" alt>
              25K
            </Colored.Title>
            <Text>{ourImpactText.firstColumn}</Text>
          </div>
          <div>
            <Colored.Title type="h5" alt>
              55
            </Colored.Title>
            <Text>{ourImpactText.secondColumn}</Text>
          </div>
          <div>
            <Colored.Title type="h5" alt>
              31
            </Colored.Title>
            <Text>{ourImpactText.thirdColumn}s</Text>
          </div>
        </Columns>
      </Colored>
      <Colored bgImageUrl={`${process.env.PUBLIC_URL}./images/hero/footer.jpg`}>
        <Title type="h2" alt className={st.center}>
          Who we are
        </Title>
        <Columns number="2">
          <Text className={st.twoColumnBlocks}>
            Allied Against Cancer is a collaboration among organizations committed to creating a future where every
            person with cancer receives high-quality treatment.
          </Text>
          <Text className={st.twoColumnBlocks}>
            We seek to address these disparities by advancing the practitioners, products, and processes integral to an
            efficient and effective cancer care system.
          </Text>
        </Columns>
        <Centered>
          <Button label="Read Our Story" to="/our-story" />
        </Centered>
      </Colored>
      <Descriptive>
        <div className={st.partnerImagesBlock}>
          <Title type="h2" className={st.center}>
            Founding partners
          </Title>
          <div className={st.partnersLinksWrapper}>
            <a
              className={st.partnersLinksLink}
              href="https://alliedagainstcancer.org/coalition"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ImageItem path="./images/our_story/africancancercoalition.png" style={{width: '100px'}} />
            </a>
            <a className={st.partnersLinksLink} href="https://www.cancer.org" rel="noopener noreferrer" target="_blank">
              <ImageItem path="./images/our_story/acs-rgb.svg" style={{width: '134px'}} />
            </a>
            <a
              className={st.partnersLinksLink}
              href="https://www.clintonhealthaccess.org/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ImageItem path="./images/our_story/clinton.png" style={{width: '140px'}} />
            </a>
            <a className={st.partnersLinksLink} href="https://www.NCCN.org" rel="noopener noreferrer" target="_blank">
              <ImageItem path="./images/our_story/nccn.svg" style={{width: '245px'}} />
            </a>
          </div>
        </div>
      </Descriptive>
      <div style={{display: 'flex', justifyContent: 'center', marginBottom: '3rem'}}>
        <Button label="Contact Us" onClick={mailWindowOpened} />
      </div>
    </div>
  )
}

export default Home
