import React from 'react'
import {Button, Text} from '../../../../../components'
import {setUser} from '../../../../../redux/user/actions'
import {setTokens} from '../../../../../utils/tokens'
import {Tokens} from '../../../../../services/types'
import st from './SmallForms.module.scss'

interface Props {
  handleResponse: (value: boolean) => void
  setTokensInState: typeof setTokens
  setUserInState: typeof setUser
  tokens: Tokens
  text: string
}

const ApprovedDialog: React.FunctionComponent<Props> = ({text}) => {
  return (
    <div className={st.approvedDialog}>
      <Text className={st.text}>{text}</Text>
      <div className={st.buttons}>
        <Button label="Confirm" to="/profile/settings" />
      </div>
    </div>
  )
}

export default ApprovedDialog
