import React, {ChangeEvent, FormEvent, useState} from 'react'
import cn from 'classnames'
import Loader from 'react-loader-spinner'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {bindActionCreators, Dispatch} from 'redux'
import {MidtierService} from '../../../services'
import {Button, LoginInput, Title} from '../../index'
import {setUser} from '../../../redux/user/actions'
import {addMessage} from '../../../redux/alerts/actions'
import generateId from '../../../utils/generateId'
import {AppActions} from '../../../redux'
import {setTokens} from '../../../utils/tokens'
import st from './LoginForm.module.scss'

interface LoginTooltipProps extends AppReduxInjectedProps {
  onChangePasswordClick?: () => void
  closeDropdown?: () => void
  hideCreateAndForgot?: boolean
  className?: string
  afterSubmitting?: any
}

const LoginForm = ({
  setUser: setUserInState,
  onChangePasswordClick,
  closeDropdown,
  hideCreateAndForgot,
  afterSubmitting,
  className,
  addMessage,
}: LoginTooltipProps) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | undefined>(undefined)
  const [isLoginSubmitting, setIsLoginSubmitting] = useState(false)
  const [isResendEmailSubmitting, setIsResendEmailSubmitting] = useState(false)
  const history = useHistory()
  const setEmailValue = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const setPasswordValue = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  const onAccountClickHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    closeDropdown && closeDropdown()
    history.push('/sign-up')
  }

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    setIsLoginSubmitting(true)
    event.preventDefault()
    MidtierService.signIn({email, password})
      .then(async (tokens) => {
        await setTokens(tokens)
        return MidtierService.getUser()
      })
      .then((user) => {
        setUserInState(user)
        afterSubmitting()
      })
      .catch((error) => {
        setError(error.message)
        setIsLoginSubmitting(false)
      })
  }

  const ResendEmailButton = () => {
    return error === 'Pending user verification' ? (
      isResendEmailSubmitting ? (
        <Loader className={st.spinner} type="TailSpin" color="orange" height={24} width={24} visible={true} />
      ) : (
        <button
          type="button"
          className={cn('reset-button', st.button, st.linkButton, st.resendEmailButton)}
          onClick={async (event) => {
            event.preventDefault()
            event.stopPropagation()
            try {
              setIsResendEmailSubmitting(true)
              await MidtierService.resendEmailConfirm({email})
              addMessage({id: generateId(), message: 'A confirmation email was sent.', type: 'success'})
              setError(undefined)
              setIsResendEmailSubmitting(false)
            } catch (e) {
              setIsResendEmailSubmitting(false)
              setError(e.message)
            }
          }}
        >
          Resend email verification message
        </button>
      )
    ) : null
  }
  return (
    <form className={cn(st.form, className)} onSubmit={submitForm}>
      <Title type="h3" className={st.title}>
        Login
      </Title>
      <p>
        <LoginInput placeholder="Email" value={email} onChange={setEmailValue} />
      </p>
      <p>
        <LoginInput placeholder="Password" type="password" value={password} onChange={setPasswordValue} />
      </p>
      <p>
        <ResendEmailButton />
        <Button
          className={st.submit}
          labelClassName={st.submitLabel}
          label={
            isLoginSubmitting ? (
              <object>
                <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
              </object>
            ) : (
              'Login'
            )
          }
        />
      </p>
      {!hideCreateAndForgot && (
        <>
          {' '}
          <p className={st.link}>
            <button
              type="button"
              className={cn('reset-button', st.button, st.linkButton)}
              onClick={onChangePasswordClick}
            >
              Forgot Password?
            </button>
          </p>
          <p className={st.link}>
            <button
              type="button"
              className={cn('reset-button', st.button, st.linkButton)}
              onClick={onAccountClickHandler}
            >
              Create Account
            </button>
          </p>
        </>
      )}
    </form>
  )
}

LoginForm.defaultProps = {
  hideCreateAndForgot: false,
}
const mapDispatchToProps = (dispatch: Dispatch<AppActions>) => bindActionCreators({setUser, addMessage}, dispatch)
type AppReduxInjectedProps = ReturnType<typeof mapDispatchToProps>
export default connect(null, {setUser, addMessage})(LoginForm)
