export const pagePreviewText = 'Increasing the availability of high-quality, affordable cancer medications'
export const pageDescriptionText =
  'The Cancer Access Partnership (CAP) is motivated by the belief that cancer patients should have access to the quality medications that they need at an affordable price. CAP brings together Allied Against Cancer and pharmaceutical companies to provide world-class medications at affordable prices to treatment centers across sub-Saharan Africa. At the same time, Allied Against Cancer works with those who purchase medications to accurately plan and budget for their procurement, based on best-practice guidelines. The current participating pharmaceutical companies are Aurobindo, Biocon Biologics, Pfizer and Sandoz.'
export const accessingCapText =
  'Under the CAP program, the participating companies have individually agreed to provide high quality cancer medicines at affordable prices in select countries. Products are available for purchasers who provide high quality cancer treatment and who are committed to passing savings on to patients, such as governments, not-for-profit hospitals, and in some cases, for-profit hospitals. Eligible purchasers who wish to purchase products engage directly with the companies in order to receive quotations and enter into purchase agreements. The medicines offered through the program are the same quality as those sold in the United States or Europe. The companies operate effective supply chains in order to ensure that the medication that reaches patients is safe and potent, with minimal markups from middlemen.'

export const availableProductsText = {
  text: [
    'Some products may not be available in all countries. The map below shows which products will be offered through CAP in which countries.',
    'Purchasers in some regions in Asia, Eastern Europe, the Eastern Mediterranean and South America may also access some of the products. If you are from any of these regions, please reach out to us to confirm whether the companies are able to supply to you. In some cases, buyers outside of the designated countries above may be able to access a product at the CAP-negotiated price. For additional detail, please contact us using this ',
  ],
  linkTitle: 'form.',
  link: {
    to: '/contact-us',
  },
}
