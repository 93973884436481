import React from 'react'
import {Descriptive, Title, Button, Text} from '../../components'
import st from './NotFound.module.scss'
const NotFound = () => (
  <div className={st.notFound}>
    <Descriptive>
      <div className={st.content}>
        <div className={st.descriptionContainer}>
          <Title className={st.title} type="h3">
            Error 404 - Whoops!
          </Title>
          <Text>The page you’re looking for isn’t here!</Text>
          <Button label="Home" to="/" />
        </div>
        <div className={st.imageContainer}>
          <img src={`${process.env.PUBLIC_URL}/images/404/not_found.png`} alt="" />
        </div>
      </div>
    </Descriptive>
  </div>
)

export default NotFound
