import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {useStyles} from '../../styles'
import {ChangeLog} from '../../../../../../../../../../../services/types'

interface ShowInAssessmentTemplateProps {
  classes: any
  isShown: boolean
  type?: string
}

interface ShowInAssessmentProps {
  changelog: ChangeLog
}

const ShowInAssessmentTemplate = ({classes, isShown, type}: ShowInAssessmentTemplateProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {type && type} Shown In Assessment ?
      </Typography>
      <Typography>{isShown ? 'Yes' : 'No'}</Typography>
    </Box>
  )
}

const ShowInAssessment = ({changelog}: ShowInAssessmentProps) => {
  const classes = useStyles()

  switch (changelog.type) {
    case 'CHANGE_SHOW_IN_ASSESSMENT':
      return (
        <>
          <ShowInAssessmentTemplate classes={classes} type="Was" isShown={changelog.oldQuestion.showInAssessment} />
          <ShowInAssessmentTemplate classes={classes} type="Is" isShown={changelog.newQuestion.showInAssessment} />
        </>
      )
    case 'DELETE_QUESTION':
      return <ShowInAssessmentTemplate classes={classes} isShown={changelog.oldQuestion.showInAssessment} type="Was" />
    default:
      return <ShowInAssessmentTemplate classes={classes} isShown={changelog.newQuestion.showInAssessment} />
  }
}
export default ShowInAssessment
