export const countries = {
  BF: {name: 'Burkina Faso', coordinates: [-1.3, 12.4]},
  BW: {
    coordinates: [24.5116396, -21.3133867],
    name: 'Botswana',
  },
  CI: {
    coordinates: [-4.55874, 7.3575387],
    name: 'Ivory Coast',
  },
  CM: {
    coordinates: [11.3703686, 3.8302938],
    name: 'Cameroon',
  },
  CD: {
    name: 'Congo (DRC)',
    coordinates: [21.75, -4.038],
  },
  ET: {
    coordinates: [38.763611, 9.005401],
    name: 'Ethiopia',
  },
  GH: {
    coordinates: [-0.6563323, 9.7107281],
    name: 'Ghana',
  },
  KE: {
    coordinates: [38.3601638, 0.1692829],
    name: 'Kenya',
  },
  LR: {
    coordinates: [-8.7220075, 5.0880462],
    name: 'Liberia',
  },
  LS: {
    coordinates: [27.7981063, -30.21455],
    name: 'Lesotho',
  },
  ML: {
    coordinates: [-3.4159757, 16.2610066],
    name: 'Mali',
  },
  MW: {
    coordinates: [33.7904262, -11.8694504],
    name: 'Malawi',
  },
  MZ: {
    coordinates: [37.652635, -15.6301813],
    name: 'Mozambique',
  },
  NA: {
    coordinates: [16.9921861, -22.5637411],
    name: 'Namibia',
  },
  NG: {
    coordinates: [7.491302, 9.072264],
    name: 'Nigeria',
  },
  RW: {
    coordinates: [29.944954, -1.9918094],
    name: 'Rwanda',
  },
  SD: {
    coordinates: [30.5417553, 16.7819186],
    name: 'Sudan',
  },
  SL: {
    coordinates: [-11.5896326, 8.5919454],
    name: 'Sierra Leone',
  },
  SN: {
    coordinates: [-14.0086758, 14.8196516],
    name: 'Senegal',
  },
  SZ: {
    coordinates: [31.5211019, -26.5133063],
    name: 'Eswatini',
  },
  TZ: {
    coordinates: [34.8924826, -6.3728253],
    name: 'Tanzania',
  },
  UG: {
    coordinates: [32.2529406, 2.7754066],
    name: 'Uganda',
  },
  ZA: {
    coordinates: [22.0678279, -32.6636394],
    name: 'South Africa',
  },
  ZM: {
    coordinates: [26.1544991, -14.768575],
    name: 'Zambia',
  },
  ZW: {
    coordinates: [31.053028, -17.824858],
    name: 'Zimbabwe',
  },
}
