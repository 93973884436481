import React, {useState} from 'react'
import {Text, ImageItem, Title} from '../../../../components'
import {countries, availableProducts, availableProductsText} from '../../constants'
import Link from '../../../../components/Link'
import MapChart from './components/MapChart'
import st from './AvailableProductsContainer.module.scss'

const AvailableProductsContainer = () => {
  const initialCountry = 'NG'
  const [selectedCountry, setSelectedCountry] = useState(initialCountry)

  const selectCountryHandler = (countryIso: string): void => {
    setSelectedCountry(countryIso)
  }
  return (
    <>
      <Title type="h3">Product Availability</Title>
      <div className={st.productsContainer}>
        <Text>{availableProductsText.text[0]}</Text>
        <div className={st.productsListContainer}>
          <div className={st.productsListHeading}>
            <ImageItem path="icons\map-marker.svg" className={st.markerIcon} />
            <Text className={st.productsListCountry}>{countries[selectedCountry].name}</Text>
          </div>
          <ul className={st.productsList}>
            {availableProducts[selectedCountry]?.map((product: string) => (
              <li key={product}>
                <Text className={st.productHeading}>{`– ${product}`}</Text>
              </li>
            ))}
          </ul>
        </div>
        <Text>
          {availableProductsText.text[1]}
          {availableProductsText.link && <Link {...availableProductsText.link}>{availableProductsText.linkTitle}</Link>}
        </Text>
        <div className={st.mapChart}>
          <MapChart selectedCountry={selectedCountry} onSelectCountry={selectCountryHandler} />
        </div>
      </div>
    </>
  )
}

export default AvailableProductsContainer
