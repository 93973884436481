import cn from 'classnames'
import React from 'react'

import {ErrorMessage} from '../'
import st from './Checkbox.module.scss'

interface CheckboxProps {
  className?: string
  inputContainerClassName?: string
  id: string
  labelClassName?: string
  checked?: boolean
  label?: string | React.ReactNode
  error?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

const Checkbox = ({
  className,
  onChange,
  onBlur,
  inputContainerClassName,
  label,
  error,
  id,
  checked,
  labelClassName,
  ...attrs
}: CheckboxProps) => {
  return (
    <div className={cn(st.inputContainer, inputContainerClassName)}>
      <label className={st.inputWrap}>
        {label && (
          <label className={cn(st.label, labelClassName)} htmlFor={id}>
            {label}
          </label>
        )}
        <input
          className={cn(st.input, className)}
          type="checkbox"
          onChange={onChange}
          checked={checked}
          name={id}
          id={id}
          {...attrs}
        />
        <span className={st.checkmark} />
      </label>
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

export default Checkbox
