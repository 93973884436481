import {NavLink} from 'react-router-dom'
import React from 'react'
import cn from 'classnames'
import st from './NavLinkListElement.module.scss'

interface NavLinkListElementProps {
  isSubLink?: boolean
  onClick?: () => void
  name: string
  exact: boolean
  to: string
  listItemClassName?: string
  navLinkClassName?: string
  activeNavLinkClassName?: string
}

const NavLinkListElement = ({
  onClick,
  name,
  exact,
  to,
  isSubLink,
  listItemClassName,
  navLinkClassName,
  activeNavLinkClassName,
}: NavLinkListElementProps) => {
  return (
    <li className={cn(listItemClassName || st.listItemClassName)}>
      <NavLink
        exact={exact}
        to={to}
        onClick={onClick}
        className={cn(navLinkClassName || st.navLink, isSubLink && st.subLink)}
        activeClassName={cn(activeNavLinkClassName || st.active)}
      >
        <span>{name}</span>
      </NavLink>
    </li>
  )
}

export default NavLinkListElement
