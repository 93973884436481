import cn from 'classnames'
import React from 'react'

import {ContentWrap} from '../..'
import st from './Descriptive.module.scss'

interface DescriptiveProps {
  children?: React.ReactNode
  className?: string
  id?: string
}

const Descriptive = ({children, className, id}: DescriptiveProps) => {
  return (
    <div className={cn(st.descriptive, className)} id={id}>
      <ContentWrap>{children}</ContentWrap>
    </div>
  )
}

export default Descriptive
