import React from 'react'

import cn from 'classnames'
import {Title} from '../../../../../components'
import st from './ChallengeItem.module.scss'
interface CardTypes {
  title?: string | React.ReactNode
  description?: string | React.ReactNode
  imgUrl?: string
  className?: string
}

const ChallengeItem = ({title, description, imgUrl, className}: CardTypes) => {
  return (
    <div className={cn(st.challengeItem, className)}>
      <div className={st.imgCol}>
        <div className={st.imgBlock}>
          <div
            className={st.img}
            style={{
              backgroundImage: `url(${imgUrl})`,
            }}
          />
        </div>
      </div>
      <div className={st.descriptionCol}>
        <Title className={st.title} type="h4">
          {title}
        </Title>
        <div className={st.description}>{description}</div>
      </div>
    </div>
  )
}

export default ChallengeItem
