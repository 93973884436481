import React, {useEffect, useState} from 'react'
import {Box, CircularProgress} from '@material-ui/core'
import {MidtierService} from '../../../services'
import {useCSTContext} from '../context/chemosafeToolContext'
import {CSTReleaseMeta} from '../../../services/types'
import {getCurrentReleaseMeta} from '../utils'
import {VersionControlTable} from './components'
import {useStyles} from './styles'

const ChemoSafeToolChangelog = () => {
  const {
    actions: {updateCurrentRelease, loadData},
  }: any = useCSTContext()
  const [releases, setReleases] = useState<Array<CSTReleaseMeta>>([])
  const [isLoading, setIsLoading] = useState(true)
  const classes = useStyles()

  async function getAllAssessmentMetas() {
    const releasesMetas = await MidtierService.getAllCSTReleases()
    const currentReleaseMeta = getCurrentReleaseMeta(releasesMetas)
    const currentRelease = await MidtierService.getCSTReleaseById(currentReleaseMeta.id)

    updateCurrentRelease?.(currentRelease)
    setReleases(releasesMetas.reverse())
    setIsLoading(false)
  }

  async function getReleases() {
    const resources = await MidtierService.getAllChemosafeDocuments()
    const {currentRelease, currentActiveRelease, blacklist} = await MidtierService.getCurrentRelease()
    loadData({resources, currentRelease, currentActiveRelease, blacklist})
  }

  const handleClick = async (id: string) => {
    setIsLoading(true)
    await MidtierService.makeNewCSTrelease(id)
    getReleases()
    getAllAssessmentMetas()
  }

  useEffect(() => {
    getAllAssessmentMetas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.container}>
      {!isLoading && <VersionControlTable data={releases} handleClick={handleClick} />}
      {isLoading && (
        <Box my={1} height="100%" display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}

export default ChemoSafeToolChangelog
