import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles({
  root: {
    height: '100%',
    backgroundColor: '#1D2222',
    fontWeight: 'normal',
  },
  header: {
    textTransform: 'none',
    color: '#fff',
    marginRight: '5px',
    '&.activeLink': {
      color: '#EB7346',
    },
  },
  filterBox: {
    fontSize: '15px',
    padding: '30px 20px 10px 20px',
    borderBottom: '1px solid #d2d2d252',
  },
  filterSubBox: {
    fontSize: '15px',
  },
  filter: {
    paddingBottom: '20px',
    color: '#ffffffa1',
    fontWeight: 700,
  },
  title: {
    color: '#ffffff',
    padding: '10px 0 10px 0',
  },
  formControl: {
    width: '100%',
    backgroundColor: '#ffffff',
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 0',
  },
  checkbox: {
    color: '#ffffff',
    backgroundColor: '#ffffff',
    fontSize: '15px',
  },
  inputBox: {
    fontSize: '15px',
    padding: '10px 20px 20px 20px',
    borderBottom: '1px solid #d2d2d252',
  },
  button: {
    width: '100%',
    padding: '25px 20px',
    display: 'flex',
    borderRadius: 0,
    justifyContent: 'start',
    '&:hover': {
      backgroundImage: 'linear-gradient(to right, #dc663b2e, #1D2222)',
    },
    borderBottom: '1px solid #d2d2d252',
  },
  input: {
    backgroundColor: '#ffffff',
    width: '100%',
    padding: '9px',
    '&:after': {
      borderBottom: '2px solid #EB7346',
    },
  },
  select: {
    '& .MuiSelect-root': {
      padding: '15px',
    },
    backgroundColor: '#fff',
    fontWeight: 'normal',
  },
  menuItem: {
    fontWeight: 'normal',
  },
  vcIcon: {
    marginRight: '10px',
    height: '24px',
    width: '24px',
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
    borderBottom: '1px solid #d2d2d252',
  },
  activeLink: {
    color: '#EB7346',
    fontSize: '15px',
  },
})
