import React, {useEffect, useState} from 'react'
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import TablePagination from '@material-ui/core/TablePagination'
import {VisibilityOutlined as ViewIcon} from '@material-ui/icons'
import {MidtierService} from '../../../../../../../services'
import {ChangeLog} from '../../../../../../../services/types'
import {useStyles} from './styles'
import {ChangelogModal} from './components'

interface CLRowProps {
  classes: any
  version: number
  data: ChangeLog
}

interface ChangelogTableProps {
  version: number
}
const ClRow = ({data, classes, version}: CLRowProps) => {
  const [openModal, setOpenModal] = useState(false)
  const handleClick = () => setOpenModal(!openModal)
  return (
    <>
      <TableRow>
        <TableCell className={classes.cell} colSpan={1}>
          {data.questionId}
        </TableCell>
        <TableCell className={classes.cell}>{data.section}</TableCell>
        <TableCell className={classes.cell}>{data.subSection}</TableCell>
        <TableCell className={classes.cell}>{data.assessmentMetaVersion}</TableCell>
        <TableCell className={classes.cell}>{data.date}</TableCell>
        <TableCell className={classes.cell}>{data.type || 'No changes'}</TableCell>
        <TableCell className={classes.cell}>{data.author}</TableCell>

        <TableCell className={classes.cell}>
          <button className={classes.iconButtonStyle} onClick={handleClick}>
            <ViewIcon />
          </button>
        </TableCell>
      </TableRow>
      <TableRow className={classes.row}>
        <TableCell colSpan={8} className={classes.text}>
          {data.newQuestion ? data.newQuestion.questionText : data.oldQuestion.questionText}
        </TableCell>
      </TableRow>
      {openModal && (
        <ChangelogModal changelog={data} isOpened={openModal} handleClose={handleClick} version={version} />
      )}
    </>
  )
}

const ChangelogTable = ({version}: ChangelogTableProps) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [changelogData, setChangelogData] = useState<ChangeLog[]>([])
  async function getChangelogData() {
    const data = await MidtierService.getChangelogForVersion(version)
    setChangelogData(data)
    setIsLoading(false)
  }
  useEffect(() => {
    getChangelogData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  return (
    <Box margin={2}>
      {isLoading && <CircularProgress className={classes.circle} />}
      {!isLoading && (
        <>
          <Typography variant="subtitle1" className={classes.changelogTitle}>
            {changelogData.length === 0 && 'No'} Changelog
          </Typography>
          <Box component={Paper}>
            {changelogData.length > 0 && (
              <>
                <TableContainer>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.thead}>ID</TableCell>
                        <TableCell className={classes.thead}>Section</TableCell>
                        <TableCell className={classes.thead}>Sub-section</TableCell>
                        <TableCell className={classes.thead}>Release</TableCell>
                        <TableCell className={classes.thead}>Date of Change</TableCell>
                        <TableCell className={classes.thead}>Type of Changes</TableCell>
                        <TableCell className={classes.thead}>User</TableCell>
                        <TableCell className={classes.thead}>View</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {changelogData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, i) => {
                        return <ClRow data={data} classes={classes} key={i} version={version} />
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50, 100]}
                  component="div"
                  count={changelogData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  colSpan={3}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  )
}

export default ChangelogTable
