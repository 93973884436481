import React from 'react'
import Link from '../../components/Link'
import {Descriptive, PagePreview, Text, ContentWrap, ImageItem, Title} from '../../components'
import {pagePreviewText, pageDescriptionText, accessingCapText, questions} from './constants'
import st from './CancerAccessPartnership.module.scss'
import CollapsePanel from './components/CollapsePanel'
import AvailableProductsContainer from './components/AvailableProductsContainer'

const CancerAccessPartnership = () => {
  return (
    <div>
      <PagePreview
        title="Cancer Access Partnership"
        description={pagePreviewText}
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/access_partnership.jpg`}
      />
      <Descriptive>
        <Text>{pageDescriptionText}</Text>
      </Descriptive>

      <div className={st.accessingCapContainer}>
        <div className={st.videoCol}>
          <div className={st.iframeWrapper}>
            <iframe
              className={st.iframe}
              title="Cancer Access Partnership"
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/t2uMjGKH2UE"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
        <div className={st.accessingCapCol}>
          <Title type="h3">Accessing CAP Products</Title>
          <Text className={st.accessingCapDescriptionText}>{accessingCapText}</Text>
        </div>
      </div>
      <ContentWrap className={st.availableProductsContainer}>
        <AvailableProductsContainer />
      </ContentWrap>
      <ContentWrap className={st.faqContainer}>
        <Title type="h3">Frequently Asked Questions</Title>
        <CollapsePanel question={questions[1].question}>
          <Text>
            {questions[1].answer}
            {questions[1].link && <Link {...questions[1].link}>{questions[1].linkTitle}</Link>}
          </Text>
        </CollapsePanel>
        <CollapsePanel question={questions[2].question}>
          <Text>{questions[2].answer}</Text>
        </CollapsePanel>
        <CollapsePanel question={questions[3].question}>
          <Text>{questions[3].answer}</Text>
        </CollapsePanel>
        <CollapsePanel question={questions[4].question}>
          <Text>{questions[4].answer}</Text>
        </CollapsePanel>
        <CollapsePanel question={questions[5].question}>
          <Text>
            {questions[5].answer[0]}
            {questions[5].links && <Link {...questions[5].links[0].link}>{questions[5].links[0].linkTitle}</Link>}
            {questions[5].answer[1]}
          </Text>
        </CollapsePanel>
        <CollapsePanel question={questions[6].question}>
          <Text>
            {questions[6].answer}
          </Text>
        </CollapsePanel>
        <CollapsePanel question={questions[7].question}>
          <Text>
            {questions[7].answer}
          </Text>
        </CollapsePanel>
        <CollapsePanel question={questions[8].question}>
          <Text>
            {questions[8].answer}
            {questions[8].link && <Link {...questions[8].link}>{questions[8].linkTitle}</Link>}
          </Text>
        </CollapsePanel>
      </ContentWrap>
      <ContentWrap className={st.partnersContainer}>
        <Title type="h3">Participating Companies</Title>
        <ul className={st.partnersList}>
        <li>
            <Link
              to="https://www.aurobindo.com/"
              external
              target="_blank"
              rel="noopener noreferrer"
              className={st.partnerItem}
            >
              <ImageItem path="images/our_work/aurobindo_logo.png" className={st.partnerLogo} />
            </Link>
          </li>
          <li>
            <Link
              to="https://www.biocon.com/"
              external
              target="_blank"
              rel="noopener noreferrer"
              className={st.partnerItem}
            >
              <ImageItem path="images/our_work/biocon_logo.png" className={st.partnerLogo} />
            </Link>
          </li>
          <li>
            <Link
              to="https://www.sandoz.com"
              external
              target="_blank"
              rel="noopener noreferrer"
              className={st.partnerItem}
            >
              <ImageItem path="images/our_work/sandoz_logo.png" className={st.partnerLogo} />
            </Link>
          </li>
          <li>
            <Link
              to="https://www.pfizer.com/news/hot-topics/collaborating_to_provide_lifesaving_cancer_treatments_to_patients_in_africa"
              external
              target="_blank"
              rel="noopener noreferrer"
              className={st.partnerItem}
            >
              <ImageItem path="images/our_work/pfizer_logo.jpg" className={st.partnerLogo} />
            </Link>
          </li>
        </ul>
      </ContentWrap>
    </div>
  )
}

export default CancerAccessPartnership
