/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import React, {MouseEvent} from 'react'
import classNames from 'classnames'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  Box,
  Typography,
  ExpansionPanelDetails,
  TextField,
  FormControl,
  InputLabel,
  Switch,
  Tooltip,
  Select,
} from '@material-ui/core'
import {ExpandMore, DeleteOutlined as RemoveIcon, Create as EditIcon} from '@material-ui/icons'
import {Autocomplete} from '@material-ui/lab'
import {useFormik} from 'formik'
import {RenderItemParams} from '@atlaskit/tree'
import {useCSTContext} from '../../../../context/chemosafeToolContext'
import {ChemosafeDocument, Question as QuestionType} from '../../../../../../services/types'
import {validator} from '../../../../../../utils/validation/validator'
import {Button} from '../../../../../../components'
import {useStyles} from './styles'
import {DragEmitter} from './DragEmitter'
import {TextInput} from './components'

interface QuestionProps {
  displayNumber: string
  isExpanded?: boolean
  hideDragEmmiter?: boolean
  dragHandleProps?: RenderItemParams['provided']['dragHandleProps']
  isDragging?: boolean
  data: QuestionType
  toggleShowInAssessmentDisabled?: boolean
  disableToggle?: boolean
  mode?: 'edit' | 'display'
  onToggle: (id: string, isExpanded: boolean) => void
  onRemove: (id: string) => void
  onEdit: (id: string | null) => void
}

const validate = (values: QuestionType) =>
  validator.rules({
    questionText: validator.validate(values.questionText).required(),
    recommendationText: validator.validate(values.recommendationText).required(),
  })

const Question = ({
  data,
  mode = 'display',
  displayNumber,
  toggleShowInAssessmentDisabled = false,
  isExpanded = false,
  onToggle,
  dragHandleProps = {},
  isDragging = false,
  disableToggle = false,
  onRemove,
  hideDragEmmiter = false,
  onEdit,
}: QuestionProps) => {
  const {
    forms: {
      filters: {showRecommendations, showReferences, showResources},
    },
    resources,
    actions: {editQuestion, toggleShowInAssessment},
  }: any = useCSTContext()
  const classes = useStyles({isDragging, hideDragEmmiter})

  const {
    values,
    handleChange,
    errors,
    setFieldValue,
    handleSubmit,
    dirty,
    isSubmitting,
    isValid,
    resetForm,
  } = useFormik<QuestionType>({
    enableReinitialize: true,
    initialValues: data,
    validate,
    onSubmit: (values) => {
      editQuestion?.(values)
      onEdit?.(null)
      onToggle?.(values.id, !isExpanded)
    },
  })

  const handleToggle = (event: React.ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
    if (!disableToggle) onToggle?.(values.id, isExpanded)
  }

  const handleRemove = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    onRemove?.(values.id)
  }

  const handleEdit = (event: React.MouseEvent<SVGSVGElement>) => {
    event.stopPropagation()
    if (disableToggle) return

    onEdit?.(data.id)
    if (!isExpanded) onToggle?.(values.id, !isExpanded)
  }

  const handleCancelEdit = () => {
    onEdit?.(null)
    onToggle?.(values.id, !isExpanded)
    resetForm()
  }

  const handleToggleShowInAssessment = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    toggleShowInAssessment?.(data.id)
  }

  const isExpansible =
    showRecommendations || showReferences || showResources || values.conditionalPredicate?.value || mode === 'edit'
  return (
    <form onSubmit={handleSubmit}>
      <ExpansionPanel
        classes={{
          root: classes['question-panel-root'],
        }}
        className={mode === 'edit' ? classNames(classes.editMode) : undefined}
        square
        expanded={isExpansible && isExpanded}
        onChange={handleToggle}
      >
        <ExpansionPanelSummary
          className={classNames(classes.panel, {activeDnd: isDragging})}
          classes={{
            expanded: classes['question-panel-summary-expanded'],
            expandIcon: classes['toggle-icon'],
            content: classes['question-panel-summary-content'],
          }}
          expandIcon={isExpansible ? <ExpandMore /> : null}
          id="panel1bh-header"
        >
          <Box display="flex" width="100%" className={classes.dndPanel}>
            <Box className={classes['question-number-wrapper']}>
              <Typography className={classes['question-number']}>{displayNumber}</Typography>
            </Box>
            {!hideDragEmmiter && (
              <Box className={classes['drag-emmiter-wrapper']} {...dragHandleProps}>
                <DragEmitter isDragging={isDragging} />
              </Box>
            )}
            <Box display="flex" flexGrow={1}>
              <Box className={classes['question-info-container']}>
                <Box className={classes['question-meta-info-container']}>
                  <Typography className={classes['question-id']}>ID: {values.id}</Typography>
                </Box>
                {mode === 'display' ? (
                  <Typography className={classes['question-text']}>{values.questionText}</Typography>
                ) : (
                  <TextInput
                    onChange={handleChange}
                    text={values.questionText || ''}
                    error={!!errors.questionText}
                    helperText={!!errors?.questionText || ''}
                    autoFocus
                    margin="dense"
                    id="questionText"
                    mode={mode}
                    isQuestionText={true}
                  />
                )}
              </Box>
            </Box>
            <Box display="flex" ml="auto" alignSelf="center">
              <Tooltip title="Show in assessment">
                <Switch
                  disabled={toggleShowInAssessmentDisabled}
                  size="small"
                  color="primary"
                  checked={data.showInAssessment}
                  onClick={handleToggleShowInAssessment}
                  name="showInAssessment"
                />
              </Tooltip>
              <EditIcon onClick={handleEdit} className={classes['edit-icon']} style={{color: mode === 'edit' ? '#EB7346' : 'black', margin: '0 12px'}} />
              <RemoveIcon onClick={handleRemove} className={classes['edit-icon']} />
            </Box>
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          classes={{root: classes['question-panel-details']}}
          className={mode === 'display' ? classNames(classes.displayMode) : undefined}
        >
          <Box width="100%" style={{padding: '8px 24px'}}>
            <Box flexDirection="column" width="100%" justifyContent="flex-start">
              <Typography
                variant="caption"
                color="textSecondary"
                gutterBottom
                style={{paddingLeft: mode === 'edit' ? '14px' : '0px'}}
              >
                RECOMMENDATION
              </Typography>
              <TextInput
                onChange={handleChange}
                text={values.recommendationText || ''}
                mode={mode}
                id="recommendationText"
              />
            </Box>
            <Box style={{marginTop: '20px'}}>
              {mode === 'edit' ? (
                <Autocomplete
                  id="resources.resourceId"
                  value={resources?.find((resource: ChemosafeDocument) => resource.id === values.resources.resourceId)}
                  options={resources}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, value) => {
                    setFieldValue('resources.resourceId', value?.id || '')
                  }}
                  style={{width: 500}}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="RESOURCE"
                      className={classNames(classes['select-text-field'])}
                    />
                  )}
                />
              ) : (
                values.resources.resourceId &&
                showResources && (
                  <>
                    <Typography variant="caption" color="textSecondary" gutterBottom>
                      RESOURCE NAME
                    </Typography>
                    <TextInput
                      mode={mode}
                      text={
                        resources.find((resource: ChemosafeDocument) => resource.id === values.resources.resourceId)
                          ?.name
                      }
                    />
                  </>
                )
              )}
            </Box>
            <Box style={{marginTop: '20px'}}>
              {mode === 'edit' ? (
                <FormControl variant="outlined">
                  <InputLabel>COST</InputLabel>
                  <Select
                    native
                    value={values.resources.resourceRequirement}
                    onChange={handleChange}
                    label="COST"
                    style={{width: 500, fontSize: '0.875rem'}}
                    inputProps={{
                      name: 'resources.resourceRequirement',
                      id: 'resources.resourceRequirement',
                    }}
                  >
                    <option value="Low">Low</option>
                    <option value="Medium">Medium</option>
                    <option value="High">High</option>
                  </Select>
                </FormControl>
              ) : (
                values.resources.resourceRequirement &&
                showResources && (
                  <>
                    <Typography variant="caption" color="textSecondary" gutterBottom>
                      RESOURCE REQUIREMENT
                    </Typography>
                    <TextInput mode={mode} text={values.resources.resourceRequirement} />
                  </>
                )
              )}
            </Box>
            <Box style={{marginTop: '20px'}}>
              {mode === 'edit' ? (
                <Autocomplete
                  id="resources.resourceId"
                  value={resources?.find((resource: ChemosafeDocument) => resource.id === values.resources.referenceId)}
                  onChange={(e, value) => {
                    setFieldValue('resources.referenceId', value?.id || '')
                  }}
                  options={resources}
                  style={{width: 500}}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="REFERENCE"
                      className={classNames(classes['select-text-field'])}
                    />
                  )}
                />
              ) : (
                values.resources.referenceId &&
                showReferences && (
                  <>
                    <Typography variant="caption" color="textSecondary" gutterBottom style={{marginTop: '25px'}}>
                      REFERENCE
                    </Typography>
                    <TextInput
                      mode={mode}
                      text={
                        resources.find((resource: ChemosafeDocument) => resource.id === values.resources.referenceId)
                          .name
                      }
                    />
                  </>
                )
              )}
            </Box>
            <Box style={{marginTop: '20px', marginBottom: '10px'}}>
              {mode === 'edit' && values.conditionalPredicate ? (
                <FormControl variant="outlined">
                  <InputLabel htmlFor="conditionalPredicate.value.optionId">PARENT ANSWER</InputLabel>
                  <Select
                    native
                    onChange={handleChange}
                    value={values.conditionalPredicate.value.optionId}
                    style={{width: 500, fontSize: '0.875rem'}}
                    labelWidth={133}
                    inputProps={{
                      name: 'conditionalPredicate.value.optionId',
                      id: 'conditionalPredicate.value.optionId',
                    }}
                  >
                    <option value="NO">NO</option>
                    <option value="YES">YES</option>
                  </Select>
                </FormControl>
              ) : (
                values.conditionalPredicate?.value && (
                  <>
                    <Typography variant="caption" color="textSecondary" gutterBottom style={{marginTop: '25px'}}>
                      PARENT ANSWER
                    </Typography>
                    <TextInput mode={mode} text={values.conditionalPredicate?.value.optionId} />
                  </>
                )
              )}
            </Box>
            <Box style={{marginTop: '20px', marginBottom: '10px'}}>
              {mode === 'edit' ? (
                <FormControl variant="outlined">
                  <InputLabel htmlFor="planType">ACTION PLAN</InputLabel>
                  <Select
                    native
                    onChange={handleChange}
                    value={values.planType}
                    style={{width: 500, fontSize: '0.875rem'}}
                    labelWidth={102}
                    inputProps={{
                      name: 'planType',
                      id: 'planType',
                    }}
                  >
                    <option value="Administrative Controls">Administrative Controls</option>
                    <option value="Personal Protective Equipment (PPE) Controls">
                      Personal Protective Equipment (PPE) Controls
                    </option>
                    <option value="Engineering Controls">Engineering Controls</option>
                  </Select>
                </FormControl>
              ) : (
                <>
                  <Typography variant="caption" color="textSecondary" gutterBottom style={{marginTop: '25px'}}>
                    ACTION PLAN TYPE
                  </Typography>
                  <TextInput mode={mode} text={values?.planType || 'Plan type not selected'} />
                </>
              )}
            </Box>
          </Box>
          <Box>
            {mode === 'edit' && (
              <Box className={classes['buttons-box']} display="block" justifyContent="space-between" width="100%">
                <Button
                  type="button"
                  label="Cancel"
                  onClick={handleCancelEdit}
                  className={classes['cancel-button']}
                  labelClassName={classes['cancel-button-label']}
                />
                <Button
                  type="submit"
                  label="Save Changes"
                  disabled={!dirty || isSubmitting || !isValid}
                  className={classes['save-changes-button']}
                />
              </Box>
            )}
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </form>
  )
}

export default Question
