import React from 'react'
import Loader from 'react-loader-spinner'
import {Formik, Form, Field, FieldProps} from 'formik'
import {Input, Button, Text} from '../../../../../components'
import {MidtierService} from '../../../../../services'
import {setUser} from '../../../../../redux/user/actions'
import {setTokens} from '../../../../../utils/tokens'
import {Tokens} from '../../../../../services/types'
import st from './SmallForms.module.scss'
import {checkValidationError} from './functions'

interface Props {
  handleResponse: (value: boolean) => void
  setTokensInState: typeof setTokens
  setUserInState: typeof setUser
}

const ChangePassword: React.FunctionComponent<Props> = ({handleResponse, setTokensInState}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validate = (values: any) => {
    return Object.keys(values).reduce((acc, fieldName) => {
      let error
      if (fieldName === 'password') {
        error = checkValidationError('password')(values[fieldName])
      }
      if (fieldName === 'newPassword') {
        error = checkValidationError('newPassword')(values[fieldName], values.password)
      }
      return error ? {...acc, [fieldName]: error} : acc
    }, {})
  }

  return (
    <Formik
      initialValues={{newPassword: '', password: ''}}
      validate={validate}
      onSubmit={({newPassword}, actions) => {
        MidtierService.changePassword({newPassword})
          .then(async (tokens: Tokens) => {
            await setTokensInState(tokens)
            actions.setSubmitting(false)
            handleResponse(true)
          })
          .catch(() => {
            actions.setSubmitting(false)
          })
      }}
    >
      {(props) => {
        return (
          <Form className={st.checkEmailForm}>
            <Text className={st.text}>Choose a new Password</Text>
            <div className={st.inputs}>
              <Field type="password" name="password">
                {(props: FieldProps) => (
                  <Input
                    inputContainerClassName={st.input}
                    id="password"
                    type="password"
                    placeholder="The password must contain 6 letters and a symbol."
                    {...props.field}
                    error={props.form.errors[props.field.name]}
                  />
                )}
              </Field>
            </div>
            <Text className={st.text}>Confirm password</Text>
            <div className={st.inputs}>
              <Field type="newPassword" name="newPassword">
                {(props: FieldProps) => {
                  return (
                    <Input
                      inputContainerClassName={st.input}
                      id="newPassword"
                      type="password"
                      placeholder="Must match with password"
                      {...props.field}
                      error={props.form.errors[props.field.name]}
                    />
                  )
                }}
              </Field>
            </div>

            <div className={st.buttons}>
              <div className={st.buttonBlock}>
                <Button label="Cancel" secondary to="/profile/settings" disabled={props.isSubmitting} />
                <Button
                  type="submit"
                  label={
                    props.isSubmitting ? (
                      <Loader
                        className={st.spinner}
                        type="TailSpin"
                        color="white"
                        height={24}
                        width={24}
                        visible={true}
                      />
                    ) : (
                      'Next'
                    )
                  }
                  className={st.buttons}
                  disabled={!props.isValid || props.isSubmitting}
                />
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ChangePassword
