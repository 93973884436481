import {makeStyles} from '@material-ui/core'

export const PADDING_PER_LEVEL = 17

export const useStyles = makeStyles(() => ({
  'question-wrapper': {
    paddingTop: '15px',
  },
  'question-wrapper--active': {
    border: '1px solid red',
  },
}))
