import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    fontFamily: 'Quicksand',
  },
  contentContainer: {
    minHeight: '250px',
  },
  inputContainer: {
    width: '100%',
  },
  h2: {
    color: 'black',
  },
  iconButtonStyle: {
    border: 'none',
    background: 'none',
    width: '25px',
    height: '25px',
    alignSelf: 'flex-end',
  },
  typeTitle: {
    fontSize: '20px',
    margin: '10px 0',
    '&.error': {
      '&::after': {content: '" *"', color: 'red'},
    },
  },
  checkboxTitle: {
    fontSize: '20px',
    margin: '10px 0',
    display: 'inline-block',
  },
  input: {
    '& input': {
      width: '100%',
    },
    '& fieldset': {
      border: '1px solid lightgray',
      borderRadius: '4px',
      '&:hover': {
        borderColor: '#eb7643',
      },
    },
    marginBottom: '10px',
  },
  buttonBox: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    fontWeight: 600,
  },

  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  checkBox: {
    color: '#eb7643',
    position: 'relative',
    padding: '0px 5px 7px 5px',
  },
  label: {
    display: 'block',
    marginBottom: '1rem',
    marginTop: '20px',
  },
})
