import React, {ChangeEvent} from 'react'
import cn from 'classnames'

import st from './LoginInput.module.scss'

interface LoginInputProps {
  id?: string
  placeholder: string
  value: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  type?: string
  onFocus?: () => void
  onBlur?: () => void
  className?: string
}

const LoginInput = ({className, ...props}: LoginInputProps) => {
  return <input {...props} className={cn(st.input, className)} />
}

export default LoginInput
