/* eslint-disable @typescript-eslint/no-explicit-any */
import * as regexp from '../regexp'
import {isNumber, isString} from './validationUtils'

class Validator {
  value: any
  errors: string[]
  isOptional: boolean

  constructor(value: any) {
    this.value = value
    this.errors = []
    this.isOptional = false
  }

  /**
   * Get first error from "error" array
   *
   * @return {string} Error string
   */
  get error(): string {
    return this.errors[0] || ''
  }

  /**
   * Worker to be executed for each validation check
   *
   * @param {String} errorMessage Validation error message
   * @param {Boolean} isValid Validation flag
   * @return {Validator} Validator
   */
  worker = ({errorMessage, isValid}: {errorMessage: string; isValid: boolean}): Validator => {
    if (!isValid) {
      this.errors.push(errorMessage)
    }
    return this
  }

  /**
   * Validation rule for checking if given value is a string
   *
   * @param {String} errorMessage Error Message
   * @return {Validator} Validator
   */
  string = (errorMessage = 'Provided value should be a string'): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.isOptional && !this.value ? true : isString(this.value),
    })
  }

  /**
   * Validation rule for checking if given value is a number
   *
   * @param {String} errorMessage Error message
   * @return {Validator} Validator
   */
  number = (errorMessage = 'Provided value should be a number'): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.isOptional && !isNumber(this.value) ? true : isNumber(this.value),
    })
  }

  /**
   * Validation rule for checking if given value is required
   *
   * @param {String} errorMessage Error message
   * @return {Validator} Validator
   */
  required = (errorMessage = 'Required field'): Validator => {
    return this.worker({
      errorMessage,
      isValid: !(
        this.value === undefined ||
        this.value === '' ||
        this.value === false ||
        (Array.isArray(this.value) && !this.value.length)
      ),
    })
  }

  boolean = (errorMessage = 'Agree with proccessing of you personal data first'): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.value,
    })
  }

  maxLength = (length: number, errorMessage?: string): Validator => {
    const message = errorMessage || `Up to ${length} options are allowed`
    return this.worker({
      errorMessage: message,
      isValid: this.isOptional && !this.value ? true : this.value && this.value.length <= length,
    })
  }

  minLength = (length: number, errorMessage?: string): Validator => {
    const message = errorMessage || `At least ${length} option is required `
    return this.worker({
      errorMessage: message,
      isValid: this.isOptional && !this.value ? true : this.value && this.value.length >= length,
    })
  }

  email = (errorMessage = 'Please enter a valid email address'): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.isOptional && !this.value ? true : regexp.emailRegexp.test(this.value),
    })
  }

  optional = (): Validator => {
    this.isOptional = true
    return this
  }

  url = (errorMessage = 'Please enter a valid url address'): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.isOptional && !this.value ? true : regexp.urlRegexp.test(this.value),
    })
  }

  password = (
    errorMessage = 'Password must be at least 8 characters, must contain letter, symbol and number',
  ): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.isOptional && !this.value ? true : regexp.passwordRegexp.test(this.value) && this.value.length >= 8,
    })
  }

  name = (errorMessage = 'The field can contain only letters, dashes, apostrophe, commas or dots '): Validator => {
    return this.worker({
      errorMessage,
      isValid: this.isOptional && !this.value ? true : regexp.nameRegexp.test(this.value),
    })
  }

  /**
   * Validation rule for checking if given value is in the given range
   *
   * @param {Number[]} range Number range
   * @param {String} errorMessage Error message
   * @return {Validator} Validator
   */
  range = (range: [number, number], errorMessage: string): Validator => {
    const message = errorMessage || `Provided value should be in range between ${range[0]} and ${range[1]}`
    return this.worker({
      errorMessage: message,
      isValid: this.isOptional && !this.value ? true : this.value >= range[0] && this.value <= range[1],
    })
  }

  /**
   * Custom validation rule
   *
   * @param {Function} method Validation method
   * @param {*[]} args Array with arguments
   * @return {Validator} Validator
   */
  custom = (method: () => {errorMessage: string; isValid: boolean}, args: any): Validator => {
    return this.worker(method.apply(this, args))
  }
}

/**
 * Validation interface
 *
 * @type {{rules: (function(*=): {}), validate: (function(*=): Validator)}}
 */
const validationInterface = {
  rules: (config: {[key: string]: {error: string}}) =>
    Object.keys(config).reduce(
      (prev, curr) => ({
        ...prev,
        ...{...(config[curr].error ? {[curr]: config[curr].error} : {})},
      }),
      {},
    ),
  validate: (value: any) => new Validator(value),
}

export {validationInterface as validator}
