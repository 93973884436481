export interface Message {
  id: string
  message: string
  type: 'error' | 'success'
}

export type AlertsState = Message[]

export const ADD_MESSAGE = 'ADD_MESSAGE'
export const DEL_MESSAGE = 'DEL_MESSAGE'
interface AddMessage {
  type: typeof ADD_MESSAGE
  payload: Message
}

interface DelMessage {
  type: typeof DEL_MESSAGE
  payload: {id: string}
}

export type AlertsActionTypes = AddMessage | DelMessage
