/*eslint-disable max-lines-per-function*/
import React, {Fragment, useState, useEffect, useCallback, useMemo} from 'react'
import {useDispatch} from 'react-redux'
import {Box, Typography, Button, CircularProgress} from '@material-ui/core'
import {Add} from '@material-ui/icons'
import {TreeData, mutateTree, TreeSourcePosition, moveItemOnTree, TreeDestinationPosition} from '@atlaskit/tree'
import isEqual from 'lodash.isequal'
import {useCSTContext} from '../context/chemosafeToolContext'
import {addMessage} from '../../../redux/alerts/actions'
import generateId from '../../../utils/generateId'
import {Question as QuestionType} from '../../../services/types'
import {useConfirmation} from '../context/confirmationServiceContext'
import {MidtierService} from '../../../services'
import AddQuestionDialog from './components/Dashboard/components/dialogs/AddQuestionDialog'
import {useStyles} from './styles'
import Question from './components/Dashboard/components/Question'
import {getDisplayNumber} from './components/Dashboard/utils'
import {Dashboard, DashboardSearchResults, DashboardHeader, SearchQuestionsForm} from './components'

interface Dashboard {
  questions?: any
  order:
    | {
        [key: string]: []
      }
    | {
        root?: any
      }
}
interface ItemProps {
  [key: string]: {
    id?: string
    children?: []
    hasChildren: boolean
    isExpanded: boolean
    isChildrenLoading: boolean
    data: any
  }
}
interface ElementProps {
  id: string
}
const getTreeStructure = (dashboard: Dashboard): TreeData => {
  const {questions, order} = dashboard

  const tree = questions.reduce(
    (items: ItemProps, el: ElementProps) => {
      items[el.id] = {
        id: el.id,
        children: order[el.id] || [],
        hasChildren: !!(order[el.id] && order[el.id]?.length !== 0),
        isExpanded: true,
        isChildrenLoading: false,
        data: el,
      }
      return items
    },
    {
      root: {
        id: 'root',
        children: order.root,
        hasChildren: order.root.length !== 0,
        isExpanded: true,
        isChildrenLoading: false,
        data: {
          title: 'root',
        },
      },
    },
  )
  return {
    rootId: 'root',
    items: tree,
  }
}

const ChemoSafeToolDashboard = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const confirm = useConfirmation()

  const {
    currentRelease,
    currentQuestionnaire,
    dashboard,
    forms: {
      filters: {section, subSection, questionName},
    },
    actions: {addQuestion, reorderDashboard, removeQuestion, loadData},
  }: any = useCSTContext()

  const [isLoading, setIsLoading] = useState(false)
  const [tree, setTree] = useState(getTreeStructure(dashboard))
  const [isAddQuestionDialogOpen, setAddQuestionDialogOpen] = useState(false)
  const [expanded, setExpanded] = useState<string | null>(null)
  const [editable, setEditable] = useState<string | null>(null)

  const handleToggleQuestion = useCallback((id: string, isExpanded: boolean) => {
    setExpanded(isExpanded ? id : null)
  }, [])

  const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    if (!destination || (source.index === destination.index && source.parentId === destination.parentId)) return
    if (
      destination.index === undefined &&
      (source.parentId !== 'root' || source.parentId === 'root') &&
      tree.items[tree.rootId].children.indexOf(destination?.parentId) === -1
    )
      return

    if (
      tree.items[tree.items[source.parentId].children[source.index]].hasChildren === true &&
      source.parentId !== destination.parentId
    )
      return

    // if (source.parentId !== destination.parentId && destination.parentId !== 'root') handleOpenReorderDialog() //todo: fix modal with moving to new parent(answer)

    let newTree = moveItemOnTree(tree, source, destination)
    newTree = mutateTree(newTree, destination.parentId, {isExpanded: true})

    const newOrder = Object.keys(newTree.items).reduce((orderObject, item) => {
      orderObject[item] = newTree.items[item].children
      return orderObject
    }, {})

    setTree(newTree)
    reorderDashboard({
      newOrder,
      movedQuestionId: tree.items[source.parentId].children[source.index],
      destinationQuestionId: destination.parentId,
    })
  }

  const handlEditQuestion = useCallback((id: string | null) => {
    setEditable(id)
  }, [])

  const handleAddQuestion = (values: QuestionType) => {
    addQuestion?.(values)
    setAddQuestionDialogOpen(false)
    dispatch(addMessage({message: 'New question was successfully added', id: generateId(), type: 'success'}))
  }

  const handleRemoveQuestion = (id: string) => {
    const node = tree.items[id]
    const description = node.hasChildren ? `If u remove it, the subquestions will be removed too` : ''

    confirm({
      variant: 'danger',
      catchOnCancel: false,
      title: 'Are you sure you want to delete this question ?',
      description,
    }).then(() => {
      removeQuestion(id)
      if (id === editable) {
        setEditable(null)
      }
    })
  }

  const handleOpenAddQuestionDialog = () => {
    setAddQuestionDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setAddQuestionDialogOpen(false)
  }

  const handleSaveChanges = useCallback(() => {
    async function saveChanges() {
      setIsLoading(true)

      if (currentRelease.isActiveRelease || currentRelease.releaseDate) {
        await MidtierService.saveCSTRelease(currentQuestionnaire)
      } else {
        await MidtierService.updateCSTRelease(currentRelease.id, currentQuestionnaire)
      }

      const {currentRelease: updatedRelease, currentActiveRelease, blacklist} = await MidtierService.getCurrentRelease()
      loadData({currentRelease: updatedRelease, blacklist, currentActiveRelease})

      setIsLoading(false)
      dispatch(addMessage({message: 'Changes were successfully saved', id: generateId(), type: 'success'}))
    }

    saveChanges()
  }, [
    currentQuestionnaire,
    currentRelease.id,
    currentRelease.isActiveRelease,
    currentRelease.releaseDate,
    dispatch,
    loadData,
  ])

  useEffect(() => {
    setTree(getTreeStructure(dashboard))
  }, [section, subSection, dashboard])

  const isSaveChangesDisabled = useMemo(
    () => isLoading || isEqual(currentQuestionnaire, currentRelease.assessment.subjectAreas),
    [currentQuestionnaire, currentRelease.assessment.subjectAreas, isLoading],
  )

  const renderSearchResult = (question: QuestionType) => (
    <div key={question.id} style={{paddingTop: '15px'}}>
      <Question
        data={question}
        hideDragEmmiter
        displayNumber={getDisplayNumber(tree, question.id) || 'uknown'}
        isExpanded={question.id === expanded}
        disableToggle={question.id === editable || !!editable}
        onToggle={handleToggleQuestion}
        onRemove={handleRemoveQuestion}
        onEdit={handlEditQuestion}
        mode={question.id === editable ? 'edit' : 'display'}
      />
    </div>
  )

  return (
    <Fragment>
      <Box height="100%" display="flex" flexDirection="column">
        <ChemoSafeToolDashboard.Header />
        <ChemoSafeToolDashboard.SearchQuestionsForm />
        <Box className={classes.sectionBox} marginY={0}>
          <Typography component="div" className={classes.sectionSubBox}>
            <Box fontWeight="fontWeightBold" m={1}>
              {section}
            </Box>
            : <Box m={1}>{subSection}</Box>
          </Typography>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSaveChanges}
            disabled={isSaveChangesDisabled}
            // style={{alignSelf: 'flex-end', marginRight: '20px', minWidth: '150px'}}
            className={classes.saveButton}
          >
            {isLoading && <CircularProgress color="primary" size="25px" style={{padding: '5px'}} />}
            Save Changes
          </Button>
          <Button
            size="medium"
            startIcon={<Add />}
            // style={{textTransform: 'none', color: '#EB7346'}}
            className={classes.addNewQuesButton}
            onClick={handleOpenAddQuestionDialog}
          >
            Add New Question
          </Button>
        </Box>
        <Box className={classes.dashboard}>
          <ChemoSafeToolDashboard.SearchResults renderItem={renderSearchResult} />
          {!questionName && (
            <Dashboard
              treeStructure={tree}
              onDragEnd={handleDragEnd}
              onToggleQuestion={handleToggleQuestion}
              onEditQuestion={handlEditQuestion}
              onRemoveQuestion={handleRemoveQuestion}
              expandedQuestion={expanded}
              editableQuestion={editable}
            />
          )}
        </Box>
      </Box>
      <AddQuestionDialog isOpen={isAddQuestionDialogOpen} onClose={handleCloseDialog} onSubmit={handleAddQuestion} />
    </Fragment>
  )
}

ChemoSafeToolDashboard.Header = DashboardHeader
ChemoSafeToolDashboard.SearchResults = DashboardSearchResults
ChemoSafeToolDashboard.SearchQuestionsForm = SearchQuestionsForm

export default ChemoSafeToolDashboard
/*eslint-enable max-lines-per-function*/
