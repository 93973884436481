import {createMuiTheme} from '@material-ui/core'

const customMuiTheme = createMuiTheme({
  palette: {
    primary: {main: '#EB7346'},
  },
  typography: {
    fontFamily: 'Quicksand',
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 700,
    },
    button: {
      fontStyle: 'normal',
    },
    h1: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.2rem',
      fontWeight: 700,
    },
  },
  overrides: {
    MuiContainer: {
      maxWidthLg: {
        maxWidth: '1400px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        '&$focused $notchedOutline': {
          borderColor: '#eb7346',
          borderWidth: '1px',
        },
        '&:hover $notchedOutline': {
          borderColor: '#eb7040a1',
        },
      },
      notchedOutline: {
        borderWidth: '1px',
      },
    },
    MuiExpansionPanelSummary: {
      content: {
        margin: 0,
        '&$expanded': {
          margin: 0,
        },
      },
      root: {
        '&$expanded': {
          borderBottom: '1px solid #eee',
          backgroundColor: '#FFF9F6',
        },
        '&$focused': {
          backgroundColor: '#FFF9F6',
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: '#eb7643',
      },
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#eb7643',
        },
      },
    },
    MuiInput: {
      underline: {
        '&::after': {
          borderBottom: 'none',
        },
        '&.Mui-disabled:before': {
          borderBottomStyle: 'none',
        },
        '&:before': {
          borderBottom: 'none',
        },
        '&:hover:before': {
          borderBottom: 'none',
        },
        '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
          borderBottom: 'none',
        },
        '&.Mui-focused': {
          borderBottom: 'none',
          '&:after': {
            transform: 'none !important',
          },
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        fontWeight: 700,
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: '#fff3',
      },
    },
    MuiCheckbox: {
      root: {
        '&$colorSecondary$checked': {
          color: '#eb7643',
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
    },
  },
})

export default customMuiTheme
