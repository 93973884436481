import React from 'react'

import st from './MemberCard.module.scss'

interface Props {
  className?: string
  name: string
  position?: string
  institution: string
  country?: string
  imgUrl?: string
  id?: string | number
}

const MemberCard: React.FunctionComponent<Props> = ({id, name, position, institution, country, imgUrl}) => {
  return (
    <div className={st.memberCard}>
      <div className={st.imgBlock}>
        <div
          className={st.img}
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      </div>
      <div className={st.description}>
        <h3 className={st.title}>{name}</h3>
        <p className={st.position}>{position}</p>
        <p className={st.place}>{institution}</p>
        <span className={st.country}>{country}</span>
      </div>
    </div>
  )
}

MemberCard.defaultProps = {
  name: '',
  position: '',
  country: '',
  imgUrl: `${process.env.PUBLIC_URL}${'images/avatars/not_found.png'}`,
}

export default MemberCard
