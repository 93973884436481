import {TreeData} from '@atlaskit/tree'

export const getDisplayNumber = (treeStructure: TreeData, matchId: string) => {
  const indexInRootNode = treeStructure.items[treeStructure.rootId].children.indexOf(matchId)
  if (indexInRootNode !== -1) return `${indexInRootNode + 1}`

  const parentNodeId = Object.keys(treeStructure.items).find(
    (id) => treeStructure.items[id].children.indexOf(matchId) !== -1,
  )

  if (parentNodeId) {
    const parentNumber = treeStructure.items[treeStructure.rootId].children.indexOf(parentNodeId) + 1
    const itemSymbol = String.fromCharCode(
      'a'.charCodeAt(0) + treeStructure.items[parentNodeId].children.indexOf(matchId),
    )
    return `${parentNumber}${itemSymbol}`
  }
}
