import React, {useState, useEffect} from 'react'
import {IoMdClose} from 'react-icons/io'
import {midtierAxios} from '../../services/api'
import Mark from './Mark'
import MarkDescription from './MarkDescription'

import st from './Nps.module.scss'

export default function Nps() {
  const [showModalForm, setShowModalForm] = React.useState(false)

  useEffect(() => {
    if (Date.now() - Number(localStorage.getItem('NPS_UPDATED_AT') || 0) < 30 * 24 * 3600 * 1000) return

    // uncomment to enable survey popup
    // if (Math.random() > 0.5) setTimeout(setShowModalForm, 10000, true)
  }, [])

  const handleClose = () => setShowModalForm(false)

  return showModalForm ? <AssessmentsModalForm handleClose={handleClose} /> : null
}

function AssessmentsModalForm({handleClose}: {handleClose: any}) {
  const [mark, setMark] = useState(-1)

  const [feedback, setFeedback] = useState('')

  const [showFeedbackResponse, setShowFeedbackResponse] = useState(false)

  const handleFeedbackInput = (e: any) => {
    setFeedback(e.currentTarget.value)
  }

  const handleSubmit = (e: SubmitEvent) => {
    e.preventDefault()

    midtierAxios.post('/nps', {text: feedback, rating: mark}).then(() => {
      setShowFeedbackResponse(true)
      setTimeout(() => {
        setShowFeedbackResponse(false)
        handleClose()
      }, 2000)

      localStorage.setItem('NPS_UPDATED_AT', Date.now().toString())
    })
  }

  return (
    <div className={st.wrapper}>
      {showFeedbackResponse ? (
        <>
          <h2 style={{fontSize: '1.75rem', marginBottom: '2rem'}}>Thank you for taking our survey.</h2>
          <h3 style={{fontSize: '1.25rem'}}>Your response is very important to us.</h3>
        </>
      ) : (
        <>
          <div>
            <div className={st.titleBlock}>
              <div className={st.title}>Tell us how we are doing!</div>
              <div onClick={handleClose}>
                <IoMdClose className={st.icon} />
              </div>
            </div>
            <div className={st.text}>How likely are you to recommend</div>
            <div className={st.text}>Allied Against Cancer to others?</div>
            <div className="assessments">
              <Mark mark={mark} setMark={setMark} />
            </div>
            <div className={st.like}>
              <div>(0) Not at all likely</div>
              <div>(10) Highly Likely</div>
            </div>
          </div>
          <form style={{display: mark > -1 ? 'block' : 'none'}} onSubmit={handleSubmit}>
            <MarkDescription mark={mark} />
            <label>
              <div>
                <textarea name="textareaNps" cols={50} rows={7} maxLength={1024} onInput={handleFeedbackInput} />
              </div>
              <div className={st.textareaTextLength}>Characters used: {feedback.length} out of 1024.</div>
              <div className={st.buttonBlock}>
                <input className={st.submitButton} type="submit" value="Submit" />
              </div>
            </label>
          </form>
        </>
      )}
    </div>
  )
}
