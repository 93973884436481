import React from 'react'
import {
  ChallengeItem,
  Challenges,
  Colored,
  Columns,
  Descriptive,
  ImageItem,
  Link,
  List,
  PagePreview,
  Parted,
  Text,
  Title,
  ContentWrap,
} from '../../components'
import st from './OurStory.module.scss'
import {
  additionalInformation,
  disclaimerText,
  howWeWorkText,
  ourMissionText,
  ourStoryColoredText,
  ourWork,
  practitionersText,
  processesText,
  productsText,
  whoWeArePartOneText,
  whoWeArePartThreeText,
  whoWeArePartTwoText,
} from './constants'

const OurStory = () => {
  return (
    <div className={st.ourStory}>
      <PagePreview title="Our Story" bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/our_story.jpeg`} />
      <Descriptive>
        <Title type="h3">Our Mission</Title>
        <Text>{ourMissionText}</Text>
        <Title type="h3">Background</Title>
        <Text>
          Sub-Saharan Africa’s cancer burden is significant and growing. In 2020, there were an estimated 846,000 new
          cases of cancer and 543,000 deaths from{' '}
          <Link
            to="https://gco.iarc.fr/today/data/factsheets/populations/991-who-africa-afro-fact-sheets.pdf "
            external
            target="_blank"
            rel="noopener noreferrer"
          >
            cancer in the region.
          </Link>
        </Text>
      </Descriptive>
      <ContentWrap>
        <Parted
          className={st.partedWrap}
          description={
            <div className={st.decsriptionInnerWrap}>
              <Title type="h3">
                By 2040, cancer incidence in Sub-Saharan will nearly double to 1.6 million new cases per year
              </Title>
            </div>
          }
          imageUrl={`${process.env.PUBLIC_URL}./images/our_story/deaths_annualy.jpeg`}
        />
      </ContentWrap>

      <Colored className={st.ourStoryColored}>
        <Text>{ourStoryColoredText}</Text>
      </Colored>
      <Descriptive>
        <Title type="h3">The Challenge</Title>
        <Challenges>
          <ChallengeItem
            title="Practitioners:"
            imgUrl={`${process.env.PUBLIC_URL}images/our_story/group6.png`}
            description={<Text>{practitionersText}</Text>}
          />
          <ChallengeItem
            title="Products:"
            imgUrl={`${process.env.PUBLIC_URL}images/our_story/group7.png`}
            description={<Text>{productsText}</Text>}
          />
          <ChallengeItem
            title="Processes:"
            imgUrl={`${process.env.PUBLIC_URL}images/our_story/group8.png`}
            description={<Text>{processesText}</Text>}
          />
        </Challenges>

        <Title type="h3">Who we are</Title>
        <Text>{whoWeArePartOneText}</Text>
        <Text>
          {whoWeArePartTwoText} Read more <Link to="/coalition">here</Link>.
        </Text>
        <Text>{whoWeArePartThreeText}</Text>

        <Title type="h3">How we work</Title>
        <Text>{howWeWorkText}</Text>
        <Text>Examples of our work include:</Text>
        <List items={ourWork} type="ul" styleType="dash" />
        <div className={st.partnerImagesBlock}>
          <Title className={st.partnersImagesTitle}>Founding partners</Title>
          <Columns number={3}>
            <ImageItem path="./images/our_story/acs-rgb.svg" style={{width: '134px'}} />
            <ImageItem path="./images/our_story/clinton.png" style={{width: '196px'}} />
            <ImageItem path="./images/our_story/nccn.svg" style={{width: '245px'}} />
          </Columns>
        </div>
        <Title type="h3">Additional information</Title>
        {additionalInformation.map((item, index) => {
          return (
            <Text key={index}>
              <Link external to={item.link} target="_blank" rel="noopener noreferrer">
                {item.text}
              </Link>
            </Text>
          )
        })}
        <Text>{disclaimerText}</Text>
      </Descriptive>
    </div>
  )
}

export default OurStory
