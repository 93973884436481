import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  variant?: 'danger' | 'info'
  title?: string
  description?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

const ConfirmationDialog = ({
  open,
  title = '',
  variant = 'info',
  description = '',
  onSubmit,
  onClose,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle id="alert-dialog-title" disableTypography>
        <Typography variant="h2" style={{color: 'black'}}>
          {title}
        </Typography>
      </DialogTitle>
      {description && (
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {variant === 'danger' && (
          <>
            <Button color="primary" onClick={onSubmit}>
              Confirm
            </Button>
            <Button color="primary" onClick={onClose} autoFocus>
              CANCEL
            </Button>
          </>
        )}

        {variant === 'info' && (
          <Button color="primary" onClick={onSubmit}>
            OK
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
