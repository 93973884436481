import localForage from 'localforage'
import {Tokens} from '../services/types'

export const setTokens = async (tokens: Tokens) => {
  await localForage.setItem('tokens', JSON.stringify(tokens))
}

export const delTokens = async () => {
  await localForage.setItem('tokens', null)
}

export const getTokens = async (): Promise<Tokens | null> => {
  const tokens: string | null = await localForage.getItem('tokens')
  if (tokens && tokens !== '') {
    return JSON.parse(tokens)
  }
  return null
}
