import React from 'react'

import st from './MarkDescription.module.scss'

export default function MarkDescription({mark}: {mark: number}) {
  const text =
    mark > 8
      ? `That's Great! Can you tell us what you like most about Allied Against Cancer? (optional)`
      : mark > 6
      ? `Thanks for your feedback. If we could do just one thing to improve, what would it be? (optional)`
      : `We'd love to know how we can improve. What was missing or disappointing in your experience with Allied Against Cancer? (optional)`

  return <div className={st['mark-description']}>{text}</div>
}
