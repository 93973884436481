import React from 'react'

import st from './Mark.module.scss'

export default function Mark({mark, setMark}: {setMark: any; mark: number}) {
  const items = Array.from({length: 11}, (_, idx) => idx)

  return (
    <div className={st.markArea}>
      {items.map((m) => (
        <div onClick={() => setMark(m)} data-active={m === mark} key={m}>
          {m}
        </div>
      ))}
    </div>
  )
}
