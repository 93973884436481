export const promoText = `  Allied Against Cancer has developed a series of digital tools to help scale and sustain solutions to building
  a high-quality cancer care system, especially where resources may be constrained. These tools - available at
  no cost to the oncology community - aim to help clinicians and health leaders make timely, insight-driven
  decisions to improve individuals' and populations' health.`

export const chemosafeBlock = {
  improveChemotherapySafetyText: {
    partOne: `The ChemoSafe mobile application enables you to evaluate how hazardous drugs are handled at every point
    of interaction in your healthcare facility, and benchmark current practices against international and
    national standards for safety and quality.`,
    partTwo: `The app delivers recommendations about how to improve safety, so you can develop action plans to
    minimize harmful exposure to hazardous drugs for health workers and patients.`,
  },
  benefitsList: [
    `Easy to complete self-assessment, based on yes or no questions`,
    `Recommendations on how to make quality and safety improvements according to expert institutions`,
    `References supporting each recommendation`,
    `Resources to support further action`,
    `Ability to use the app offline`,
    `Capability to record progress made over time to improve chemotherapy safety`,
  ],
}

export const navigatorBlock = {
  supportCancer: {
    partOne: `Cancer Guidelines Navigator is a digital tool that acts as a physician's companion to the NCCN Harmonized Guidelines™ for Sub-Saharan Africa. Physicians are guided to treatment options found in the Harmonized Guidelines based on the cancer-specific case
information they have entered.`,
    partTwo: `These guidelines, developed by the African Cancer Coalition and NCCN®, present general standard of care, regional-specific recommendations, and highly advanced care options. The tool includes guidelines for five of the most prevalent cancers in Africa - cervical cancer, breast cancer, prostate cancer, diffuse large B-cell lymphoma, and Kaposi Sarcoma.`,
  },
  benefitsList: [
    `Easy-to-reference treatment planning based on the NCCN Harmonized Guidelines™ for Sub-Saharan Africa`,
    `Custom treatment suggestions for each patient with no protected health information needed`,
    `Options displayed to represent variability in access to healthcare resources`,
    `Drug information available from Elsevier Gold Standard`,
    `Patient education materials for download`,
  ],
}
