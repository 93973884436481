/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable complexity */
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import {useHistory, RouteComponentProps} from 'react-router-dom'
import cn from 'classnames'
import {Form as FormikForm, FormikProps, withFormik} from 'formik'
import React from 'react'
import Toggle from 'react-toggle'
import Loader from 'react-loader-spinner'
import {connect} from 'react-redux'
import TagManager from 'react-gtm-module'

import {Button, Checkbox, Descriptive, ImageLoader, Input, Link, Select, Text, TextArea, Title} from '../../components'
import {setUser} from '../../redux/user/actions'
import {MidtierService} from '../../services'
import {validPasswordConfirmation} from '../../utils/validation/validationRules'
import {validator} from '../../utils/validation/validator'

import 'react-toggle/style.css'
import countries from '../../constants/countries'
import positions from '../../constants/positions'
import {LoaderProps} from '../../components/ImageLoader/ImageLoader'
import st from './SignUp.module.scss'
interface FormValues {
  firstName: string
  email: string
  lastName: string
  password: string
  confirmPassword: string
  position: string
  country: string
  institution: string
  eula: boolean
  isCoalitionMemberRequest: boolean
  isRequestAccessToChemoquant: boolean
  name?: string
  summary?: string
  interests?: string
  twitter?: string
  linkedIn?: string
  googleScholar?: boolean
  imgUrl?: string
}

// eslint-disable-next-line max-lines-per-function
const SignUp = (props: FormikProps<FormValues>) => {
  const history = useHistory()
  const sortingCountriesByAlphabet = countries.sort((a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  })
  const submitForm = (event: {preventDefault: () => void}) => {
    event.preventDefault()
    props.submitForm()
  }

  if (props.status.isSubmitted) {
    history.push('/success-send-email')
  }
  const setImageUrl = (options: LoaderProps) => {
    if (!options.error && options.imageUrl) {
      props.setValues({...props.values, imgUrl: options.imageUrl})
    }
  }
  const resetPublicProfileFields = () => {
    props.setValues({
      ...props.values,
      name: undefined,
      summary: undefined,
      interests: undefined,
      twitter: undefined,
      linkedIn: undefined,
      googleScholar: false,
      imgUrl: `${process.env.PUBLIC_URL}/images/avatars/not_found.png`,
    })
  }

  return (
    <div className={st.container}>
      <Descriptive>
        <Title type="h2" secondary className={st.title}>
          Sign up
        </Title>
        <Text>Please complete the required fields below.</Text>
        <FormikForm>
          <div className={cn(st.inputGroup, st.inputDoubleGroup)}>
            <Input
              id="firstName"
              value={props.values.firstName}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              label="First name *"
              placeholder="Given name"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.firstName && props.errors.firstName) || ''}
            />

            <Input
              id="lastName"
              value={props.values.lastName}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              label="Last name *"
              placeholder="Surname or family name"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.lastName && props.errors.lastName) || ''}
            />
          </div>

          <div className={st.inputGroup}>
            <Input
              id="email"
              value={props.values.email}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              label="Email *"
              placeholder="At what email email address would you like to be contacted?"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.email && props.errors.email) || ''}
            />
          </div>

          <div className={cn(st.inputGroup, st.inputDoubleGroup)}>
            <Input
              id="password"
              type="password"
              value={props.values.password}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              label="Password *"
              placeholder="Password must be at least 8 characters, must contain letter, symbol and number"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.password && props.errors.password) || ''}
            />

            <Input
              id="confirmPassword"
              type="password"
              value={props.values.confirmPassword}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              label="Confirm password *"
              placeholder="Must match with password"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.confirmPassword && props.errors.confirmPassword) || ''}
            />
          </div>

          <div className={cn(st.inputGroup, st.inputDoubleGroup)}>
            <Select
              id="position"
              label="Position *"
              value={props.values.position}
              placeholder="What is your occupation?"
              selectContainerClassName={st.inputContainer}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              options={positions}
              withFormik
              error={(props.errors && props.touched.position && props.errors.position) || ''}
            />

            <Select
              id="country"
              label="Country *"
              value={props.values.country}
              placeholder="What country do you work in?"
              selectContainerClassName={st.inputContainer}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              options={sortingCountriesByAlphabet}
              withFormik
              error={(props.errors && props.touched.country && props.errors.country) || ''}
            />
          </div>

          <div className={cn(st.inputGroup)}>
            <Input
              id="institution"
              value={props.values.institution}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              label="Institution *"
              placeholder="What is the name of your institution? (example: Uganda Cancer Institute)"
              error={(props.errors && props.touched.institution && props.errors.institution) || ''}
            />
          </div>
          <div className={st.inputGroup}>
            <p className={st.toggleDescription}>Are you a member of African Cancer Coalition?</p>
            <div className={st.toggleContainer}>
              <Toggle
                id="isCoalitionMemberRequest"
                onChange={(e) => {
                  resetPublicProfileFields()
                  props.handleChange(e)
                }}
                onBlur={props.handleBlur}
                defaultChecked={props.values.isCoalitionMemberRequest}
                aria-labelledby="isCoalitionMemberRequest"
              />
              <p className={st.toggleText}>{props.values.isCoalitionMemberRequest ? 'Yes' : 'No'}</p>
            </div>
          </div>
          {props.values.isCoalitionMemberRequest && (
            <>
              <div className={cn(st.inputGroup)}>
                <Input
                  id="name"
                  value={props.values.name}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Profile name"
                  placeholder="Name as it should appear on profile (Example: Thomas Smith, MD, MBA)"
                  inputContainerClassName={st.inputContainer}
                  error={(props.errors && props.touched.name && props.errors.name) || ''}
                />
              </div>
              <div className={cn(st.inputGroup)}>
                <TextArea
                  id="summary"
                  value={props.values.summary}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Professional Summary"
                  placeholder="Provide a brief professional summary. (Max 300 characters)"
                  textAreaContainerClassName={st.textAreaContainer}
                  error={(props.errors && props.touched.summary && props.errors.summary) || ''}
                />
              </div>
              <div className={cn(st.inputGroup)}>
                <TextArea
                  id="interests"
                  value={props.values.interests}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Interests"
                  placeholder="What would you like people to contact you about for collaboration? Please list here (example: specific research, clinical technique, collaboration on specific project) (max 200 characters)"
                  textAreaContainerClassName={st.textAreaContainer}
                  error={(props.errors && props.touched.interests && props.errors.interests) || ''}
                />
              </div>
              <div className={cn(st.inputGroup, st.inputDoubleGroup)}>
                <Input
                  id="twitter"
                  value={props.values.twitter}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="Twitter"
                  placeholder="Place here your Twitter profile link."
                  inputContainerClassName={st.inputContainer}
                  error={(props.errors && props.touched.twitter && props.errors.twitter) || ''}
                />
                <Input
                  id="linkedIn"
                  value={props.values.linkedIn}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  label="LinkedIn"
                  placeholder="Place here your Linkedin profile link."
                  inputContainerClassName={st.inputContainer}
                  error={(props.errors && props.touched.linkedIn && props.errors.linkedIn) || ''}
                />
              </div>
              <div className={st.checkBoxGroup}>
                <label className={st.singleLabel} htmlFor="googleScholar">
                  Google Scholar
                </label>
                <div className={st.checkboxWrap}>
                  <Checkbox
                    id="googleScholar"
                    checked={props.values.googleScholar}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    label={
                      <span className={st.checkBoxLabel}>
                        By checking this box, you agree to let us post your publications on GoogleScholar on your public
                        profile.
                      </span>
                    }
                    inputContainerClassName={st.checkboxContainer}
                    error={(props.errors && props.touched.googleScholar && props.errors.googleScholar) || ''}
                  />
                </div>
                <div className={st.uploadImageContainer}>
                  <ImageLoader
                    className={st.imageLoader}
                    label="Profile Image"
                    imageUrl={props.values.imgUrl}
                    setImageUrl={setImageUrl}
                    isSignUp={true}
                  />
                </div>
              </div>
            </>
          )}
          <div className={st.inputGroup}>
            <p className={st.toggleDescription}>Request Access to ChemoQuant</p>
            <div className={st.toggleContainer}>
              <Toggle
                id="isRequestAccessToChemoquant"
                onChange={(e) => {
                  resetPublicProfileFields()
                  props.handleChange(e)
                }}
                onBlur={props.handleBlur}
                defaultChecked={props.values.isRequestAccessToChemoquant}
                aria-labelledby="isRequestAccessToChemoquant"
              />
              <p className={st.toggleText}>{props.values.isRequestAccessToChemoquant ? 'Yes' : 'No'}</p>
            </div>
          </div>
          <div className={st.inputGroup}>
            <Checkbox
              id="eula"
              checked={props.values.eula}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              labelClassName={st.privacyText}
              label={
                <>
                  By checking this box, you agree to our Country-Specific Information{' '}
                  <Link
                    className={st.termsOfUseButton}
                    to="https://www.cancer.org/about-us/policies/user-agreement.html"
                    external
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms of Use
                  </Link>{' '}
                  and{' '}
                  <Link
                    className={st.policyButton}
                    to="https://www.cancer.org/about-us/policies/privacy-statement.html"
                    external
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </>
              }
            />
          </div>
          <div className={st.buttonsContainer}>
            <Button
              type="submit"
              disabled={!props.values.eula}
              className={st.button}
              onClick={submitForm}
              label={
                props.status.isSpinnerVisible ? (
                  <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
                ) : (
                  'Register'
                )
              }
            />
            <Button
              secondary
              label="Cancel"
              className={st.button}
              onClick={() => {
                history.push('/')
              }}
            />
          </div>
        </FormikForm>
      </Descriptive>
    </div>
  )
}

/**
 * Settings for Formik HOC
 *
 */
const WithFormik = withFormik<RouteComponentProps, FormValues>({
  enableReinitialize: true,
  handleSubmit: async (values, {setStatus}) => {
    setStatus({isSpinnerVisible: true})
    try {
      await MidtierService.signUp({
        email: values.email,
        password: values.password,
        firstName: values.firstName,
        lastName: values.lastName,
        position: values.position,
        institution: values.institution,
        country: values.country,
        isCoalitionMemberRequest: values.isCoalitionMemberRequest,
        isRequestAccessToChemoquant: values.isRequestAccessToChemoquant,
        publicProfile: {
          name: values.name || undefined,
          summary: values.summary || undefined,
          interests: values.interests || undefined,
          twitter: values.twitter || undefined,
          linkedIn: values.linkedIn || undefined,
          googleScholar: values.googleScholar,
          imgUrl:
            values.imgUrl === `${process.env.PUBLIC_URL}/images/avatars/not_found.png` ? undefined : values.imgUrl,
        },
      })

      TagManager.dataLayer({
        dataLayer: {
          event: 'SignUp',
          signUpPosition: values.position,
          signUpCountry: values.country,
          signUpInstitution: values.institution,
        },
      })

      setStatus({isSpinnerVisible: false, isSubmitted: true})
    } catch (e) {
      setStatus({isSpinnerVisible: false, isSubmitted: false})
    }
  },

  mapPropsToStatus: () => {
    return {
      isSubmitted: false,
      isSpinnerVisible: false,
    }
  },

  mapPropsToValues: () => {
    return {
      firstName: '',
      email: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      position: '',
      country: '',
      institution: '',
      isCoalitionMemberRequest: false,
      isRequestAccessToChemoquant: false,
      eula: false,
      name: undefined,
      summary: undefined,
      interests: undefined,
      twitter: undefined,
      linkedIn: undefined,
      googleScholar: false,
      imgUrl: `${process.env.PUBLIC_URL}/images/avatars/not_found.png`,
    }
  },

  validate: (values) => {
    return validator.rules({
      firstName: validator.validate(values.firstName).required().name(),
      lastName: validator.validate(values.lastName).required().name(),
      password: validator.validate(values.password).password().required(),
      confirmPassword: validator
        .validate({password: values.password, confirmPassword: values.confirmPassword})
        .custom(validPasswordConfirmation, []),
      position: validator.validate(values.position).required(),
      country: validator.validate(values.country).required(),
      institution: validator.validate(values.institution).required(),
      eula: validator.validate(values.eula).required(),
      email: validator.validate(values.email).required().email(),
      name: validator.validate(values.name).optional(),
      summary: validator.validate(values.summary).optional().maxLength(300, 'Maximum 300 characters are allowed'),
      interests: validator.validate(values.interests).optional().maxLength(200, 'Maximum 200 characters are allowed'),
      twitter: validator.validate(values.twitter).optional().url(),
      linkedIn: validator.validate(values.linkedIn).optional().url(),
    })
  },
})(SignUp)

export default connect(null, {setUser})(WithFormik)
