import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  searchContainer: {
    width: '70%',
    '& fieldset': {
      border: '1px solid lightgray',
      borderRadius: '4px',
      '&:hover': {
        borderColor: '#eb7643',
      },
    },
  },
  search: {
    width: '100%',
    zIndex: 0,
  },
})
