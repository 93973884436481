import React from 'react'
import Loader from 'react-loader-spinner'
import {Formik, Form, Field, FieldProps} from 'formik'
import {Input, Button, Text} from '../../../../../components'
import {MidtierService} from '../../../../../services'
import {setUser} from '../../../../../redux/user/actions'
import {setTokens} from '../../../../../utils/tokens'
import {UserState} from '../../../../../redux/user/types'
import {Tokens} from '../../../../../services/types'
import st from './SmallForms.module.scss'
import {checkValidationError} from './functions'

interface Props {
  handleResponse: (value: boolean) => void
  setTokensInState: typeof setTokens
  setUserInState: typeof setUser
  tokens: Tokens
  keepPassword: (password: string) => void
  user: UserState
}

const ConfirmUser: React.FunctionComponent<Props> = ({
  handleResponse,
  setTokensInState,
  setUserInState,
  keepPassword,
  user,
}) => {
  const validate = (values: {email: string; password: string}) => {
    return Object.keys(values).reduce((acc, fieldName) => {
      let error
      if (fieldName === 'email') {
        error = checkValidationError(fieldName)(values[fieldName])
      }
      return error ? {...acc, [fieldName]: error} : acc
    }, {})
  }

  return (
    <Formik
      initialValues={{email: user?.email || '', password: ''}}
      validate={validate}
      onSubmit={({email, password}, actions) => {
        MidtierService.signIn({email, password})
          .then(async (tokens) => {
            await setTokensInState(tokens)
            return MidtierService.getUser()
          })
          .then((user) => {
            setUserInState(user)
            handleResponse(user !== undefined)
            keepPassword(password)
          })
          .catch(() => {
            keepPassword(password)
            actions.setSubmitting(false)
          })
      }}
    >
      {(props) => {
        return (
          <Form>
            <Text className={st.text}>Please log in to confirm</Text>
            <div className={st.inputs}>
              <Field type="email" name="email">
                {(props: FieldProps) => <Text className={st.confirmUserlogin}>{props.field.value}</Text>}
              </Field>
              <Field type="password" name="password">
                {(props: FieldProps) => {
                  return (
                    <Input
                      inputContainerClassName={st.input}
                      id="password"
                      type="password"
                      placeholder="Password"
                      {...props.field}
                      error={props.form.errors[props.field.name] as string}
                    />
                  )
                }}
              </Field>
            </div>
            <div className={st.buttonBlock}>
              <Button
                type="submit"
                label={
                  props.isSubmitting ? (
                    <Loader
                      className={st.spinner}
                      type="TailSpin"
                      color="white"
                      height={24}
                      width={24}
                      visible={true}
                    />
                  ) : (
                    'Next'
                  )
                }
                className={st.buttons}
                disabled={!props.isValid || props.isSubmitting}
              />
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ConfirmUser
