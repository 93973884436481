import React from 'react'
import {Descriptive, Title} from '../../../../components'
import {SmallForms, FormsFlow} from '../components'
import st from './ChangeEmail.module.scss'

const ChangeEmail = () => {
  return (
    <Descriptive>
      <Title className={st.title}>Change Email</Title>
      <FormsFlow
        ConfirmUserForm={SmallForms.ConfirmUser}
        MainForm={SmallForms.ChangeEmail}
        ApprovedDialog={SmallForms.ApprovedDialog}
        approvedText="Email has been changed."
      />
    </Descriptive>
  )
}

export default ChangeEmail
