import React, {useEffect, useState} from 'react'
import {push as Menu, State} from 'react-burger-menu'
import './SideMenu.scss'

import {Link} from 'react-router-dom'
import {Logo} from '../..'
import st from './SideMenu.module.scss'
import {MenuContent} from './components'

interface SideMenuProps {
  isOpen: boolean
  setIsSideMenuOpen: (isOpened: boolean) => void
  isAuth: boolean
  isAdmin: boolean
  isChemosafeToolAdmin: boolean
}

const SideMenu = ({isAuth, isAdmin, isOpen, setIsSideMenuOpen, isChemosafeToolAdmin}: SideMenuProps) => {
  const closeSideMenu = () => {
    setIsSideMenuOpen(false)
  }
  const [width, setWidth] = useState(window.innerWidth)
  const updateWidth = () => {
    setWidth(window.innerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', updateWidth)
    return () => window.removeEventListener('resize', updateWidth)
  })

  return (
    <Menu
      pageWrapId="page-wrap"
      outerContainerId="outer-container"
      width="70%"
      isOpen={width < 1000 && isOpen}
      onStateChange={(state: State) => setIsSideMenuOpen(state.isOpen)}
    >
      <div>
        <Link className={st.title} to="/" onClick={closeSideMenu}>
          Allied Against Cancer
        </Link>
        <MenuContent
          closeSideMenu={closeSideMenu}
          isAuth={isAuth}
          isAdmin={isAdmin}
          isChemosafeToolAdmin={isChemosafeToolAdmin}
        />
        <div className={st.sideMenuFooter}>
          <Logo />
        </div>
      </div>
    </Menu>
  )
}

export default SideMenu
