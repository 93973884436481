import React, {useEffect, useState} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import Loader from 'react-loader-spinner'
import cn from 'classnames'
import {connect} from 'react-redux'
import {ChallengeItem, Challenges, Descriptive, ImageItem, Text, Title, Link, Button} from '../../components'
import {MidtierService} from '../../services'
import countries from '../../constants/countries'
import positions from '../../constants/positions'
import speciality from '../../constants/speciality'
import {AppState} from '../../redux'
import {USER_ROLES} from '../../constants/userRoles'
import {User} from '../../services/types'
import st from './UserPublicProfile.module.scss'

const UserPublicProfile = ({isAdmin}: AppReduxInjectedProps) => {
  const {id} = useParams()
  const history = useHistory()
  const [user, setUser] = useState<User | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  const getUserSpeciality = (userSpecialities: string[] | undefined): string | undefined => {
    return userSpecialities?.reduce((total: string, userSpeciality: string) => {
      const findSpeciality = speciality.find((speciality) => speciality.value === userSpeciality)
      if (findSpeciality) {
        return `${total} ${findSpeciality.name}`
      }
      return total
    }, '')
  }

  const getUser = async () => {
    try {
      if (id) {
        setIsLoading(true)
        const user = isAdmin
          ? await MidtierService.getUserByIdWithAdminPermission(id)
          : await MidtierService.getCoalitionMemberById(id)
        if (!user) {
          history.push('/404')
        }
        setUser({...user})
      }
      setIsLoading(false)
    } catch (e) {
      history.push('/404')
    }
  }
  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [id])
  return (
    <div className={st.profile}>
      {isLoading ? (
        <Loader className={st.spinner} type="TailSpin" color="#eb7346" height={300} width={300} visible={true} />
      ) : (
        <>
          <Descriptive className={st.shortProfileInfoHeader}>
            <Title className={st.header}>Profile page</Title>
            <div className={st.rowContainer}>
              <Challenges className={st.shortProfileInfo}>
                <ChallengeItem
                  className={st.challengeItem}
                  title={user?.publicProfile?.name}
                  imgUrl={`${process.env.PUBLIC_URL}${user?.publicProfile?.imgUrl}`}
                  description={
                    <>
                      <Text className={cn(st.profileInfoItem, st.boldText)}>{user?.email}</Text>
                      <Text className={st.profileInfoItem}>
                        {positions.find((position) => position.value === user?.position)?.name}
                      </Text>
                      <Text className={st.profileInfoItem}>{user?.institution}</Text>
                      <Text className={st.profileInfoItem}>{getUserSpeciality(user?.speciality)}</Text>
                      <Text className={st.profileInfoItem}>
                        {countries.find((country) => country.value === user?.country)?.name}
                      </Text>
                    </>
                  }
                />
              </Challenges>
              {isAdmin && (
                <Button
                  className={st.editButton}
                  label="Edit User"
                  onClick={() => {
                    history.push(`/profile/adminArea/publicProfile/${id}`)
                  }}
                />
              )}
            </div>
          </Descriptive>
          <Descriptive>
            <Title className={st.header}>Professional Summary</Title>
            <Text>{user?.publicProfile?.summary}</Text>
            <Title className={st.header}>Interests</Title>
            <Text>{user?.publicProfile?.interests}</Text>
            <Text className={st.socialIcons}>
              {user?.publicProfile?.twitter && (
                <Link to={user.publicProfile.twitter} external target="_blank">
                  <ImageItem path="/icons/twitter.svg" className={st.socialIcon} />
                </Link>
              )}
              {user?.publicProfile?.linkedIn && (
                <Link to={user?.publicProfile.linkedIn} external target="_blank">
                  <ImageItem path="/icons/linkedin.svg" className={st.socialIcon} />
                </Link>
              )}
            </Text>
          </Descriptive>
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    isAdmin: state.user?.roles.includes(USER_ROLES.ADMIN),
  }
}
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps)(UserPublicProfile)
