import React from 'react'

import Text from '../Text'
import Title from '../Title'
import st from './RightColumn.module.scss'

interface RightColumnProps {
  children?: React.ReactNode
  title: string
  text: string
}

const RightColumn = ({title, text}: RightColumnProps) => {
  return (
    <div className={st.rightColumn}>
      <Title type="h1">{title}</Title>
      <Text>{text}</Text>
    </div>
  )
}

export default RightColumn
