import cn from 'classnames'
import React from 'react'
import Link from '../Link'
import st from './List.module.scss'

interface ListProps {
  items: {name: string; url: string; description?: string}[]
  type: string
  styleType?: string
  links?: boolean
  target?: string
  style?: React.CSSProperties
}

const List = ({items, type, styleType, links, style, target}: ListProps) => {
  const ul = (
    <ul className={cn(st.ul, st[`${styleType}`])} style={style}>
      {items.map((item, index) => (
        <li
          key={index}
          className={cn(
            st.listItem,
            styleType === 'dash' && st.dashedElement,
            styleType === 'hash' && st.hashedElement,
            links && st.linkItem,
          )}
        >
          {links ? (
            <>
              <Link to={item.url} external target={target} rel="noopener noreferrer">
                {item.name}{' '}
              </Link>
              <h5 className={st.description}>{item.description}</h5>
            </>
          ) : (
            item.name
          )}
        </li>
      ))}
    </ul>
  )
  const ol = (
    <ol className={cn(st.ol, st[`${styleType}`])} style={style}>
      {items.map((item, index) => (
        <li key={index} className={cn(st.listItem, links && st.linkItem)}>
          {links ? (
            <Link to={item.url} external target={target} rel="noopener noreferrer">
              {item.name}{' '}
            </Link>
          ) : (
            item.name
          )}
        </li>
      ))}
    </ol>
  )
  return type === 'ul' ? ul : ol
}

List.defaultProps = {
  styleType: 'none',
  target: '_blank',
}
export default List
