import cn from 'classnames'
import React from 'react'
import st from '../TextArea/TextArea.module.scss'
import {ErrorMessage} from '../FormComponents'

interface TextAreaProps {
  textAreaContainerClassName?: string
  placeholder?: string
  id: string
  label?: string
  labelClassName?: string
  error?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void
}

const TextArea = ({
  value,
  textAreaContainerClassName,
  label,
  labelClassName,
  placeholder,
  error,
  id,
  onChange,
  onBlur,
  ...attrs
}: TextAreaProps) => {
  return (
    <div className={cn(st.inputContainer, textAreaContainerClassName)}>
      {label && (
        <label htmlFor={id} className={cn(st.textAreaLabel, labelClassName)}>
          {label}
        </label>
      )}
      <div className={st.textAreaWrap}>
        <textarea
          id={id}
          rows={5}
          className={st.textAreaBox}
          placeholder={placeholder}
          {...attrs}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        />
        {error && <ErrorMessage error={error} />}
      </div>
    </div>
  )
}
export default TextArea
