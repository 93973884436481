export const availableProducts = {
  BF: ['Pegfilgrastim', 'Trastuzumab'],
  BW: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  CD: ['Pegfilgrastim', 'Trastuzumab'],
  CI: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  CM: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  ET: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  GH: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  KE: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  LR: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  LS: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  ML: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  MW: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  MZ: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  NA: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  NG: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  RW: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  SD: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  SL: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  SN: [
    'Anastrozole',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  SZ: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Docetaxel',
    'Doxorubicin',
    'Filgrastim',
    'Fluorouracil',
    'Letrozole',
    'Paclitaxel',
    'Pegfilgrastim',
    'Tamoxifen',
    'Trastuzumab',
  ],
  TZ: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  UG: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  ZA: ['Bevacizumab', 'Bicalutamide', 'Pegfilgrastim', 'Trastuzumab'],
  ZM: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
  ZW: [
    'Anastrozole',
    'Bevacizumab',
    'Bicalutamide',
    'Carboplatin',
    'Cisplatin',
    'Cyclophosphamide',
    'Cytarabine',
    'Daunorubicin',
    'Docetaxel',
    'Doxorubicin',
    'Epirubicin',
    'Filgrastim',
    'Fluorouracil',
    'Gemcitabine',
    'Letrozole',
    'Leucovorin',
    'Methotrexate',
    'Oxaliplatin',
    'Paclitaxel',
    'Pegfilgrastim',
    'Rituximab',
    'Tamoxifen',
    'Trastuzumab',
    'Vincristine',
  ],
}
