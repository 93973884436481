import React from 'react'
import cn from 'classnames'
import st from '../../Tools.module.scss'
import {Text, Title} from '../../../../components'

interface PlatformVariantTileProps {
  title: string
  description: string
  imageUrl: string
  onClick: () => void
  selected: boolean
}
const PlatformVariantTile = ({title, description, imageUrl, onClick, selected}: PlatformVariantTileProps) => {
  return (
    <button className={cn(st.platformVariantTile, selected ? st.selected : st.notSelected)} onClick={onClick}>
      <div className={st.imageWrapper}>
        <img src={imageUrl} alt="" />
      </div>
      <div className={st.descriptionWrapper}>
        <Title color="#eb7346" type="h4" className={st.title}>
          {title}
          <img src={`${process.env.PUBLIC_URL}${'icons/arrow-orange.svg'}`} alt="" />
        </Title>
        <Text>{description}</Text>
      </div>
    </button>
  )
}

export default PlatformVariantTile
