/* eslint-disable max-lines-per-function,@typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
import React, {useEffect, useState} from 'react'
import ReactTable from 'react-table'
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import {bindActionCreators, Dispatch} from 'redux'
import cuid from 'cuid'
import {Button, Checkbox, Descriptive, Select} from '../../../components'
import {MyProfile} from '../../index'
import {AppActions, AppState} from '../../../redux'
import {setCoalitionRequests, setUsers} from '../../../redux/admin/actions'
import 'react-table/react-table.css'
import {MidtierService} from '../../../services'

import {USER_ROLES} from '../../../constants/userRoles'
import countries from '../../../constants/countries'
import {User} from '../../../services/types'
import {addMessage} from '../../../redux/alerts/actions'
import st from './AdminArea.module.scss'
import {GlobalSearch} from './components/GlobalSearch'
import {BlackCrossIcon} from './components'

type TableData =
  | 'All Users'
  | 'Coalition Members'
  | 'Coalition Requests'
  | 'Admins'
  | 'Limit Access'
  | 'Chemosafe tool Admins'
  | 'Chemoquant Requests'
  | 'Chemoquant Admins'

type TableAction =
  | 'Confirm ACC Membership'
  | 'Deny ACC Membership'
  | 'Confirm Request Access to ChemoQuant'
  | 'Deny Request Access to ChemoQuant'
  | 'Remove ACC Membership'
  | 'Delete user'
  | 'Make admin'
  | 'Remove admin role'
  | 'Limit Access to User'
  | 'Unlimited Access to User '
  | 'Make chemosafe tool admin'
  | 'Make chemoquant admin'
  | 'Remove chemosafe tool admin role'
  | 'Remove chemoquant admin role'

const allUsersActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Delete user', value: 'Delete user'},
  {name: 'Make admin', value: 'Make admin'},
  {name: 'Limit Access to User', value: 'Limit Access to User'},
  {name: 'Make chemosafe tool admin', value: 'Make chemosafe tool admin'},
  {name: 'Make chemoquant admin', value: 'Make chemoquant admin'},
]

const coalitionMembersActions: Array<{name: TableAction; value: TableAction}> = [
  ...allUsersActions,
  {name: 'Remove ACC Membership', value: 'Remove ACC Membership'},
]

const coalitionRequestsActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Confirm ACC Membership', value: 'Confirm ACC Membership'},
  {name: 'Deny ACC Membership', value: 'Deny ACC Membership'},
]

const adminActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Remove admin role', value: 'Remove admin role'},
]

const chemosafeToolAdminActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Remove chemosafe tool admin role', value: 'Remove chemosafe tool admin role'},
]

const chemoquantRequestsActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Confirm Request Access to ChemoQuant', value: 'Confirm Request Access to ChemoQuant'},
  {name: 'Deny Request Access to ChemoQuant', value: 'Deny Request Access to ChemoQuant'},
]

const chemoquantAdminActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Remove chemoquant admin role', value: 'Remove chemoquant admin role'},
]

const limitUsersActions: Array<{name: TableAction; value: TableAction}> = [
  {name: 'Unlimited Access to User ', value: 'Unlimited Access to User '},
]

const tableData: Array<{name: TableData; value: TableData}> = [
  {name: 'All Users', value: 'All Users'},
  {name: 'Coalition Members', value: 'Coalition Members'},
  {name: 'Coalition Requests', value: 'Coalition Requests'},
  {name: 'Admins', value: 'Admins'},
  {name: 'Limit Access', value: 'Limit Access'},
  {name: 'Chemoquant Admins', value: 'Chemoquant Admins'},
  {name: 'Chemoquant Requests', value: 'Chemoquant Requests'},
  {name: 'Chemosafe tool Admins', value: 'Chemosafe tool Admins'},
]
const AdminArea = ({setUsers, users, setCoalitionRequests, user, addMessage}: AppReduxInjectedProps) => {
  const [isActionProcess, setIsActionProcess] = useState<boolean>(false)
  const [filteredUsers, setFilteredUsers] = useState<Array<User>>([])
  const [globalSearch, setGlobalSearch] = useState<string>('')
  const [tableDataType, setTableDataType] = useState<TableData>('All Users')
  const [tableAction, setTableAction] = useState<TableAction | undefined>(undefined)
  const [tableActions, setTableActions] = useState<Array<{name: TableAction; value: TableAction}>>([])
  const [selectedUsers, setSelectedUsers] = useState<Array<string>>([])
  const [isDownloadProcess, setIsDownloadProcess] = useState<boolean>(false)

  console.log({users})

  const resetAllFiltersAndGetUsers = async () => {
    const users = await MidtierService.getAllUsers()
    setUsers(users)
    setFilteredUsers(users)
    setGlobalSearch('')
    setTableDataType('All Users')
    setTableAction(undefined)
    setTableActions(allUsersActions)
    setSelectedUsers([])
  }

  useEffect(() => {
    switch (tableDataType) {
      case 'All Users':
        setTableActions(allUsersActions)
        break
      case 'Coalition Members':
        setTableActions(coalitionMembersActions)
        break
      case 'Coalition Requests':
        setTableActions(coalitionRequestsActions)
        break
      case 'Admins':
        setTableActions(adminActions)
        break
      case 'Limit Access':
        setTableActions(limitUsersActions)
        break
      case 'Chemoquant Admins':
        setTableActions(chemoquantAdminActions)
        break
      case 'Chemoquant Requests':
        setTableActions(chemoquantRequestsActions)
        break
      case 'Chemosafe tool Admins':
        setTableActions(chemosafeToolAdminActions)
        break
      default:
        setTableActions(allUsersActions)
        break
    }
  }, [tableDataType])

  const getCountryNameByValue = (value: string) => countries.find((country) => country.value === value)?.name

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await MidtierService.getAllUsers()
        const usersWithCountries: User[] = users.map((user) => {
          const country = getCountryNameByValue(user.country)
          return {
            ...user,
            country: country ? country : 'Undefined',
          }
        })
        setUsers(usersWithCountries)
        setFilteredUsers(usersWithCountries)
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
    getUsers()
    // eslint-disable-next-line
  }, [])
  const getTrProps = (state: any, rowInfo: any) => {
    if (rowInfo) {
      return {
        style: {
          background: rowInfo.row._original.isCoalitionMemberRequest ? 'rgba(250,100,0,0.2)' : 'white',
          color: 'black',
        },
      }
    }
    return {}
  }

  const changeTableData = (event: any) => {
    setTableDataType(event.target.value)
    setTableAction(undefined)
    setSelectedUsers([])
    switch (event.target.value) {
      case 'All Users':
        setFilteredUsers(users)
        break
      case 'Coalition Members':
        setFilteredUsers(users.filter((user) => user.roles.includes(USER_ROLES.COALITION_MEMBER)))
        break
      case 'Coalition Requests':
        setFilteredUsers(users.filter((user) => user.isCoalitionMemberRequest))
        break
      case 'Admins':
        setFilteredUsers(users.filter((user) => user.roles.includes(USER_ROLES.ADMIN)))
        break
      case 'Limit Access':
        setFilteredUsers(users.filter((user) => user.isLimitAccess))
        break
      case 'Chemoquant Requests':
        setFilteredUsers(users.filter((user) => user.isRequestAccessToChemoquant))
        break
      case 'Chemoquant Admins':
        setFilteredUsers(users.filter((user) => user.roles.includes(USER_ROLES.CHEMOQUANT_ADMIN)))
        break
      case 'Chemosafe tool Admins':
        setFilteredUsers(users.filter((user) => user.roles.includes(USER_ROLES.CHEMOSAFE_TOOL_ADMIN)))
        break
      default:
    }
  }

  const changeAction = (event: any) => {
    setTableAction(event.target.value)
  }

  const globalSearchFilter = (users: User[]) =>
    users.filter(
      (user) =>
        user.email.includes(globalSearch) ||
        user.firstName.includes(globalSearch) ||
        user.lastName.includes(globalSearch) ||
        user.institution.includes(globalSearch) ||
        user.country.includes(globalSearch),
    )

  const toggleRow = (id: string) => {
    selectedUsers.includes(id)
      ? setSelectedUsers(selectedUsers.filter((selectedId) => selectedId !== id))
      : setSelectedUsers([...selectedUsers, id])
  }

  const handleDownloadUserList = async () => {
    try {
      setIsDownloadProcess(true)
      await MidtierService.getUsersList()
      setIsDownloadProcess(false)
    } catch (e) {
      setIsDownloadProcess(false)
    }
  }

  const handleAction = async () => {
    setIsActionProcess(true)
    try {
      switch (tableAction) {
        case 'Make admin':
          const makeAdminPromises = selectedUsers.map(async (userId) => {
            await MidtierService.addUserRole({userId, role: USER_ROLES.ADMIN})
          })
          await Promise.all(makeAdminPromises)
          break
        case 'Confirm ACC Membership':
          const confirmAccPromises = selectedUsers.map(async (userId) => {
            await MidtierService.addUserRole({userId, role: USER_ROLES.COALITION_MEMBER})
          })
          await Promise.all(confirmAccPromises)
          setCoalitionRequests(await MidtierService.getCoalitionRequests())
          break
        case 'Confirm Request Access to ChemoQuant':
          const confirmChemoquantPromises = selectedUsers.map(async (userId) => {
            await MidtierService.addUserRole({userId, role: USER_ROLES.CHEMOQUANT_ADMIN})
          })
          await Promise.all(confirmChemoquantPromises)
          await MidtierService.getChemoquantRequests()
          break
        case 'Delete user':
          const deleteUserPromises = selectedUsers.map(async (userId) => {
            user?.id !== userId && (await MidtierService.deleteUserByAdmin({userId}))
          })
          await Promise.all(deleteUserPromises)
          break
        case 'Deny ACC Membership':
          const denyAccPromises = selectedUsers.map(async (userId) => {
            await MidtierService.denyCoalitionRequest({userId})
          })
          await Promise.all(denyAccPromises)
          setCoalitionRequests(await MidtierService.getCoalitionRequests())
          break
        case 'Deny Request Access to ChemoQuant':
          const denyChemoquantPromises = selectedUsers.map(async (userId) => {
            await MidtierService.denyChemoquantRequest({userId})
          })
          await Promise.all(denyChemoquantPromises)
          await MidtierService.getChemoquantRequests()
          break
        case 'Remove chemoquant admin role':
          const delChemoquantAdminPromises = selectedUsers.map(async (userId) => {
            userId !== user?.id && (await MidtierService.delUserRole({userId, role: USER_ROLES.CHEMOQUANT_ADMIN}))
          })
          await Promise.all(delChemoquantAdminPromises)
          break
        case 'Remove ACC Membership':
          const removeAccPromises = selectedUsers.map(async (userId) => {
            await MidtierService.delUserRole({userId, role: USER_ROLES.COALITION_MEMBER})
          })
          await Promise.all(removeAccPromises)
          break
        case 'Remove admin role':
          const delAdminPromises = selectedUsers.map(async (userId) => {
            userId !== user?.id && (await MidtierService.delUserRole({userId, role: USER_ROLES.ADMIN}))
          })
          await Promise.all(delAdminPromises)
          break
        case 'Remove chemosafe tool admin role':
          const delChemosafeToolAdminPromises = selectedUsers.map(async (userId) => {
            userId !== user?.id && (await MidtierService.delUserRole({userId, role: USER_ROLES.CHEMOSAFE_TOOL_ADMIN}))
          })
          await Promise.all(delChemosafeToolAdminPromises)
          break
        case 'Make chemoquant admin':
          const addChemoqunantAdminPromises = selectedUsers.map(async (userId) => {
            await MidtierService.addUserRole({userId, role: USER_ROLES.CHEMOQUANT_ADMIN})
          })
          await Promise.all(addChemoqunantAdminPromises)
          break
        case 'Make chemosafe tool admin':
          const addChemosafeToolAdminPromises = selectedUsers.map(async (userId) => {
            await MidtierService.addUserRole({userId, role: USER_ROLES.CHEMOSAFE_TOOL_ADMIN})
          })
          await Promise.all(addChemosafeToolAdminPromises)
          break
        case 'Limit Access to User':
          const limitAccessPromises = selectedUsers.map(async (userId) => {
            await MidtierService.updateUserByAdmin({userId, isLimitAccess: true})
          })
          await Promise.all(limitAccessPromises)
          break
        case 'Unlimited Access to User ':
          const unlimitedAccessPromises = selectedUsers.map(async (userId) => {
            await MidtierService.updateUserByAdmin({userId, isLimitAccess: false})
          })
          await Promise.all(unlimitedAccessPromises)
          break
        default:
          break
      }
      addMessage({id: cuid(), message: 'Action successfully completed', type: 'success'})
      await resetAllFiltersAndGetUsers()
      setIsActionProcess(false)
      setTableAction(undefined)
      setSelectedUsers([])
    } catch (e) {
      setIsActionProcess(false)
      setTableAction(undefined)
      setSelectedUsers([])
    }
  }

  const columns = [
    {
      id: 'checkbox',
      accessor: '',
      Header: () => {
        return (
          selectedUsers.length > 0 && (
            <button className={st.iconButtonStyle} onClick={() => setSelectedUsers([])}>
              <BlackCrossIcon />
            </button>
          )
        )
      },
      Cell: ({row}: {row: any}) => {
        return (
          <Checkbox
            inputContainerClassName={st.textCenter}
            id="user-table"
            className="checkbox"
            checked={selectedUsers.includes(row._original.id)}
            onChange={() => toggleRow(row._original.id)}
          />
        )
      },
      sortable: false,
      width: 45,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({row}: {row: any}) => {
        return <NavLink to={`adminArea/user/${row._original.id}`}>{row._original.email}</NavLink>
      },
    },
    {
      Header: 'Institution',
      accessor: 'institution',
    },
    {
      Header: 'First Name',
      accessor: 'firstName',
    },
    {
      Header: 'LastName',
      accessor: 'lastName',
    },
    {
      Header: 'Country',
      accessor: 'country',
      Cell: ({row}: {row: any}) => {
        const country = countries.find((country) => country.value === row._original.country)
        return country ? country.name : row._original.country
      },
    },
  ]

  return (
    <Descriptive>
      <MyProfile.Title>Admin Area</MyProfile.Title>
      <div className={st.selectContainer}>
        <Select
          id="table-data"
          value={tableDataType}
          onChange={changeTableData}
          label="Choose table data"
          placeholder="Table data"
          options={tableData}
        />
        <div className={st.selectElement}>
          <Select
            id="table-action"
            className={st.customSelect}
            value={tableAction ? tableAction : ''}
            onChange={changeAction}
            label="Choose action"
            placeholder="Choose action"
            options={tableActions}
          />
          <Button
            className={st.okButton}
            label={
              isActionProcess ? (
                <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
              ) : (
                'OK'
              )
            }
            disabled={isActionProcess || tableAction === undefined || selectedUsers.length === 0}
            onClick={handleAction}
          />
          <Button
            className={st.downloadButton}
            label={
              isDownloadProcess ? (
                <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
              ) : (
                'Download user list'
              )
            }
            disabled={isDownloadProcess}
            onClick={handleDownloadUserList}
          />
        </div>
      </div>
      <GlobalSearch setGlobalSearch={setGlobalSearch} globalSearch={globalSearch} />
      <ReactTable
        data={globalSearchFilter(filteredUsers)}
        columns={columns}
        className="reactTable"
        loading={users.length === 0}
        getTrProps={getTrProps}
      />
    </Descriptive>
  )
}
const mapStateToProps = (state: AppState) => {
  return {
    users: state.admin.users,
    coalitionRequests: state.admin.coalitionRequests,
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) =>
  bindActionCreators(
    {
      setUsers,
      setCoalitionRequests,
      addMessage,
    },
    dispatch,
  )
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(AdminArea)
