import './reset.scss'
import './index.scss'

import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'

import React from 'react'
import ReactDOM from 'react-dom'
import TagManager from 'react-gtm-module'
import App from './app'
import {store, persistor} from './app/redux'

if (
  window.location.href.startsWith("https://www.alliedagainstcancer.org") ||
    window.location.href.startsWith("https://alliedagainstcancer.org")
) {
  TagManager.initialize({gtmId: 'GTM-59C7QRC'})
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
)
