import cn from 'classnames'
import React from 'react'

import {ContentWrap, Text, Title} from '../../../components'
import st from './PagePreview.module.scss'

interface PagePreviewProps {
  className?: string
  bgImageUrl?: string
  title?: string | React.ReactNode
  titleStyle?: React.CSSProperties
  description?: string | React.ReactNode
  styles?: React.CSSProperties
}

const PagePreview = ({className, bgImageUrl, title, description, styles, titleStyle}: PagePreviewProps) => {
  return (
    <div
      className={cn(st.pagePreview, className)}
      style={{
        ...styles,
        ...{
          backgroundImage: `url(${bgImageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: 'gray',
        },
      }}
    >
      <ContentWrap className={st.contentWrap}>
        <div className={st.description}>
          <Title className={st.title} type="h1" alt style={titleStyle}>
            {title}
          </Title>
          <Text className={st.text}>{description}</Text>
        </div>
      </ContentWrap>
    </div>
  )
}

PagePreview.defaultProps = {
  backgroundUrl: '../../assets/images/headers/our_story.jpeg',
  title: 'Page Title',
  text: '',
  styles: {},
}

export default PagePreview
