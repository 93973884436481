import React from 'react'
import {ComposableMap, Geographies, Geography} from 'react-simple-maps'
import {geoUrl, countries} from '../../../../constants'
import Marker from './Marker'
import st from './MapChart.module.scss'

export interface MapChartProps {
  selectedCountry: string
  onSelectCountry: (countryIso: string) => void
}

const MapChart = ({selectedCountry, onSelectCountry}: MapChartProps) => {
  return (
    <ComposableMap
      width={505}
      height={550}
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-17, -2, 0],
        scale: 430,
      }}
    >
      <Geographies geography={geoUrl} className={st.geographies}>
        {({geographies}) =>
          geographies
            .filter((d) => d.properties.REGION_UN === 'Africa')
            .map((geo) => {
              return (
                <Geography
                  style={{
                    default: {
                      fill: '#e0e0e0',
                      outline: 'none',
                      stroke: '#f9f9f9',
                    },
                    hover: {
                      fill: '#CBCBCB',
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#CBCBCB',
                      outline: 'none',
                    },
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => countries[geo.properties.ISO_A2] && onSelectCountry(geo.properties.ISO_A2)}
                />
              )
            })
        }
      </Geographies>
      {Object.keys(countries).map((country) => (
        <Marker
          key={country}
          country={countries[country]}
          countryIso={country}
          selectedCountry={selectedCountry}
          onSelectCountry={onSelectCountry}
        />
      ))}
    </ComposableMap>
  )
}

export default MapChart
