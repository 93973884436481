import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {AppActions, AppState} from '../../redux'
import {setActiveModal, closeModal} from '../../redux/modals/actions'
import {MODAL_NAMES} from '../../redux/modals/types'
import {LoginOrSignupModal, LogoffModal} from './components'

const ModalConductor = ({activeModal, closeModal}: Props) => {
  switch (activeModal) {
    case MODAL_NAMES.LOGOFF:
      return <LogoffModal activeModal={activeModal} closeModal={closeModal} />
    case MODAL_NAMES.NAVIGATOR:
      return <LoginOrSignupModal activeModal={activeModal} closeModal={closeModal} />
    default:
      return null
  }
}

const mapStateToProps = (state: AppState) => {
  return {activeModal: state.modals.activeModal}
}
const mapDispatchToProps = (dispatch: Dispatch<AppActions>) =>
  bindActionCreators(
    {
      setActiveModal,
      closeModal,
    },
    dispatch,
  )
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
export default connect(mapStateToProps, mapDispatchToProps)(ModalConductor)
