import React from 'react'

const BellIconWithoutNotifications = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Actions-/-Toggle-/-notification--new-/-24">
        <path
          d="M19.2765 14.475L17.2515 12.45L17.2515 9C17.2466 5.14323 14.3365 1.9098 10.5015 1.5L10.5015 0L9.00154 0L9.00154 1.5C5.17987 1.93567 2.28363 5.15371 2.25154 9L2.25154 12.45L0.22654 14.475C0.0783961 14.6087 -0.00381675 14.8005 0.00153956 15L0.00153956 17.25C-0.0118719 17.4527 0.0628285 17.6514 0.206492 17.795C0.350155 17.9387 0.548812 18.0134 0.75154 18L6.00154 18C6.00154 20.0711 7.68047 21.75 9.75154 21.75C11.8226 21.75 13.5015 20.0711 13.5015 18L18.7515 18C18.9543 18.0134 19.1529 17.9387 19.2966 17.795C19.4403 17.6514 19.5149 17.4527 19.5015 17.25L19.5015 15C19.5069 14.8005 19.4247 14.6087 19.2765 14.475ZM1.50154 16.5L18.0015 16.5L18.0015 15.3L15.9765 13.275C15.8284 13.1413 15.7462 12.9495 15.7515 12.75L15.7515 9C15.7515 5.68629 13.0652 3 9.75154 3C6.43783 3 3.75154 5.68629 3.75154 9L3.75154 12.75C3.7569 12.9495 3.67468 13.1413 3.52654 13.275L1.50154 15.3L1.50154 16.5ZM9.75154 20.25C8.5089 20.25 7.50154 19.2426 7.50154 18L12.0015 18C12.0015 19.2426 10.9942 20.25 9.75154 20.25Z"
          transform="translate(2.248461 0.75)"
          id="Fill"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  )
}

export default BellIconWithoutNotifications
