import React from 'react'

interface ImageItemProps {
  path: string
  alt?: string
  style?: React.CSSProperties
  className?: string
}

const ImageItem = ({path, alt, style, className}: ImageItemProps) => {
  return <img src={`${process.env.PUBLIC_URL}${path}`} alt={alt} style={style} className={className} />
}

export default ImageItem
