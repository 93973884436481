import cn from 'classnames'
import React, {ReactElement} from 'react'
import {RouteComponentProps, withRouter} from 'react-router-dom'

import st from './Button.module.scss'

type ButtonType = 'submit' | 'reset' | 'button' | undefined
interface ButtonProps extends RouteComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (args?: any) => void
  className?: string
  label?: string | ReactElement
  containerStyle?: React.CSSProperties
  attrs?: React.ReactNodeArray
  to?: string
  external?: boolean
  target?: string
  icon?: ReactElement
  labelClassName?: string
  secondary?: boolean
  type?: ButtonType
  disabled?: boolean
  alt?: boolean
}

const Button: React.FunctionComponent<ButtonProps> = ({
  onClick,
  className,
  label,
  containerStyle,
  to,
  external,
  target,
  history,
  icon,
  labelClassName,
  secondary,
  type,
  disabled,
  alt,
  ...attrs
}) => {
  const onClickDefaultBehaviour = () => {
    if (external) {
      if (target) {
        window.open(`${to}`, target)
      } else {
        window.location.assign(`${to}`)
      }
    } else if (history && to) history.push(`${to}`)
  }
  return (
    <button
      className={cn(st.button, {[st.withIcon]: icon}, {[st.secondary]: secondary}, alt && st.alt, className)}
      style={containerStyle}
      disabled={disabled}
      type={type}
      onClick={onClick || onClickDefaultBehaviour}
      {...attrs}
    >
      <div className={cn(st.label, {[st.secondary]: secondary}, labelClassName)}>{label}</div>
      {icon}
    </button>
  )
}

Button.defaultProps = {
  label: 'Learn More',
}

export default withRouter(Button)
