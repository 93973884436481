const literature = [
  {
    name: 'Improving Access to PPE During COVID-19',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/ChemoSafe_PPE-COVID19.pdf',
    description:
      'Operational strategies for cancer programs and links to regulatory guidance on handling shortages of PPE.',
  },
  {
    name: 'ChemoSafe Literature and Standards review',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/ChemoSafe.Literature.and.Standards.Review.v2.5.pdf',
  },
]

export {literature}
