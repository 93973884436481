/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
/* eslint-disable max-statements */
import React, {createContext, useContext, useReducer, ReactNode, useCallback, useMemo} from 'react'
import cloneDeep from 'lodash.clonedeep'
import {ChemosafeDocument, CSTRelease, Section, Subsection} from '../../../services/types'
import {calculateNewQuestionId} from './utils'

interface FilterValue {
  sectionName: string
  subSection: string[]
}
export interface ChemoSafeToolContextProps {
  forms?: {
    filters?: {
      section?: string
      subSection?: string
      questionName?: string
      showReferences?: boolean
      showResources?: boolean
      showRecommendations?: boolean
    }
  }
  filterValues?: FilterValue[]
  dashboard?: {
    questions?: []
    order?: {
      root: any
    }
  }
  resources?: ChemosafeDocument[]
  actions?: {
    changeFilters?: () => void
    updateCurrentRelease?: () => void
    loadData: (...args: any[]) => any
    updateResources?: () => void
  }
  currentActiveRelease?: CSTRelease
  currentRelease?: {
    id: string
    releaseDate: string
    isActiveRelease: boolean
  }
  currentQuestionnaire?: Section[]
  blacklist?: string[]
}

export interface ElementProps {
  id?: string
  labelText?: string
}

interface ActionProps {
  type?: string
  payload?: any
}

interface Filter {
  labelText: string
  subsections?: Subsection[]
}
export const ChemoSafeToolContext = createContext<ChemoSafeToolContextProps>({})

ChemoSafeToolContext.displayName = 'ChemoSafeToolContext'

const INITIAL_STATE = {
  currentActiveRelease: {},
  currentRelease: {},
  currentQuestionnaire: [],
  blacklist: [],
  resources: [],
  forms: {
    filters: {
      section: '',
      subSection: '',
      questionName: '',
      showReferences: true,
      showResources: true,
      showRecommendations: true,
    },
  },
  filterValues: [],
}

const getCurrentDashboard = (
  questionnaire: CSTRelease['assessment']['subjectAreas'],
  section: string,
  subSection: string,
) => {
  const questionnaireCopy = cloneDeep(questionnaire)
  const sectionIndex = questionnaireCopy?.findIndex((el) => el.labelText === section)
  const subSectionIndex = questionnaire[sectionIndex].subsections?.findIndex(
    ({labelText: subSectionName}) => subSectionName === subSection,
  )
  return {
    sectionIndex,
    subSectionIndex,
    structure: questionnaireCopy[sectionIndex].subsections[subSectionIndex],
  }
}

const reducer = (state: ChemoSafeToolContextProps, action: ActionProps) => {
  switch (action.type) {
    case 'ON_DATA_LOAD': {
      const {
        currentRelease: {
          assessment: {subjectAreas: questionnaire},
        },
      } = action.payload

      const filterValues = questionnaire?.map(({labelText: sectionName, subsections}: Filter) => {
        return {
          sectionName,
          subSections: subsections?.map(({labelText: subSectionName}: Filter) => subSectionName),
        }
      })

      return {
        ...state,
        ...action.payload,
        currentQuestionnaire: questionnaire,
        filterValues,
        forms: {
          ...state.forms,
          filters: {
            ...state?.forms?.filters,
            section: state?.forms?.filters?.section || filterValues[0].sectionName,
            subSection: state?.forms?.filters?.subSection || filterValues[0].subSections[0],
          },
        },
      }
    }
    case 'UPDATE_CURRENT_RELEASE': {
      return {...state, currentRelease: action.payload}
    }
    case 'ON_CHANGE_FILTER': {
      const filters = {...state?.forms?.filters, ...action.payload}
      return {
        ...state,
        forms: {filters},
      }
    }
    case 'REORDER_DASHBOARD': {
      const {
        currentQuestionnaire,
        forms: {
          filters: {subSection, section},
        },
      }: any = state
      const {newOrder, movedQuestionId, destinationQuestionId} = action.payload

      const {structure, sectionIndex, subSectionIndex} = getCurrentDashboard(currentQuestionnaire, section, subSection)
      const questionnaire = cloneDeep(currentQuestionnaire)
      structure.order = newOrder

      const movedQuestionIndex = structure.questions.findIndex(({id}) => id === movedQuestionId)
      const movedQuestion = structure.questions[movedQuestionIndex]
      // child -> parent
      if (movedQuestion?.conditionalPredicate && newOrder.root.indexOf(movedQuestionId) !== -1) {
        delete movedQuestion.conditionalPredicate
      }
      // parent -> child
      if (
        !structure.questions[movedQuestionIndex]?.conditionalPredicate &&
        newOrder.root.indexOf(movedQuestionId) === -1
      ) {
        movedQuestion.conditionalPredicate = {
          value: {questionId: destinationQuestionId, optionId: 'YES'},
        }
      }

      questionnaire[sectionIndex].subsections[subSectionIndex] = structure
      return {
        ...state,
        currentQuestionnaire: questionnaire,
      }
    }
    case 'REMOVE_QUESTION': {
      const {
        currentQuestionnaire,
        forms: {
          filters: {subSection, section},
        },
      }: any = state
      const {id: questionId} = action.payload

      const questionnaire = cloneDeep(currentQuestionnaire)
      const sectionIndex = questionnaire?.findIndex((el: ElementProps) => el.labelText === section)
      const subSectionIndex = questionnaire[sectionIndex].subsections.findIndex(
        (el: Subsection) => el.labelText === subSection,
      )

      const dashboard = questionnaire[sectionIndex].subsections[subSectionIndex]
      const updatedQuestions = dashboard.questions.filter(
        (el: ElementProps) =>
          el.id !== questionId && (dashboard.order[questionId] ? !dashboard.order[questionId].includes(el.id) : true),
      )

      const orderKey = Object.keys(dashboard.order).find((key) => dashboard.order[key]?.includes(questionId))
      orderKey &&
        dashboard.order[orderKey].splice(
          dashboard.order[orderKey].findIndex((id: string) => id === questionId),
          1,
        )
      delete dashboard.order[action.payload.id]

      questionnaire[sectionIndex].subsections[subSectionIndex] = {...dashboard, questions: updatedQuestions}

      return {
        ...state,
        currentQuestionnaire: questionnaire,
      }
    }
    case 'ADD_QUESTION': {
      const {
        currentQuestionnaire,
        forms: {
          filters: {subSection, section},
        },
        blacklist,
      }: any = state
      const newQuestion = {...action.payload}
      const {structure, sectionIndex, subSectionIndex} = getCurrentDashboard(currentQuestionnaire, section, subSection)
      newQuestion.id = calculateNewQuestionId(
        structure.questions.map(({id}) => id),
        blacklist,
        section,
        subSection,
      )
      const rootId = newQuestion?.conditionalPredicate?.value.questionId || 'root'
      if (!structure.order[rootId]) {
        structure.order[rootId] = []
      }
      structure.order[rootId].push(newQuestion.id)
      structure.questions.push(newQuestion)

      const questionnaire = cloneDeep(currentQuestionnaire)
      questionnaire[sectionIndex].subsections[subSectionIndex] = structure
      return {
        ...state,
        currentQuestionnaire: questionnaire,
      }
    }
    case 'EDIT_QUESTION': {
      const {
        currentQuestionnaire,
        forms: {
          filters: {subSection, section},
        },
      }: any = state
      const updatedQuestion = action.payload
      const {structure, sectionIndex, subSectionIndex} = getCurrentDashboard(currentQuestionnaire, section, subSection)

      const questionIndex = structure.questions.findIndex(({id}) => id === updatedQuestion.id)
      structure.questions[questionIndex] = {...structure.questions[questionIndex], ...updatedQuestion}
      const questionnaire = cloneDeep(currentQuestionnaire)
      questionnaire[sectionIndex].subsections[subSectionIndex] = structure
      return {
        ...state,
        currentQuestionnaire: questionnaire,
      }
    }
    case 'UPDATE_RESOURCES': {
      return {...state, resources: action.payload}
    }
    case 'TOGGLE_SHOW_IN_ASSESSMENT': {
      const {
        currentQuestionnaire,
        forms: {
          filters: {subSection, section},
        },
      }: any = state
      const questionId = action.payload
      const {structure, sectionIndex, subSectionIndex} = getCurrentDashboard(currentQuestionnaire, section, subSection)

      const questionIndex = structure.questions.findIndex(({id}) => id === questionId)
      const {showInAssessment} = structure.questions[questionIndex]
      structure.questions[questionIndex] = {...structure.questions[questionIndex], showInAssessment: !showInAssessment}
      structure.questions.forEach(({conditionalPredicate}, index) => {
        if (conditionalPredicate?.value.questionId === questionId) {
          structure.questions[index].showInAssessment = !showInAssessment
        }
      })
      const questionnaire = cloneDeep(currentQuestionnaire)
      questionnaire[sectionIndex].subsections[subSectionIndex] = structure

      return {
        ...state,
        currentQuestionnaire: questionnaire,
      }
    }
    default:
      return new Error('Incorrect type of action')
  }
}

interface ChemoSafeToolProviderPrrops {
  children: ReactNode
}

const ChemoSafeToolProvider = (props: ChemoSafeToolProviderPrrops) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const changeFilters = useCallback((filterValues) => {
    dispatch({type: 'ON_CHANGE_FILTER', payload: filterValues})
  }, [])

  const loadData = useCallback((bootstrapInfo) => {
    dispatch({
      type: 'ON_DATA_LOAD',
      payload: bootstrapInfo,
    })
  }, [])

  const reorderDashboard = useCallback((payload) => {
    dispatch({type: 'REORDER_DASHBOARD', payload})
  }, [])

  const addQuestion = useCallback((values) => {
    dispatch({type: 'ADD_QUESTION', payload: values})
  }, [])

  const removeQuestion = useCallback((id: string) => {
    dispatch({type: 'REMOVE_QUESTION', payload: {id}})
  }, [])

  const editQuestion = useCallback((updatedQuestion) => {
    dispatch({type: 'EDIT_QUESTION', payload: updatedQuestion})
  }, [])

  const toggleShowInAssessment = useCallback((questionId: string) => {
    dispatch({type: 'TOGGLE_SHOW_IN_ASSESSMENT', payload: questionId})
  }, [])

  const updateCurrentRelease = useCallback((release) => {
    dispatch({type: 'UPDATE_CURRENT_RELEASE', payload: release})
  }, [])

  const updateResources = useCallback((resource) => {
    dispatch({type: 'UPDATE_RESOURCES', payload: resource})
  }, [])

  const value = useMemo(
    () => ({
      ...state,
      dashboard: state.currentQuestionnaire.length
        ? getCurrentDashboard(state.currentQuestionnaire, state.forms.filters.section, state.forms.filters.subSection)
            ?.structure
        : {},
      actions: {
        removeQuestion,
        changeFilters,
        reorderDashboard,
        addQuestion,
        editQuestion,
        loadData,
        updateResources,
        toggleShowInAssessment,
        updateCurrentRelease,
      },
    }),
    [
      state,
      removeQuestion,
      changeFilters,
      reorderDashboard,
      addQuestion,
      editQuestion,
      updateResources,
      loadData,
      updateCurrentRelease,
      toggleShowInAssessment,
    ],
  )

  return <ChemoSafeToolContext.Provider value={value} {...props} />
}

function useCSTContext() {
  const value = useContext(ChemoSafeToolContext)
  if (!value) throw new Error('You can not use tool context outside of ChemoSafeToolContextProvider')
  return value
}

export default ChemoSafeToolProvider

export {useCSTContext}
