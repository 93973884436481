import cn from 'classnames'
import React from 'react'
import {Form as FormikForm, withFormik, FormikProps, FieldArray} from 'formik'
import {connect} from 'react-redux'
import {RouteComponentProps} from 'react-router-dom'
import Loader from 'react-loader-spinner'
import {Descriptive, Input, Title, Select, Button, Checkboxes} from '../../../components'
import {validator} from '../../../utils/validation/validator'
import speciality from '../../../constants/speciality'
import {AppState} from '../../../redux'
import {MidtierService} from '../../../services'
import {setUser} from '../../../redux/user/actions'
import {addMessage} from '../../../redux/alerts/actions'
import generateId from '../../../utils/generateId'
import positions from '../../../constants/positions'
import countries from '../../../constants/countries'
import {UserState} from '../../../redux/user/types'
import {User} from '../../../services/types'
import st from './ProfileInformation.module.scss'

interface ProfileInformationProps extends RouteComponentProps {
  setUser: typeof setUser
  addMessage: typeof addMessage
  user: UserState
}

// eslint-disable-next-line complexity
const ProfileInformation = (props: FormikProps<User> & ProfileInformationProps) => {
  const submitForm = (event: {preventDefault: () => void}) => {
    event.preventDefault()
    props.submitForm()
  }

  return (
    <div className={st.container}>
      <Descriptive>
        <div>
          <Title className={st.title}>Profile information </Title>
        </div>
        <FormikForm className={st.profileInfoForm}>
          <div className={cn(st.inputGroup)}>
            <Input
              id="firstName"
              value={props.values.firstName}
              onChange={props.handleChange}
              placeholder="Given name"
              inputContainerClassName={st.inputContainer}
              label="First name"
              error={(props.errors && props.touched.firstName && props.errors.firstName) || ''}
            />
            <Input
              id="lastName"
              value={props.values.lastName}
              onChange={props.handleChange}
              placeholder="Surname or family name"
              label="Last name"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.lastName && props.errors.lastName) || ''}
            />
          </div>
          <div className={cn(st.inputGroup)}>
            <div className={st.inputContainer}>
              <label className={st.emailLabel} htmlFor="email">
                Email
              </label>
              <span className={st.email}>{props.values.email}</span>
            </div>
            <Select
              id="position"
              value={props.values.position}
              onChange={props.handleChange}
              withFormik
              placeholder="Position"
              label="Position"
              selectContainerClassName={st.inputContainer}
              className={st.input}
              options={positions}
              error={(props.errors && props.touched.position && props.errors.position) || ''}
            />
          </div>
          <div className={cn(st.inputGroup)}>
            <Select
              id="country"
              withFormik
              value={props.values.country}
              onChange={props.handleChange}
              label="Country"
              placeholder="Country"
              selectContainerClassName={st.inputContainer}
              options={countries}
              error={(props.errors && props.touched.country && props.errors.country) || ''}
            />
            <Input
              id="city"
              value={props.values.city || ''}
              onChange={props.handleChange}
              label="City"
              placeholder="City"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.city && props.errors.city) || ''}
            />
          </div>
          <div className={cn(st.inputGroup)}>
            <Input
              id="institution"
              value={props.values.institution}
              onChange={props.handleChange}
              label="Institution"
              placeholder="Institution"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.institution && props.errors.institution) || ''}
            />
            <Input
              id="jobRole"
              value={props.values.jobRole || ''}
              onChange={props.handleChange}
              label="Role"
              placeholder="Role"
              inputContainerClassName={st.inputContainer}
              error={(props.errors && props.touched.jobRole && props.errors.jobRole) || ''}
            />
          </div>
          <div className={cn(st.inputGroup)}>
            <FieldArray
              name="speciality"
              render={(arrayHelpers) => (
                <Checkboxes
                  label="Speciality"
                  tips="Choose up to 3 options"
                  arrayHelpers={arrayHelpers}
                  selectedValues={props.values.speciality || []}
                  options={speciality}
                  error={(props.errors && props.touched.speciality && props.errors.speciality) || ''}
                />
              )}
            />
          </div>
          <div className={st.buttonsContainer}>
            <Button
              type="submit"
              onClick={submitForm}
              label={
                props.isSubmitting ? (
                  <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
                ) : (
                  'Save'
                )
              }
              className={st.buttons}
              disabled={props.isSubmitting}
            />
          </div>
        </FormikForm>
      </Descriptive>
    </div>
  )
}

const WithFormik = withFormik<ProfileInformationProps, User>({
  enableReinitialize: true,

  handleSubmit: async (values, {props, setSubmitting}) => {
    const {firstName, lastName, position, country, city, institution, jobRole, speciality} = values
    try {
      setSubmitting(true)
      const user = await MidtierService.updateUser({
        firstName,
        lastName,
        position,
        country,
        city,
        institution,
        jobRole,
        speciality,
      })
      props.setUser(user)
      props.addMessage({message: 'Profile has been updated', id: generateId(), type: 'success'})
      setSubmitting(false)
    } catch {
      setSubmitting(false)
    }
  },

  mapPropsToValues: ({user}) => {
    if (user) {
      return {
        id: user.id,
        uuid: user.uuid,
        roles: user.roles,
        institution: user.institution,
        country: user.country,
        position: user.position,
        firstName: user.firstName,
        lastName: user.lastName,
        registerDate: user.registerDate,
        email: user.email,
        city: user.city,
        jobRole: user.jobRole,
        speciality: user.speciality || [],
      }
    }
    return {
      id: '',
      uuid: '',
      roles: [],
      institution: '',
      country: '',
      position: '',
      firstName: '',
      lastName: '',
      registerDate: '',
      email: '',
      city: '',
      jobRole: '',
      speciality: [],
    }
  },

  validate: (values) =>
    validator.rules({
      firstName: validator.validate(values.firstName).name().required(),

      lastName: validator.validate(values.lastName).name().required(),

      position: validator.validate(values.position).required(),
      country: validator.validate(values.country).required(),
      city: validator.validate(values.city).name().required(),
      institution: validator.validate(values.institution).required(),
      jobRole: validator.validate(values.jobRole).required(),
      speciality: validator.validate(values.speciality).maxLength(3),
    }),
})(ProfileInformation)
const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  }
}
export default connect(mapStateToProps, {setUser, addMessage})(WithFormik)
