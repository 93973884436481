import React from 'react'
import Loader from 'react-loader-spinner'
import {Form as FormikForm, withFormik, FormikProps} from 'formik'
import {MidtierService} from '../../../services'
import {Button, LoginInput, Text, Title, ErrorMessage} from '../../index'
import {validator} from '../../../utils/validation/validator'
import text from './text.json'
import st from './PasswordForm.module.scss'

interface FormValues {
  email: string
}

interface PasswordFormProps {
  closeDropdown: () => void
  onClickBack: () => void
}

const PasswordForm = ({
  closeDropdown,
  submitForm,
  handleChange,
  values,
  errors,
  touched,
  status,
  isSubmitting,
  onClickBack,
}: FormikProps<FormValues> & PasswordFormProps) => {
  const formSubmitted = status && status.formSubmitted
  const submitFormikForm = () => {
    if (formSubmitted) {
      closeDropdown()
    } else {
      submitForm()
    }
  }
  const handleClickBack = () => {
    onClickBack()
  }
  return (
    <FormikForm className={st.form}>
      <Title type="h4" className={st.title}>
        <span className={st.titleText}>Forgot password?</span>{' '}
        <button className={st.backButton} onClick={handleClickBack}>
          <img src={`${process.env.PUBLIC_URL}${'icons/arrow-orange.svg'}`} alt="" className={st.arrowImage} />
        </button>
      </Title>
      <Text className={st.description}>{formSubmitted ? text.formSubmitted : text.submitForm}</Text>
      {formSubmitted ? null : (
        <p>
          <LoginInput id="email" placeholder="Email" value={values.email} onChange={handleChange} />
          {errors && errors.email && touched.email && <ErrorMessage className={st.emailError} error={errors.email} />}
        </p>
      )}
      <p>
        <Button
          type="button"
          className={st.submit}
          labelClassName={st.submitLabel}
          onClick={submitFormikForm}
          label={
            isSubmitting ? (
              <object>
                <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
              </object>
            ) : formSubmitted ? (
              text.ok
            ) : (
              text.send
            )
          }
        />
      </p>
    </FormikForm>
  )
}

/**
 * Settings for Formik HOC
 *
 */
const WithFormik = withFormik<PasswordFormProps, FormValues>({
  handleSubmit: async (values, {setStatus, setSubmitting}) => {
    setSubmitting(true)
    try {
      await MidtierService.forgotPassword({email: values.email})
      setStatus({formSubmitted: true})
      setSubmitting(false)
    } catch (e) {
      setSubmitting(false)
    }
  },

  mapPropsToValues: () => {
    return {
      email: '',
    }
  },

  validate: (values) =>
    validator.rules({
      email: validator.validate(values.email).required().email(),
    }),
})(PasswordForm)

export default WithFormik
