export const seotags = {
  'our-story': {
    descriptionContent: 'Our Story page',
    title: 'Our Story',
  },
  'our-work': {
    descriptionContent: 'Our Work page',
    title: 'Our Work',
  },
  tools: {
    descriptionContent: 'Tools page',
    title: 'Tools',
  },
  home: {
    descriptionContent: 'Allied Against Cancer',
    title: 'Allied Against Cancer',
  },
  coalition: {
    descriptionContent: 'African Cancer Coalition page ',
    title: 'African Cancer Coalition',
  },
  chemosafe: {
    descriptionContent: 'ChemoSafe application page ',
    title: 'ChemoSafe',
  },
  guidelines: {
    descriptionContent: 'Guidelines page ',
    title: 'Guidelines',
  },
  'pain-management': {
    descriptionContent: 'Pain Management page ',
    title: 'Pain Management',
  },
  'contact-us': {
    descriptionContent: 'Contact Us page ',
    title: 'Contact Us',
  },
  navigator: {
    descriptionContent: 'Navigator page ',
    title: 'Navigator',
  },
}
