import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  thead: {
    fontSize: '0.9rem',
    color: '#fff',
    fontWeight: 700,
    textTransform: 'uppercase',
    backgroundColor: '#eb7643',
  },
  iconButtonStyle: {
    border: 'none',
    background: 'none',
  },
  activeRelease: {
    backgroundColor: 'rgba(250,100,0,0.2)',
  },
  collapseCell: {
    paddingBottom: '5px',
    paddingTop: 0,
  },
})
