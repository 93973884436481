const chemoSafeDescription = ` ChemoSafe is a comprehensive approach to promote the safe handling and administration of chemotherapy and
          quality service provision to patients in Sub-Saharan Africa. The program encompasses the lifecycle of
          chemotherapy delivery, including logistics, compounding, administration (including diagnostics and side effect
          management), and disposal. It engages all healthcare and facility workers with the potential for interaction
          with chemotherapy, such as physicians, pharmacists, nurses, students and trainees, cleaners, and laundry
          workers.`

const chemoSafeWorkDescription = `The ChemoSafe mobile app enables individuals overseeing safety and quality at their hospital to examine
              each location in which hazardous drugs may be handled in their facility, including pharmacy,
              administration, and housekeeping. Upon completion of the assessment, the app provides recommendations for
              how to improve handling of hazardous drugs at every potential point of exposure, so to assist facilities
              in meeting international standards and ensure the safety of health workers.`
export {chemoSafeDescription, chemoSafeWorkDescription}
