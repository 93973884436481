import cn from 'classnames'
import React from 'react'

import st from './Challenges.module.scss'

interface ChallengesProps {
  children?: React.ReactNode
  className?: string
}

const Challenges = ({children, className}: ChallengesProps) => {
  return <div className={cn(st.challenges, className)}>{children}</div>
}

export default Challenges
