import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {useStyles} from '../../styles'
import {ChangeLog, ChemosafeDocument} from '../../../../../../../../../../../services/types'

interface ResourceTemplateProps {
  resource: any
  classes: any
  type?: string
}

interface NoResourceProps {
  title?: string
  classes: any
  deleted?: boolean
}
interface ResourceProps {
  changelog: ChangeLog
  documents: ChemosafeDocument[]
}

const NoResource = ({title, classes, deleted}: NoResourceProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {title && title} Resource
      </Typography>
      <Typography>{deleted ? 'Resource is deleted' : 'No resource'}</Typography>
    </Box>
  )
}

const ResourceTemplate = ({resource, classes, type}: ResourceTemplateProps) => {
  return (
    <>
      {resource && (
        <>
          <Box className={classes.bodySection}>
            <Typography className={classes.bodyTitle} variant="body2">
              {type && type} Resource Name
            </Typography>
            <Typography>{resource.name}</Typography>
          </Box>
          <Box className={classes.bodySection}>
            <Typography className={classes.bodyTitle} variant="body2">
              {type && type} Resource Url
            </Typography>
            <Typography>{resource.url ? resource.url : 'No resource url'}</Typography>
          </Box>
        </>
      )}
      {!resource && <NoResource classes={classes} title={type} />}
    </>
  )
}

const Resources = ({changelog, documents}: ResourceProps) => {
  const classes = useStyles()
  const resource = () => {
    return (
      changelog.newQuestion && documents.find((document) => document.id === changelog.newQuestion.resources.resourceId)
    )
  }
  const oldResource = () => {
    return (
      changelog.oldQuestion && documents.find((document) => document.id === changelog.oldQuestion.resources.resourceId)
    )
  }
  switch (changelog.type) {
    case 'ADD_RESOURCE_ID':
      return (
        <>
          <NoResource classes={classes} title="Old" />
          <ResourceTemplate type="New" classes={classes} resource={resource()} />
        </>
      )
    case 'EDIT_RESOURCE_ID':
      return (
        <>
          <ResourceTemplate type="New" classes={classes} resource={resource()} />
          <ResourceTemplate type="Old" classes={classes} resource={oldResource()} />
        </>
      )
    case 'DELETE_RESOURCE_ID':
      return (
        <>
          <ResourceTemplate type="Old" classes={classes} resource={oldResource()} />
          <NoResource classes={classes} title="New" deleted={true} />
        </>
      )
    case 'DELETE_QUESTION':
      return <ResourceTemplate classes={classes} resource={oldResource()} type="Old" />
    default:
      return <ResourceTemplate classes={classes} resource={resource()} />
  }
}
export default Resources
