import cn from 'classnames'
import React from 'react'
import {Link as ReactLink} from 'react-router-dom'
import {HashLink} from 'react-router-hash-link'
import st from './Link.module.scss'

interface Link {
  children?: React.ReactNode
  className?: string
  to: string
  target?: string
  external?: boolean
  rel?: string
  onClick?: (e: React.SyntheticEvent) => void
  section?: boolean
}

const Link = ({external, children, className, to, rel, target, onClick, section}: Link) => {
  return external ? (
    <a className={cn(st.link, className)} href={to} target={target} rel={rel}>
      {children}
    </a>
  ) : section ? (
    <HashLink to={to} className={cn(st.link, className)} target={target} rel={rel} onClick={onClick}>
      {children}
    </HashLink>
  ) : (
    <ReactLink className={cn(st.link, className)} to={to} target={target} rel={rel} onClick={onClick}>
      {children}
    </ReactLink>
  )
}

export default Link
