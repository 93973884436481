export default [
  {
    id: 1,
    firstName: 'Egide',
    lastName: 'Abahuje',
    position: 'Surgery',
    country: 'Rwanda',
    institution: 'University of Rwanda/University Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 2,
    firstName: 'Kunuz',
    lastName: 'Abdella',
    position: '',
    country: 'Ethiopia',
    institution: 'Federal Ministry of Health',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 3,
    firstName: 'Isaac',
    lastName: 'Adewole',
    position: 'Gynecologic Oncology',
    country: 'Nigeria',
    institution: 'University of Ibadan',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 4,
    firstName: 'Nii',
    lastName: 'Armah Adu-Aryee',
    position: 'Surgery',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 5,
    firstName: 'Inyas',
    lastName: 'Lawrence Akaro',
    position: 'Orthopedic Surgery, Pediatric Orthopedic Surgery',
    country: 'Tanzania',
    institution: 'Bugando Medical Centre',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 6,
    firstName: 'Patrick',
    lastName: 'Amsi',
    position: 'Pathology',
    country: 'Tanzania',
    institution: 'Kilimanjaro Christian Medical Center',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 7,
    firstName: 'Alfred',
    lastName: 'Ankrah',
    position: 'Radiology/Nuclear Medicine',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 8,
    firstName: 'Naa',
    lastName: 'Adorkor Aryeetey',
    position: 'Radiation Oncology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 9,
    firstName: 'David',
    lastName: 'Atuwo',
    position: '',
    country: 'Nigeria',
    institution: 'Federal Ministry of Health',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 10,
    firstName: 'Hannah',
    lastName: 'Ayettey Anie',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 11,
    firstName: 'Dinah',
    lastName: 'Baah-Odoom',
    position: '',
    country: 'Ghana',
    institution: 'Ministry of Health',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 12,
    firstName: 'Lewis',
    lastName: 'Banda',
    position: '',
    country: 'Zambia',
    institution: 'Cancer Diseases Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 13,
    firstName: 'Anne',
    lastName: 'Barasa',
    position: 'Pathology',
    country: 'Kenya',
    institution: 'University of Nairobi/Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 14,
    firstName: 'John',
    lastName: 'Butonzi',
    position: 'Internal Medicine',
    country: 'Rwanda',
    institution: 'Butaro Cancer Center of Excellence',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 15,
    firstName: 'Oladapo',
    lastName: 'Campbell',
    position: 'Radiation Oncology',
    country: 'Nigeria',
    institution: 'University of Ibadan/University College Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 16,
    firstName: 'Esther',
    lastName: 'Cege-Munyoro',
    position: 'Palliative Medicine/Pain Management',
    country: 'Kenya',
    institution: 'Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 17,
    firstName: 'Kondwani',
    lastName: 'Chalulu',
    position: 'Surgery',
    country: 'Malawi',
    institution: 'Queen Elizabeth Central Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 18,
    firstName: 'Olujimi',
    lastName: 'Coker',
    position: 'Surgery',
    country: 'Nigeria',
    institution: 'Lagoon Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 19,
    firstName: 'Mary-Ann',
    lastName: 'Dadzie',
    position: 'Radiation Oncology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 20,
    firstName: 'Teddy',
    lastName: 'Diiro',
    position: '',
    country: 'Uganda',
    institution: 'Victorville Clinic',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 21,
    firstName: 'Damas',
    lastName: 'Dukundane',
    position: 'Clinical Oncology, Internal Medicine',
    country: 'Rwanda',
    institution: 'Butaro Cancer Centre of Excellence',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 22,
    firstName: 'Haileyesus',
    lastName: 'Endalew',
    position: 'Hematology Oncology, Pediatric Oncology',
    country: 'Ethiopia',
    institution: 'Tikur Anbessa Specialized Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 23,
    firstName: 'Ntirenganya',
    lastName: 'Faustin',
    position: '',
    country: 'Rwanda',
    institution: 'University of Rwanda',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 24,
    firstName: 'Wondemagegnhu',
    lastName: 'Tigeneh Geberemedhin',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ethiopia',
    institution: 'Tikur Anbessa Specialized Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 25,
    firstName: 'Rahel',
    lastName: 'Ghebre',
    position: 'Gynecologic Oncology',
    country: 'United States',
    institution: "St. Paul's Hospital Millennium Medical College",
    publicProfile: {imgUrl: ''},
  },
  {
    id: 26,
    firstName: 'Jessie',
    lastName: "Githang'a",
    position: 'Hematology Oncology, Pathology',
    country: 'Kenya',
    institution: 'University of Nairobi/Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 27,
    firstName: 'Stanislas',
    lastName: 'Harakandi',
    position: 'Palliative Medicine/Pain Management, Anesthesiology',
    country: 'Burundi',
    institution: 'Kamenge University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 28,
    firstName: 'Diriba',
    lastName: 'Fufa Hordofa',
    position: 'Hematology Oncology',
    country: 'Ethiopia',
    institution: 'Jimma Medical Center',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 29,
    firstName: 'Joyce',
    lastName: 'Balagadde Kambugu',
    position: 'Pediatric Oncology',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 30,
    firstName: 'Alfred',
    lastName: 'Karagu',
    position: '',
    country: 'Kenya',
    institution: 'National Cancer Institute of Kenya',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 31,
    firstName: 'Israel',
    lastName: 'Kolawole',
    position: 'Palliative Medicine/Pain Management',
    country: 'Nigeria',
    institution: 'University of Ilorin Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 32,
    firstName: 'Ahmed',
    lastName: 'Abdi Komen',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Kenya',
    institution: 'Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 33,
    firstName: 'Thomas',
    lastName: 'Okpoti Konney',
    position: 'Gynecologic Oncology',
    country: 'Ghana',
    institution: 'Komfo Anokye Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 34,
    firstName: 'Rehema',
    lastName: 'Laiti',
    position: 'Pediatric Oncology',
    country: 'Tanzania',
    institution: 'Muhimbili National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 35,
    firstName: 'Beda',
    lastName: 'Mnamala Likonda',
    position: 'Radiation Oncology',
    country: 'Tanzania',
    institution: 'Bugando Medical Centre',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 36,
    firstName: 'Kennedy',
    lastName: 'Lishimpi',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Zambia',
    institution: 'Cancer Diseases Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 37,
    firstName: 'Emmanuel',
    lastName: 'Luyirika',
    position: 'Palliative Medicine/Pain Management',
    country: 'Uganda',
    institution: 'African Palliative Care Association',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 38,
    firstName: 'Tausi',
    lastName: 'Kassim Maftah',
    position: 'Radiology/Nuclear Medicine',
    country: 'Tanzania',
    institution: 'Ocean Road Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 39,
    firstName: 'Achille',
    lastName: 'Manirakiza',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Rwanda',
    institution: 'Rwanda Military Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 40,
    firstName: 'Astère',
    lastName: 'Manirakiza',
    position: 'Clinical Oncology',
    country: 'Burundi',
    institution: 'Kamenge University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 41,
    firstName: 'Sebastien',
    lastName: 'Manirakiza',
    position: 'Radiology/Nuclear Medicine',
    country: 'Burundi',
    institution: 'Kamenge University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 42,
    firstName: 'Leo',
    lastName: 'Masamba',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Malawi',
    institution: 'Queen Elizabeth Central Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 43,
    firstName: 'Edith',
    lastName: 'Matsikidze',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Zimbabwe',
    institution: 'Parirenyatwa Group of Hospitals',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 44,
    firstName: 'Patience',
    lastName: 'Mba',
    position: 'Gynecologic Oncology, Urology',
    country: 'Zimbabwe',
    institution: 'Parirenyatwa Group of Hospitals',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 45,
    firstName: 'Elson',
    lastName: 'Mberi',
    position: 'Hematology Oncology',
    country: 'Zimbabwe',
    institution: 'Parirenyatwa Group of Hospitals',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 46,
    firstName: 'Cosmas',
    lastName: 'Mbulwa',
    position: 'Pathology',
    country: 'Tanzania',
    institution: 'Bugando Medical Centre',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 47,
    firstName: 'James',
    lastName: 'Edward Mensah',
    position: 'Urology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 48,
    firstName: 'Alita',
    lastName: 'Mrema',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Tanzania',
    institution: 'Ocean Road Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 49,
    firstName: 'Susan',
    lastName: 'Citonje Msadabwe-Chikuni',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Zambia',
    institution: 'Cancer Diseases Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 50,
    firstName: 'Rajab',
    lastName: 'M. Mugabo',
    position: 'Surgery, Otolaryngology',
    country: 'Rwanda',
    institution: 'King Faisal Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 51,
    firstName: 'Pacifique',
    lastName: 'Mugenzi',
    position: '',
    country: 'Rwanda',
    institution: 'Rwanda Military Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 52,
    firstName: 'Diane',
    lastName: 'Mukasahaha',
    position: '',
    country: 'Rwanda',
    institution: 'Federal Ministry of Health',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 53,
    firstName: 'Sarah',
    lastName: 'Muma',
    position: 'Pediatric Oncology',
    country: 'Kenya',
    institution: 'AIC Kijabe Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 54,
    firstName: 'Asafu',
    lastName: 'Munema',
    position: 'Pathology',
    country: 'Tanzania',
    institution: 'Ocean Road Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 55,
    firstName: 'David',
    lastName: 'Mutiso Musyoka',
    position: 'Surgery, Otolaryngology',
    country: 'Kenya',
    institution: 'Aga Khan University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 56,
    firstName: 'Miriam',
    lastName: 'Mutebi',
    position: 'Surgery',
    country: 'Kenya',
    institution: 'Aga Khan University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 57,
    firstName: 'Nothando',
    lastName: 'Mutizira',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Zimbabwe',
    institution: 'Parirenyatwa Group of Hospitals',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 58,
    firstName: 'Sikudhani',
    lastName: 'Muya',
    position: 'Radiation Oncology',
    country: 'Tanzania',
    institution: 'Ocean Road Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 59,
    firstName: 'Nanzoke',
    lastName: 'Theobald Mvungi',
    position: 'Clinical Oncology',
    country: 'Tanzania',
    institution: 'Ocean Road Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 60,
    firstName: 'Maurice',
    lastName: 'Mwale',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Zambia',
    institution: 'Cancer Diseases Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 61,
    firstName: 'Ali',
    lastName: 'Mwanga',
    position: 'Surgery',
    country: 'Tanzania',
    institution: 'Muhimbili University of Health and Allied Sciences/Muhimbili National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 62,
    firstName: 'Sitna',
    lastName: 'Ali Mwanzi',
    position: 'Clinical Oncology',
    country: 'Kenya',
    institution: 'Aga Khan University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 63,
    firstName: 'Carolyn',
    lastName: 'Nakisige',
    position: 'Gynecologic Oncology',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 64,
    firstName: 'Jane',
    lastName: 'Namugga',
    position: 'Gynecologic Oncology',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 65,
    firstName: 'Xolani',
    lastName: 'Ndlovu',
    position: 'Radiology/Nuclear Medicine',
    country: 'Zimbabwe',
    institution: 'Mpilo Central Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 66,
    firstName: 'Jerry',
    lastName: 'Ndumbalo',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Tanzania',
    institution: 'Ocean Road Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 67,
    firstName: 'Nixon',
    lastName: 'Niyonzima',
    position: 'Clinical Oncology',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 68,
    firstName: 'Patrick',
    lastName: 'Noah',
    position: 'Surgery, Otolaryngology',
    country: 'Malawi',
    institution: 'University of Malawi',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 69,
    firstName: 'Nicaise',
    lastName: 'Nsabimana',
    position: 'Clinical Oncology',
    country: 'Rwanda',
    institution: 'Butaro Cancer Centre of Excellence',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 70,
    firstName: 'Emmanuella',
    lastName: 'Nwachukwu',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Nigeria',
    institution: 'National Hospital Abuja',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 71,
    firstName: 'Mary',
    lastName: 'Nyangasi',
    position: '',
    country: 'Kenya',
    institution: 'Ministry of Health',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 72,
    firstName: 'Siraji',
    lastName: 'Obayo',
    position: 'Clinical Oncology, Gastroenterology',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 73,
    firstName: 'Andrew',
    lastName: 'Odhiambo',
    position: 'Clinical Oncology',
    country: 'Kenya',
    institution: 'University of Nairobi/Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 74,
    firstName: 'Ibironke',
    lastName: 'Ogunbameru',
    position: 'Neurology/Neurosurgery',
    country: 'Nigeria',
    institution: 'Obafemi Awolowo University Teaching Hospital Complex',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 75,
    firstName: 'Ayokunle',
    lastName: 'Oke',
    position: 'Surgery',
    country: 'Nigeria',
    institution: 'Lagoon Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 76,
    firstName: 'Paul',
    lastName: 'Adekunle Onakoya',
    position: 'Surgery',
    country: 'Nigeria',
    institution: 'University College Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 77,
    firstName: 'Matilda',
    lastName: "Ong'ondi",
    position: 'Hematology Oncology',
    country: 'Kenya',
    institution: 'Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 78,
    firstName: 'Jackson',
    lastName: 'Orem',
    position: '',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 79,
    firstName: 'Lawrence',
    lastName: 'Osei-Tutu',
    position: 'Hematology Oncology, Pediatric Oncology',
    country: 'Ghana',
    institution: 'Komfo Anoyke Teaching Hospital/Presbyterian Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 80,
    firstName: 'Jeff',
    lastName: 'Otiti',
    position: 'Surgery, Otolaryngology',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 81,
    firstName: 'Warren',
    lastName: 'Phipps',
    position: 'Infectious Disease',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute/Hutchinson Center Cancer Alliance',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 82,
    firstName: 'George',
    lastName: 'Pupwe',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Zambia',
    institution: 'Cancer Diseases Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 83,
    firstName: 'Jamilla',
    lastName: 'Rajab',
    position: 'Hematology Oncology, Pathology',
    country: 'Kenya',
    institution: 'University of Nairobi/Kenyatta National Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 84,
    firstName: 'Molly',
    lastName: 'Rammipi',
    position: '',
    country: 'Botswana',
    institution: 'Ministry of Health',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 85,
    firstName: 'Aba',
    lastName: 'Anoa Scott',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 86,
    firstName: 'Furaha',
    lastName: 'Serventi',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Tanzania',
    institution: 'Kilimanjaro Christian Medical Center',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 87,
    firstName: 'Irene',
    lastName: 'Setlhako',
    position: '',
    country: 'Botswana',
    institution: 'Princess Marina Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 88,
    firstName: 'Uchechukwu',
    lastName: 'Shagaya',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Nigeria',
    institution: 'National Hospital Abuja',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 89,
    firstName: 'Youyou',
    lastName: 'Tankoy',
    position: 'Internal Medicine',
    country: 'Democratic Republic of the Congo',
    institution: 'Biamba Marie Mutombo Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 90,
    firstName: 'David',
    lastName: 'Turatsinze',
    position: 'Clinical Oncology',
    country: 'Rwanda',
    institution: 'University Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 91,
    firstName: 'Verna',
    lastName: 'Vanderpuye',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 92,
    firstName: 'Victoria',
    lastName: 'Walusansa',
    position: '',
    country: 'Uganda',
    institution: 'Uganda Cancer Institute',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 93,
    firstName: 'Khadija',
    lastName: 'Warfa',
    position: 'Gynecologic Oncology',
    country: 'Kenya',
    institution: 'Aga Khan University Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 94,
    firstName: 'Mathewos',
    lastName: 'Woldegeorgis',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ethiopia',
    institution: 'Tikur Anbessa Specialized Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 95,
    firstName: 'Edom',
    lastName: 'Seife Woldetsadik',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ethiopia',
    institution: 'Tikur Anbessa Specialized Hospital',
    publicProfile: {imgUrl: ''},
  },
  {
    id: 96,
    firstName: 'Joel',
    lastName: 'Yarney',
    position: 'Clinical Oncology, Radiation Oncology',
    country: 'Ghana',
    institution: 'Korle Bu Teaching Hospital',
    publicProfile: {imgUrl: ''},
  },
]
