import React, {useState} from 'react'
import {Descriptive} from '../../components'
import {LoginForm} from '../../components/forms/LoginForm'
import {PasswordForm} from '../../components/forms/PasswordForm'
import st from './Login.module.scss'

const Login = () => {
  const [isPasswordForm, setIsPasswordForm] = useState(false)
  const onChangePasswordClick = () => {
    setIsPasswordForm(true)
  }

  const close = () => {
    setIsPasswordForm(false)
  }

  const handleClickBack = () => {
    setIsPasswordForm(false)
  }

  return (
    <div className={st.notFound}>
      <Descriptive>
        <div className={st.content}>
          <div className={st.formWrapper}>
            {isPasswordForm ? (
              <PasswordForm closeDropdown={close} onClickBack={handleClickBack} />
            ) : (
              <LoginForm closeDropdown={close} onChangePasswordClick={onChangePasswordClick} />
            )}
          </div>
        </div>
      </Descriptive>
    </div>
  )
}

export default Login
