import * as regexp from '../../../../../utils/regexp'

export const checkValidationError = (name: string) => (value: string, otherValue?: string) => {
  switch (name) {
    case 'password':
      if (value === '') {
        return 'Password is required'
      } else if (value.length < 8) {
        return ' Password must be 8 characters at minimum'
      } else if (!regexp.passwordRegexp.test(value)) {
        return 'Password must be at least 8 characters, must contain letter, symbol and number'
      }
      break
    case 'newPassword':
      if (value === '') {
        return 'New Password is required'
      } else if (value.length < 8) {
        return ' New Password must be 8 characters at minimum'
      } else if (value !== otherValue) {
        return 'Confirm password must match with password'
      }
      break
    case 'email':
      if (value === '') {
        return 'Email is required'
      } else if (!regexp.emailRegexp.test(value)) {
        return 'Invalid email address format'
      }
      break
    default:
      break
  }
}
