const speciality = [
  {
    name: 'Medical/Clinical Oncology',
    value: 'medical',
  },
  {
    name: 'Radiotherapy/Radiation Oncology',
    value: 'radiotherapy',
  },
  {
    name: 'Surgery/Surgical Oncology',
    value: 'surgery',
  },
  {
    name: 'Cancer Management (no direct patient care responsibilities)',
    value: 'cancer_management',
  },
  {
    name: 'Gastroenterology',
    value: 'gastroenterology',
  },
  {
    name: 'Gynecologic Oncology',
    value: 'gynecologic_oncology',
  },
  {
    name: 'Hematology/Hematology Oncology',
    value: 'hematology',
  },
  {
    name: 'Internal Medicine',
    value: 'internal_medicine',
  },
  {
    name: 'Neurology/Neurosurgery/Neuro-oncology',
    value: 'neurology',
  },
  {
    name: 'Otolaryngology',
    value: 'otolaryngology',
  },
  {
    name: 'Palliative Medicine/Pain Management',
    value: 'palliative_medicine',
  },
  {
    name: 'Pathology',
    value: 'pathology',
  },
  {
    name: 'Radiology/Nuclear Medicine',
    value: 'radiology',
  },
  {
    name: 'Urology',
    value: 'urology',
  },
  {
    name: 'Other ',
    value: 'other',
  },
]

export default speciality
