import React, {ReactNode, useState, useRef, createContext, useContext} from 'react'
import ConfirmationDialog, {ConfirmationOptions} from '../components/ConfirmationDialog'

const ConfirmationServiceContext = createContext<(options: ConfirmationOptions) => Promise<void>>(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

interface ConfirmationServiceProviderProps {
  children: ReactNode[] | ReactNode
}

export const ConfirmationServiceProvider = ({children}: ConfirmationServiceProviderProps) => {
  const [confirmationState, setConfirmationState] = useState<ConfirmationOptions | null>(null)

  const awaitingPromiseRef = useRef<{
    resolve: () => void
    reject: () => void
  }>()

  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options)
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = {resolve, reject}
    })
  }

  const handleClose = () => {
    if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation} children={children} />

      <ConfirmationDialog
        open={!!confirmationState}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  )
}
