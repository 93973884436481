import cn from 'classnames'
import React, {ReactNode} from 'react'

import st from './Title.module.scss'

interface TitleProps {
  children?: ReactNode
  className?: string
  style?: React.CSSProperties
  type?: string
  alt?: boolean
  subtitle?: boolean
  center?: boolean
  color?: string
  secondary?: boolean
  id?: string
}

const Title = ({children, className, style, color, type, alt, subtitle, center, secondary, id}: TitleProps) => {
  const classes = cn(
    st.title,
    {[st.alt]: alt, [st.subtitle]: subtitle, [st.center]: center, [st.secondary]: secondary},
    className,
  )
  return type === 'h1' ? (
    <h1 className={classes} style={{...style, color: `${color}`}} id={id}>
      {children}
    </h1>
  ) : type === 'h2' ? (
    <h2 className={classes} style={{...style, color: `${color}`}} id={id}>
      {children}
    </h2>
  ) : type === 'h3' ? (
    <h3 className={classes} style={{...style, color: `${color}`}} id={id}>
      {children}
    </h3>
  ) : type === 'h4' ? (
    <h4 className={classes} style={{...style, color: `${color}`}} id={id}>
      {children}
    </h4>
  ) : type === 'h5' ? (
    <h5 className={classes} style={{...style, color: `${color}`}} id={id}>
      {children}
    </h5>
  ) : (
    <h1 className={classes} style={{...style, color: `${color}`}} id={id}>
      {children}
    </h1>
  )
}

Title.defaultProps = {
  type: 'h1',
  alt: false,
  center: false,
}

export default Title
