export const SET_ACTIVE_MODAL = 'SET_ACTIVE_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export enum MODAL_NAMES {
  LOGIN = 'login',
  LOGOFF = 'logoff',
  NAVIGATOR = 'navigator',
}

export interface Modals {
  activeModal: MODAL_NAMES | undefined
}

export type ModalsState = Modals

interface SetActiveModal {
  type: typeof SET_ACTIVE_MODAL
  payload: MODAL_NAMES
}

interface CloseModal {
  type: typeof CLOSE_MODAL
}

export type ModalsActionTypes = SetActiveModal | CloseModal
