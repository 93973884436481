import React, {useRef, useState} from 'react'
import cn from 'classnames'
import {useHistory, useLocation} from 'react-router-dom'
import {bindActionCreators, Dispatch} from 'redux'
import {connect} from 'react-redux'
import {Button, Descriptive, Link, OpenModalButton, PagePreview, Text, Title} from '../../components'

import {MODAL_NAMES} from '../../redux/modals/types'
import {AppActions} from '../../redux'
import {closeModal, setActiveModal} from '../../redux/modals/actions'
import st from './Tools.module.scss'
import {PlatformVariantTile, Separator} from './components/'
import {chemosafeBlock, navigatorBlock, promoText} from './content'
enum Platforms {
  CHEMOSAFE = 'chemosafe',
  NAVIGATOR = 'navigator',
}
const NavigatorBlock = () => (
  <div className={cn(st.chosenPlatformBlock, st.navigationBlock)}>
    <div className={st.descriptionWrapper}>
      <div className={st.descriptionInnerWrapper}>
        <Title type="h3" className={st.title}>
          Support for cancer-treating physicians
        </Title>
        <Text>{navigatorBlock.supportCancer.partOne}</Text>
        <Text>{navigatorBlock.supportCancer.partTwo}</Text>
        <Title type="h3" className={st.title}>
          Benefits
        </Title>
        <Text>
          <ul className={st.list}>
            {navigatorBlock.benefitsList.map((listElement, id) => (
              <li key={id}>{listElement}</li>
            ))}
          </ul>
        </Text>
      </div>
    </div>
    <div className={st.presentationWrapper}>
      <div className={st.imageBlockRight}>
        <div className={st.deviceImageRight} />
      </div>
      <OpenModalButton
        className={st.promoLink}
        text={
          <span>
            Start using Cancer <br />
            Guidelines Navigator today!
          </span>
        }
        modalName={MODAL_NAMES.NAVIGATOR}
      />
      <Text className={st.smallText}>
        Access is free with registration for cancer-treating clinicians in Sub-Saharan Africa.{' '}
      </Text>
    </div>
  </div>
)
const ChemoSafeBlock = () => (
  <div className={cn(st.chosenPlatformBlock, st.chemoSafeBlock)}>
    <div className={st.descriptionWrapper}>
      <div className={st.descriptionInnerWrapper}>
        <Title type="h3" className={st.title}>
          Improve chemotherapy safety
        </Title>
        <Text>{chemosafeBlock.improveChemotherapySafetyText.partOne}</Text>
        <Text>{chemosafeBlock.improveChemotherapySafetyText.partTwo}</Text>
        <Title type="h3" className={st.title}>
          Benefits
        </Title>
        <Text>
          <ul className={st.list}>
            {chemosafeBlock.benefitsList.map((listElement, id) => (
              <li key={id}>{listElement}</li>
            ))}
          </ul>
        </Text>
        <div className={st.storeLinksBlock}>
          <Link
            to="https://apps.apple.com/us/app/chemosafe/id1454417529"
            external
            className={st.storeLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={st.storeImage}
              src={`${process.env.PUBLIC_URL}/images/safety_and_administration/appstore.png`}
              alt=""
            />
          </Link>
          <Link
            to="https://play.google.com/store/apps/details?id=com.ibm.chq.citizenship.chemosafe&hl=en_US"
            external
            target="_blank"
            className={st.storeLink}
            rel="noopener noreferrer"
          >
            <img
              className={st.storeImage}
              src={`${process.env.PUBLIC_URL}/images/safety_and_administration/googleplay.png`}
              alt=""
            />
          </Link>
        </div>
      </div>
    </div>
    <div className={st.presentationWrapper}>
      <div className={st.imageBlockLeft}>
        <div className={st.deviceImageLeft} />
      </div>
      <Title className={st.promo} type="h3">
        Download ChemoSafe today!
        <br />
        Available for free for both Android and iOS devices.
      </Title>
    </div>
  </div>
)
const Tools = ({setActiveModal}: AppReduxInjectedProps) => {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const platform = query.get('tool')
  const isOpenNavigatorModal = query.get('isOpenNavigatorModal')
  const [currentPlatform, setCurrentPlatform] = useState(platform ? platform : 'chemosafe')
  const currentPlatformBlock = useRef<HTMLDivElement>(null)
  const scrollToPlatform = () => {
    setTimeout(
      () =>
        window.scrollTo({
          top: currentPlatformBlock.current?.offsetTop,
          left: 0,
          behavior: 'smooth',
        }),
      0,
    )
  }

  const handleClick = (name: Platforms) => () => {
    setCurrentPlatform(name)
    history.push(`/tools?tool=${name}`)
    scrollToPlatform()
  }
  platform && scrollToPlatform()
  isOpenNavigatorModal && setActiveModal(MODAL_NAMES.NAVIGATOR)
  return (
    <div className={st.ourWork}>
      <PagePreview
        title="Tools"
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/tools.jpg`}
        className={st.headerImg}
      />
      <Descriptive>
        <Text>{promoText}</Text>
      </Descriptive>
      <Descriptive className={st.platformerBlock}>
        <div className={st.choosePlatformBlock}>
          <div className={st.platformVariantTileWrapper}>
            <PlatformVariantTile
              onClick={handleClick(Platforms.CHEMOSAFE)}
              imageUrl={`${process.env.PUBLIC_URL}/images/tools/chemosafe.png`}
              title="ChemoSafe"
              description="Build a quality improvement plan for safe handling of chemotherapy"
              selected={currentPlatform === Platforms.CHEMOSAFE}
            />
          </div>
          <Separator />
          <div className={st.platformVariantTileWrapper}>
            <PlatformVariantTile
              onClick={handleClick(Platforms.NAVIGATOR)}
              imageUrl={`${process.env.PUBLIC_URL}/images/tools/guidlines_navigator.png`}
              title="Cancer Guidelines Navigator"
              description="Treatment planning support following the NCCN Harmonized Guidelines™ for Sub-Saharan Africa"
              selected={currentPlatform === Platforms.NAVIGATOR}
            />
          </div>
        </div>
        <div className={st.currentPlatform} ref={currentPlatformBlock}>
          {currentPlatform === Platforms.CHEMOSAFE && <ChemoSafeBlock />}
          {currentPlatform === Platforms.NAVIGATOR && <NavigatorBlock />}
        </div>
      </Descriptive>
      {currentPlatform && (
        <div className={st.wantToLearnMore}>
          <Descriptive className={st.wantLearnMoreDescriptiveWrapper}>
            <div className={st.wantToLearnMoreBlockInnerWrapper}>
              <Title className={st.wantToLearnMoreText}>Want to learn more?</Title>
              <div className={st.buttonsBlock}>
                <Button
                  className={st.learnMoreButton}
                  label="User Support"
                  to={currentPlatform === 'chemosafe' ? '/chemosafe' : '/guidelines'}
                />
                <Button
                  className={st.learnMoreButton}
                  external
                  target="_blank"
                  to={
                    currentPlatform === 'chemosafe'
                      ? 'https://www.youtube.com/watch?v=FIBi0c8ypy8&feature=youtu.be'
                      : 'https://www.youtube.com/watch?v=cssjCXCUUuw'
                  }
                  label="Watch the demo video"
                />
              </div>
            </div>
          </Descriptive>
        </div>
      )}
    </div>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AppActions>) =>
  bindActionCreators(
    {
      setActiveModal,
      closeModal,
    },
    dispatch,
  )

type AppReduxInjectedProps = ReturnType<typeof mapDispatchToProps>
export default connect(null, mapDispatchToProps)(Tools)
