import {makeStyles} from '@material-ui/core'

interface StyleProps {
  isDragging?: boolean
  hideDragEmmiter?: boolean
}

export const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  'question-panel-root': {
    boxShadow: 'none',
  },
  editMode: {
    border: '1px solid rgb(235, 115, 70) !important',
  },
  displayMode: {
    border: '1px solid #D2D2D2',
    borderTop: 'none',
  },
  'question-panel-summary-expanded': {
    'border-bottom': '1px solid #D2D2D2 !important',
  },
  'question-panel-summary-content': {
    border: 'none !important',
  },
  'question-panel-details': {
    flexDirection: 'column',
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    '& input.MuiInput-underline:before': {
      borderBottom: 'none !important',
    },
  },
  panel: {
    padding: 0,
    border: '1px solid #D2D2D2',
    '&.activeDnd': {
      border: '1px solid #eb7643',
    },
  },
  dndPanel: {
    maxHeight: '65px',
    margin: 0,
  },
  'toggle-icon': {
    border: 'none !important',
    margin: '0 !important',
  },
  'question-number-wrapper': ({hideDragEmmiter}: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px 20px',
    'min-width': '65px',
    borderStyle: 'solid',
    borderColor: '#D2D2D2',
    'border-right-width': hideDragEmmiter ? '1px' : 'none',
    '@media only screen and (max-width: 768px)': {padding: '5px', 'min-width': '40px', fontSize:'0.8rem'}
  }),
  'question-number': {
    fontSize: theme.typography.pxToRem(15),
  },
  'drag-emmiter-wrapper': ({isDragging}: StyleProps) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: isDragging ? '#eb7643' : '#E4E4E4',
    '@media only screen and (max-width: 768px)': {padding: '5px', fontSize:'0.8rem'}
  }),
  'question-info-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0 8px 8px 0',
    justifyContent: 'center',
    width: '100%',
    '@media only screen and (max-width: 768px)': {padding: '5px', fontSize:'0.8rem'}
  },
  'question-id': {
    fontSize: theme.typography.pxToRem(11),
  },
  'question-meta-info-container': {
    paddingLeft: '20px',
    '@media only screen and (max-width: 768px)': {paddingLeft: '5px'}
  },
  'question-text-input': {
    margin: '5px',
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(10),
  },
  'question-text-st': {
    justifyContent: 'center',
    fontSize: theme.typography.pxToRem(10),
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: 'none !important',
        },
        paddingLeft: '0px',
      },
    },
  },
  'question-text': {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.text.secondary,
    paddingLeft: theme.typography.pxToRem(20),
    paddingTop: theme.typography.pxToRem(5),
    overflow: 'hidden',
    'text-overflow': 'ellipsis',
    display: '-webkit-box',
    'line-height': theme.typography.pxToRem(16) /* fallback */,
    'max-height': theme.typography.pxToRem(32 + 5) /* fallback */,
    '-webkit-line-clamp': 2 /* number of lines to show */,
    '-webkit-box-orient': 'vertical',
  },
  'switcher-label': {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    padding: theme.typography.pxToRem(10),
  },
  'edit-icon': {
    marginLeft: '5px',
    '@media only screen and (max-width: 768px)': {fontSize: '0.8rem'}
  },
  'buttons-box': {
    display: 'flex',
    borderTop: '1px solid rgb(235, 115, 70) !important',
  },
  'save-changes-button': {
    marginRight: '0px !important',
    height: '50px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  'cancel-button': {
    height: '50px',
    backgroundColor: 'transparent',
    border: '0.5px solid #61443ed1',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  'cancel-button-label': {
    color: '#000',
  },
  'select-text-field': {
    '& .MuiOutlinedInput-input': {
      fontSize: theme.typography.pxToRem(14),
    },
  },
}))
