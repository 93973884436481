import React, {useState} from 'react'

import {DropdownContainer} from '../../../index'
import {PasswordForm} from '../../../forms/PasswordForm'
import {LoginForm} from '../../../forms/LoginForm'
import st from './LoginFormDropdown.module.scss'
interface Props {
  closeDropdown: () => void
}

const LoginFormDropdown = ({closeDropdown}: Props) => {
  const [isPasswordForm, setIsPasswordForm] = useState(false)
  const onChangePasswordClick = () => {
    setIsPasswordForm(true)
  }

  const close = () => {
    setIsPasswordForm(false)
  }

  const handleClickBack = () => {
    setIsPasswordForm(false)
  }

  return (
    <DropdownContainer closeDropdown={closeDropdown} className={st.dropDownContainerStyles}>
      {isPasswordForm ? (
        <PasswordForm closeDropdown={close} onClickBack={handleClickBack} />
      ) : (
        <LoginForm closeDropdown={close} onChangePasswordClick={onChangePasswordClick} />
      )}
    </DropdownContainer>
  )
}

export default LoginFormDropdown
