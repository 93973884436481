import React from 'react'

import {Button, Descriptive, PagePreview, Text, Title} from '../../components'
import st from './Programs.module.scss'
import {
  handlingOfChemotherapyText,
  ourWorkDescriptionText,
  painManagementText,
  treatmentGuidlinesText,
  cancerAccessPartnershipText,
} from './constants'
import {Card, Cards} from './components'

const Programs = () => {
  return (
    <div className={st.ourWork}>
      <PagePreview
        title="Programs"
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/our_work.jpg`}
        className={st.headerImg}
      />
      <Descriptive>
        <Text>{ourWorkDescriptionText}</Text>
      </Descriptive>
      <Descriptive>
        <Cards>
          <Card
            description={
              <div>
                <Title type="h4">NCCN Harmonized Guidelines™ for Sub-Saharan Africa</Title>
                <Text>{treatmentGuidlinesText}</Text>
                <Button label="Learn more" to="/guidelines" />
              </div>
            }
            imageUrl={`${process.env.PUBLIC_URL}./images/our_work/treatment_guidlines.jpeg`}
          />
          <Card
            description={
              <div>
                <Title type="h4">Safe handling of chemotherapy</Title>
                <Text>{handlingOfChemotherapyText}</Text>
                <Button label="Learn more" to="/chemosafe" />
              </div>
            }
            imageUrl={`${process.env.PUBLIC_URL}./images/our_work/safety_and_administration.jpeg`}
          />
          <Card
            description={
              <div>
                <Title type="h4">Cancer Access Partnership</Title>
                <Text>{cancerAccessPartnershipText}</Text>
                <Button label="Learn more" to="/access-partnership" />
              </div>
            }
            imageUrl={`${process.env.PUBLIC_URL}./images/our_work/access_partnership.jpg`}
          />
          <Card
            description={
              <div>
                <Title type="h4">Pain management</Title>
                <Text>{painManagementText}</Text>
                <Button label="Learn more" to="/pain-management" />
              </div>
            }
            imageUrl={`${process.env.PUBLIC_URL}./images/our_work/pain_management.jpeg`}
          />
        </Cards>
      </Descriptive>
    </div>
  )
}
export default Programs
