import {combineReducers, createStore} from 'redux'
import {devToolsEnhancer} from 'redux-devtools-extension'
import {persistReducer, persistStore} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {userReducer} from './user/reducer'
import {modalsReducer} from './modals/reducer'
import {alertsReducer} from './alerts/reducer'
import {ModalsActionTypes} from './modals/types'
import {AlertsActionTypes} from './alerts/types'
import {UserActionTypes} from './user/types'
import {adminReducer} from './admin/reducer'
import {AdminActionTypes} from './admin/types'

const rootReducer = combineReducers({
  user: userReducer,
  modals: modalsReducer,
  alerts: alertsReducer,
  admin: adminReducer,
})

export type AppState = ReturnType<typeof rootReducer>

export type AppActions = UserActionTypes | ModalsActionTypes | AlertsActionTypes | AdminActionTypes

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['alerts', 'admin', 'modals'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(
  persistedReducer,
  /* preloadedState, */
  devToolsEnhancer({}),
)

const persistor = persistStore(store)

export {store, persistor}
