import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {useStyles} from '../../styles'
import {ChangeLog} from '../../../../../../../../../../../services/types'

interface RequirementTemplateProps {
  classes: any
  text: string
  type?: string
}

interface RequirementProps {
  changelog: ChangeLog
}

const RequirementTemplate = ({classes, text, type}: RequirementTemplateProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {type && type} Resource Requirement
      </Typography>
      <Typography>{text ? text : 'No Resource Requirement'}</Typography>
    </Box>
  )
}

const Requirement = ({changelog}: RequirementProps) => {
  const classes = useStyles()

  switch (changelog.type) {
    case 'EDIT_RESOURCE_REQUIREMENT':
      return (
        <>
          <RequirementTemplate
            classes={classes}
            type="Old"
            text={changelog.oldQuestion.resources.resourceRequirement}
          />
          <RequirementTemplate
            classes={classes}
            type="New"
            text={changelog.newQuestion.resources.resourceRequirement}
          />
        </>
      )
    case 'DELETE_QUESTION':
      return (
        <RequirementTemplate classes={classes} text={changelog.oldQuestion.resources.resourceRequirement} type="Old" />
      )
    default:
      return <RequirementTemplate classes={classes} text={changelog.newQuestion.resources.resourceRequirement} />
  }
}
export default Requirement
