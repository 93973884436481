import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  textInput: {
    width: '100%',
    '& .MuiInput-input:not(.question-main-text-input)': {
      fontFamily: 'Quicksand',
      color: 'black',
      display: 'block',
      border: '1px solid #FFF9F6',
      backgroundColor: '#FFF9F6',
      padding: '7px 19px',
      fontSize: theme.typography.pxToRem(14),
      '&:focus': {
        border: '1px solid #bdbdbd',
      },
    },
    '& .MuiInputBase-multiline': {
      padding: '0 !important',
    },
  },
  'question-main-text-input': {
    width: '100%',
    '& .MuiInput-input': {
      fontFamily: 'Quicksand',
      color: 'black',
      display: 'block',
      border: '1px solid #FFF9F6',
      backgroundColor: '#FFF9F6',
      padding: '7px 5px',
      marginLeft: '14px',
      fontSize: theme.typography.pxToRem(14),
      '&:focus': {
        border: '1px solid #bdbdbd',
      },
    },
    '& .MuiInputBase-multiline': {
      padding: '0 !important',
    },
  },
}))
