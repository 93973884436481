import React from 'react'

import {Descriptive, PagePreview, Text, Title} from '../../components'
import st from './Coalition.module.scss'
import {CoalitionFinder} from './components'

const Coalition = () => {
  return (
    <div className={st.coalition}>
      <PagePreview
        title="African Cancer Coalition "
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/coalition.jpg`}
      />
      <Descriptive>
        <Text>
          The African Cancer Coalition is a growing consortium of oncology experts in Africa. Initially, this group came
          together to work with The American Cancer Society (ACS), National Comprehensive Cancer Network (NCCN®), and
          the Clinton Health Access Initiative (CHAI) to develop standard cancer treatment guidelines for use in
          Sub-Saharan Africa.
        </Text>
        <Title type="h4" color="black">
          Find a member of the African Cancer Coalition:
        </Title>
        <CoalitionFinder />
      </Descriptive>
    </div>
  )
}

export default Coalition
