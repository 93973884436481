import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles({
  reactTable: {
    '&.ReactTable .rt-table .rt-thead .rt-tr ': {
      backgroundColor: '#eb7643',
      color: '#fff',
      fontWeight: 700,
      height: '50px',
    },
    '&.ReactTable .rt-table .rt-thead .rt-th ': {
      display: 'flex',
      flexWrap: 'wrap',
      alignContent: 'center',
      justifyContent: 'center',
    },
    '&.ReactTable .rt-table .rt-tbody .rt-tr ': {
      paddingLeft: '10px',
      paddingTop: '10px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    fontFamily: 'Quicksand',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  iconButtonStyle: {
    border: 'none',
    background: 'none',
  },
  searchAndAddButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    margin: '0 0 15px 0',
  },
  addItem: {
    height: '56px',
    width: '150px',
    borderWidth: '1px',
    textTransform: 'none',
    fontWeight: 600,
    color: '#eb7643',
    borderColor: '#eb7643',
  },
})
