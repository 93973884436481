import React, {useState} from 'react'
import Loader from 'react-loader-spinner'
import {bindActionCreators, Dispatch} from 'redux'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {addMessage} from '../../../../../redux/alerts/actions'
import {Button, Text} from '../../../../../components'
import {delUser} from '../../../../../redux/user/actions'
import {delTokens} from '../../../../../utils/tokens'
import {MidtierService} from '../../../../../services'
import {AppActions} from '../../../../../redux'
import generateId from '../../../../../utils/generateId'
import st from './SmallForms.module.scss'

const DeleteAccount: React.FunctionComponent<AppReduxInjectedProps> = ({delUser, addMessage}) => {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const handleUserDelete = async () => {
    try {
      setIsLoading(true)
      await MidtierService.deleteUserMyself()
      delUser()
      await delTokens()
      addMessage({message: 'User successfully deleted', id: generateId(), type: 'success'})
      setIsLoading(false)
      history.push('/')
    } catch {
      addMessage({message: 'An error occurred.', id: generateId(), type: 'error'})
    }
  }
  return (
    <>
      <Text className={st.text}>Are you sure you want to delete your account? we’re gonna miss you :(</Text>

      <div className={st.buttons}>
        <div className={st.buttonBlock}>
          <Button
            type="submit"
            onClick={handleUserDelete}
            label={
              isLoading ? (
                <Loader className={st.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
              ) : (
                'Delete'
              )
            }
            className={st.buttons}
            disabled={isLoading}
          />
          <Button label="Cancel" secondary to="/profile/settings" />
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) => bindActionCreators({addMessage, delUser}, dispatch)
type AppReduxInjectedProps = ReturnType<typeof mapDispatchToProps>
export default connect(null, mapDispatchToProps)(DeleteAccount)
