import React, {useState} from 'react'

import cn from 'classnames'
import Collapse from '@kunukn/react-collapse'
import {Text} from '../../../../components'
import st from './CollapsePanel.module.scss'
export interface CollapsePanelProps {
  question: string
  children: React.ReactNode
}

const CollapsePanel: React.FC<CollapsePanelProps> = ({question, children}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const togglePanelHandler = () => {
    setIsOpen((prevState) => !prevState)
  }

  return (
    <div className={st.collapsePanel}>
      <button className={cn(st.question, isOpen && st.questionOpened)} onClick={togglePanelHandler}>
        <Text className={cn(st.questionText, isOpen && st.questionTextOpened)}>{question}</Text>
      </button>
      <Collapse isOpen={isOpen} transition="height 200ms cubic-bezier(.4, 0, .2, 1)">
        {children}
      </Collapse>
    </div>
  )
}

export default CollapsePanel
