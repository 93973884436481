const actions = [
  {
    id: 'download',
    title: 'Download the NCCN Harmonized Guidelines™ for Sub-Saharan Africa',
    imageUrl: `${process.env.PUBLIC_URL}./icons/download.png`,
    url: 'http://www.nccn.org/harmonized',
    modalAction: false,
  },
  {
    id: 'release',
    title: 'Read a press release about the collaboration',
    imageUrl: `${process.env.PUBLIC_URL}./icons/comment.png`,
    url: 'https://www.nccn.org/about/news/newsinfo.aspx?NewsID=1665',
    modalAction: false,
  },
  {
    id: 'demo',
    title: 'Watch the demo of the Cancer Guidelines Navigator',
    imageUrl: `${process.env.PUBLIC_URL}./icons/play.png`,
    url: 'https://www.youtube.com/watch?v=cssjCXCUUuw',
    modalAction: false,
  },
  {
    id: 'navigator',
    title: '',
    imageUrl: `${process.env.PUBLIC_URL}./icons/computer.png`,
    url: 'https://che-gateway.watsonplatform.net/watson-oncology/cancerguidelinesnavigator/wdao',
    modalAction: true,
  },
]

export {actions}
