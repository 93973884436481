import React, {FormEvent} from 'react'
import {Box, Button, Typography, FormControl, TextField} from '@material-ui/core'
import {useCSTContext} from '../../../context/chemosafeToolContext'
import {useStyles} from './styles'

const SearchQuestionsForm = () => {
  const classes = useStyles()
  const {
    actions: {changeFilters},
  }: any = useCSTContext()

  const handleSearchClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const searchInputEl = event.currentTarget.elements.namedItem('search') as HTMLInputElement
    const searchValue = searchInputEl.value
    changeFilters({questionName: searchValue})
  }

  return (
    <Box className={classes.searchBox}>
      <form onSubmit={handleSearchClick}>
        <Typography variant="body1" className={classes.searchText}>
          Search Questions
        </Typography>
        <Box style={{padding: 0}}>
          <FormControl className={classes.searchInputForm}>
            <TextField
              id="search"
              type="search"
              variant="outlined"
              className={classes.searchInput}
              label="Text or Keyword"
            />
          </FormControl>
          <Button type="submit" className={classes.searchButton}>
            SEARCH
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default SearchQuestionsForm
