import React from 'react'
import Collapse from '@kunukn/react-collapse'
import cn from 'classnames'
import st from './Collapsed.module.scss'
interface CollapsedProps {
  children: React.ReactNode
  triggerComponent: React.ReactNode
  triggerClassName?: string
  triggerOpenedClassName?: string
  contentOuterClassName?: string
  isOpen?: boolean
  onClick?: () => void
}

const Collapsed = ({
  children,
  triggerComponent,
  isOpen,
  onClick,
  triggerClassName,
  triggerOpenedClassName,
  contentOuterClassName,
}: CollapsedProps) => {
  return (
    <div className={st.collapseContainer}>
      <div
        className={cn(
          triggerClassName || st.navLinkTrigger,
          (isOpen && triggerOpenedClassName) || st.navLinkTriggerOpened,
        )}
        onClick={(e) => {
          onClick && onClick()
        }}
      >
        {triggerComponent}
      </div>
      <Collapse isOpen={isOpen} transition="height 200ms cubic-bezier(.4, 0, .2, 1)">
        <div className={contentOuterClassName || st.collapsibleContentOuter}>{children}</div>
      </Collapse>
    </div>
  )
}

export default Collapsed
