import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {useStyles} from '../../styles'
import {ChangeLog} from '../../../../../../../../../../../services/types'

interface NoConditionProps {
  title?: string
  classes: any
  deleted?: boolean
}
interface ConditionValueProps {
  value: {
    optionId: string
    questionId: string
  }
}
interface ConditionTemplateProps {
  type?: string
  classes: any
  condition: ConditionValueProps | undefined
}

interface ConditionProps {
  changelog: ChangeLog
}

const NoCondition = ({title, classes, deleted}: NoConditionProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {title && title} Condition
      </Typography>
      <Typography>{deleted ? 'Condition is deleted' : 'No condition'}</Typography>
    </Box>
  )
}

const ConditionTemplate = ({condition, classes, type}: ConditionTemplateProps) => {
  return (
    <>
      {condition && (
        <>
          <Box className={classes.bodySection}>
            <Typography className={classes.bodyTitle} variant="body2">
              {type && type} Parent Question Id
            </Typography>
            <Typography>{condition.value.questionId}</Typography>
          </Box>
          <Box className={classes.bodySection}>
            <Typography className={classes.bodyTitle} variant="body2">
              {type && type} Parent&apos;s required answer condition
            </Typography>
            <Typography>{condition.value.optionId}</Typography>
          </Box>
        </>
      )}
      {!condition && <NoCondition classes={classes} title={type} />}
    </>
  )
}

const Condition = ({changelog}: ConditionProps) => {
  const classes = useStyles()

  switch (changelog.type) {
    case 'ADD_CONDITION':
      return (
        <>
          <NoCondition classes={classes} title="Old" />
          <ConditionTemplate type="New" classes={classes} condition={changelog.newQuestion.conditionalPredicate} />
        </>
      )
    case 'EDIT_CONDITION':
      return (
        <>
          <ConditionTemplate type="New" classes={classes} condition={changelog.newQuestion.conditionalPredicate} />
          <ConditionTemplate type="Old" classes={classes} condition={changelog.oldQuestion.conditionalPredicate} />
        </>
      )
    case 'DELETE_CONDITION':
      return (
        <>
          <ConditionTemplate type="Old" classes={classes} condition={changelog.oldQuestion.conditionalPredicate} />
          <NoCondition classes={classes} title="New" deleted={true} />
        </>
      )
    case 'DELETE_QUESTION':
      return <ConditionTemplate classes={classes} condition={changelog.oldQuestion.conditionalPredicate} type="Old" />
    default:
      return null
  }
}
export default Condition
