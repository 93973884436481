import ConfirmUser from './ConfirmUser'
import ChangePassword from './ChangePassword'
import ChangeEmail from './ChangeEmail'
import ApprovedDialog from './ApprovedDialog'
import DeleteAccount from './DeleteAccount'

export default {
  ConfirmUser,
  ChangeEmail,
  ChangePassword,
  DeleteAccount,
  ApprovedDialog,
}
