import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {BrowserRouter as Router, Route, Switch, useLocation} from 'react-router-dom'
import {bindActionCreators, Dispatch} from 'redux'
import st from './App.module.scss'
import {CookieDisclaimer, Alerts, Footer, Header, Main, PrivateRoute, SideMenu, Nps} from './components'
import {
  ChemoSafe,
  ChemoSafeTool,
  Coalition,
  Guidelines,
  Home,
  MyProfile,
  OurStory,
  PainManagement,
  SignUp,
  EmailSentSuccess,
  ResetPasswordSuccess,
  EmailValidatedSuccess,
  Login,
  ContactUs,
  NotFound,
  Programs,
  CancerAccessPartnership,
} from './pages'
import {AppActions, AppState} from './redux'
import {USER_ROLES} from './constants/userRoles'
import {setUser} from './redux/user/actions'
import {MidtierService} from './services'
import {setCoalitionRequests} from './redux/admin/actions'
import usePageViews from './utils/hooks/usePageViews'

interface SwitchRouterProps {
  isAuth: boolean
  isChemosafeToolAdmin: boolean
}

const SwitchRouter = ({isAuth, isChemosafeToolAdmin}: SwitchRouterProps) => {
  usePageViews()

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/our-story" component={OurStory} />
      <Route path="/programs" component={Programs} />
      <Route path="/pain-management" component={PainManagement} />
      <Route path="/chemosafe" component={ChemoSafe} />
      <Route path="/coalition" component={Coalition} />
      <Route path="/contact-us" component={ContactUs} />
      <Route path="/guidelines" component={Guidelines} />
      <Route path="/access-partnership" component={CancerAccessPartnership} />
      <PrivateRoute path="/chemosafe-tool" auth={isChemosafeToolAdmin} Component={ChemoSafeTool} />
      <PrivateRoute path="/login" auth={!isAuth} Component={Login} />
      <PrivateRoute path="/sign-up" auth={!isAuth} Component={SignUp as any} />
      <PrivateRoute path="/success-send-email" auth={!isAuth} Component={EmailSentSuccess} />
      <PrivateRoute path="/success-confirm-email" auth={!isAuth} Component={EmailValidatedSuccess} />
      <PrivateRoute path="/success-reset-password" auth={isAuth} Component={ResetPasswordSuccess} />
      <PrivateRoute path="/profile" auth={isAuth} Component={MyProfile} />
      <Route component={NotFound} />
    </Switch>
  )
}
function ScrollToTop() {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = ({user, setUser, setCoalitionRequests}: AppReduxInjectedProps) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)
  const isAuth = user !== null
  const isAdmin = user !== null && user?.roles.includes(USER_ROLES.ADMIN)
  const isChemosafeToolAdmin = user !== null && user?.roles.includes(USER_ROLES.CHEMOSAFE_TOOL_ADMIN)
  useEffect(() => {
    async function getUser() {
      if (user) {
        const user = await MidtierService.getUser()
        setUser(user)
      }
    }
    getUser()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    async function getCoalitionRequests() {
      user?.roles.includes(USER_ROLES.ADMIN) && setCoalitionRequests(await MidtierService.getCoalitionRequests())
    }
    getCoalitionRequests()
    // eslint-disable-next-line
  }, [user])

  return (
    <Router>
      <ScrollToTop />
      <Alerts />

      <div className={st.app} id="outer-container">
        <SideMenu
          isOpen={isSideMenuOpen}
          setIsSideMenuOpen={setIsSideMenuOpen}
          isAuth={isAuth}
          isAdmin={isAdmin}
          isChemosafeToolAdmin={isChemosafeToolAdmin}
        />
        <div className={st.pageWrap} id="page-wrap">
          <Header
            openSideMenu={() => {
              setIsSideMenuOpen(true)
            }}
          />
          <Main>
            <SwitchRouter isAuth={isAuth} isChemosafeToolAdmin={isChemosafeToolAdmin} />
          </Main>
          <CookieDisclaimer />
          <Footer />
        </div>
      </div>

      <Nps />
    </Router>
  )
}
const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) =>
  bindActionCreators(
    {
      setUser,
      setCoalitionRequests,
    },
    dispatch,
  )
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(App)
