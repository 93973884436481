import React from 'react'
import Modal, {Styles} from 'react-modal'

interface OwnProps {
  isOpen: boolean
  children: React.ReactNode
  closeModal: () => void
}

const modalStyles: Styles = {
  overlay: {
    display: 'flex',
    zIndex: 10000,
    backgroundColor: 'rgba(9, 8, 8, 0.50)',
    padding: 10,
  },
  content: {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    margin: 'auto',
  },
}

const ModalWrapper = ({closeModal, isOpen, children, ...otherProps}: OwnProps) => {
  const handleCloseModal = () => {
    closeModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="CGN Modal"
      style={modalStyles}
      ariaHideApp={false}
      onRequestClose={(e) => {
        handleCloseModal()
      }}
      {...otherProps}
    >
      {children}
    </Modal>
  )
}

export default ModalWrapper
