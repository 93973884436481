const usefulLinks = [
  {
    name: 'One-pager',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/ChemoSafeApp_1-pager.pdf2',
  },
  {
    name: 'App user guide',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/AAC%20Guidelines%20Version%201.pdf',
  },
  {
    name: 'App Frequently Asked Questions',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/ChemoSafeApp_FrequentlyAskedQuestions.pdf',
  },
  {
    name: 'ChemoSafe app demonstration video',
    url: 'https://www.youtube.com/watch?v=FIBi0c8ypy8&feature=youtu.be',
  },
]

export {usefulLinks}
