import {DEL_USER, SET_USER, REMOVE_USER_PROFILE_AVATAR, UserActionTypes, UserState} from './types'

const initialState: UserState = null

export function userReducer(state = initialState, action: UserActionTypes): UserState {
  switch (action.type) {
    case SET_USER:
      return {...state, ...action.payload}
    case DEL_USER:
      return null
    case REMOVE_USER_PROFILE_AVATAR:
      return {
        ...state,
        publicProfile: {
          ...(state.publicProfile || {}),
          imgUrl: null,
        },
      }
    default:
      return state
  }
}
