import React from 'react'

import st from './Cards.module.scss'

interface CardsProps {
  children?: React.ReactNode
}

const Cards = ({children}: CardsProps) => {
  return <div className={st.cards}>{children}</div>
}

export default Cards
