import React from 'react'

import st from './Centered.module.scss'

interface CenteredProps {
  children?: React.ReactNode
}

const Centered = ({children}: CenteredProps) => {
  return <div className={st.centered}>{children}</div>
}

export default Centered
