import React, {useState} from 'react'
import {
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore'
import CheckIcon from '@material-ui/icons/Check'
import PublishIcon from '@material-ui/icons/Publish'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {CSTReleaseMeta as AssessmentMetaWithoutAssessment} from '../../../../../services/types'
import {useStyles} from './styles'
import {ChangelogTable} from './components'

interface ReleaseRow {
  assessmentMeta: AssessmentMetaWithoutAssessment
}

interface VCTableProps {
  data: AssessmentMetaWithoutAssessment[]
  handleClick: (id: string) => void
}

interface RowProps {
  row: AssessmentMetaWithoutAssessment
  i: number
  openedRow?: string
  handleClick: (id: string) => void
}

const VersionControlTable = ({data, handleClick}: VCTableProps) => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [openedRow, setOpenedRow] = useState<string | undefined>(undefined)
  const classes = useStyles()
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }
  const handleChangeRowsPerPage = (event: {target: {value: string}}) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const getBackgroundColor = (assessmentMeta: AssessmentMetaWithoutAssessment) => {
    if (assessmentMeta.isActiveRelease) {
      return 'rgba(250,100,0,0.2)'
    }
    if (assessmentMeta.releaseDate === undefined) {
      return 'rgba(220,220,220)'
    }
    return 'white'
  }
  const ReleaseRow = ({assessmentMeta}: ReleaseRow) => {
    const {isActiveRelease, id, releaseDate} = assessmentMeta
    return isActiveRelease ? (
      <button className={classes.iconButtonStyle} disabled>
        <CheckIcon />
      </button>
    ) : (
      <button className={classes.iconButtonStyle} onClick={() => handleClick(id)}>
        {releaseDate ? <RestoreIcon /> : <PublishIcon />}
      </button>
    )
  }
  const Row = ({row, i, openedRow, handleClick}: RowProps) => {
    const [isOpened, setIsOpened] = useState(openedRow === row.id)
    const handleOpen = (id: string) => {
      if (isOpened) {
        handleClick('')
      } else handleClick(id)
      setIsOpened(!isOpened)
    }

    return (
      <>
        <TableRow key={i} style={{backgroundColor: getBackgroundColor(row)}}>
          <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(row.id)}>
              {isOpened ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.version}</TableCell>
          <TableCell>{row.createdAt}</TableCell>
          <TableCell>{row.updateDate}</TableCell>
          <TableCell>{row.updateAuthor}</TableCell>
          <TableCell>
            <ReleaseRow assessmentMeta={row} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className={classes.collapseCell} colSpan={6}>
            <Collapse in={isOpened} timeout="auto">
              <ChangelogTable version={row.version} />
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    )
  }
  return (
    <Paper>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.thead} />
              <TableCell className={classes.thead}>Version</TableCell>
              <TableCell className={classes.thead}>Created</TableCell>
              <TableCell className={classes.thead}>Updated</TableCell>
              <TableCell className={classes.thead}>Author</TableCell>
              <TableCell className={classes.thead}>Release</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => {
              return <Row row={row} i={i} key={i} openedRow={openedRow} handleClick={setOpenedRow} />
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
export default VersionControlTable
