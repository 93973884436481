import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Link, useHistory} from 'react-router-dom'

import {AppState} from '../../redux'
import {USER_ROLES} from '../../constants/userRoles'
import st from './LoginAndUserProfileBar.module.scss'
import {BellIconWithNotifications, BellIconWithoutNotifications} from './icons'
import {BellDropdown, LoginFormDropdown} from './components'
interface BellIconProps {
  coalitionRequests: number
}
const BellIcon = ({coalitionRequests}: BellIconProps) => {
  const [isShowBellDropdown, setIsShowBellDropdown] = useState(false)
  const isHaveCoalitionRequests = coalitionRequests > 0
  return (
    <div className={st.bellContainer}>
      <button className={st.bellIconButtonStyle} onClick={() => setIsShowBellDropdown(!isShowBellDropdown)}>
        {isHaveCoalitionRequests ? <BellIconWithNotifications /> : <BellIconWithoutNotifications />}
      </button>
      {isShowBellDropdown && (
        <BellDropdown closeDropdown={() => setIsShowBellDropdown(false)} coalitionRequests={coalitionRequests} />
      )}
    </div>
  )
}

// todo refactor to two component: UserProfileBar and LoginBar
const LoginAndUserProfileBar = ({user, coalitionRequests}: AppReduxInjectedProps) => {
  const [isShownLoginForm, setIsShownLoginForm] = useState(false)
  const history = useHistory()
  const isAuth = user !== null
  const isAdmin = user && user?.roles.includes(USER_ROLES.ADMIN)
  const imageUrl =
    (user && user.publicProfile && user.publicProfile.imgUrl) ||
    `${process.env.PUBLIC_URL}${'images/avatars/not_found.png'}`

  history.listen(() => {
    setIsShownLoginForm(false)
  })

  useEffect(() => {
    setIsShownLoginForm(false)
  }, [])

  const handleButtonClick = () => {
    setIsShownLoginForm(!isShownLoginForm)
  }

  const closeLoginFormDropdown = () => {
    setIsShownLoginForm(false)
  }
  const truncate = (str: string) => (str.length > 10 ? `${str.substring(0, 13)}...` : str)
  return (
    <div className={st.loginAndUserProfileBar}>
      {isAuth ? (
        <div className={st.userProfileBar}>
          {isAdmin && <BellIcon coalitionRequests={coalitionRequests} />}
          <Link to="/profile" className={st.loggedBlock}>
            <div className={st.imageBlock}>
              <img className={st.image} src={imageUrl} alt="" />
            </div>
            <div className={st.textBlock}>
              <p className={st.welcomeText}>{`Hi, ${user && truncate(user.firstName)}`}</p>
            </div>
          </Link>
        </div>
      ) : (
        <>
          <button className="reset-button" onClick={handleButtonClick}>
            Login
          </button>
          {isShownLoginForm && <LoginFormDropdown closeDropdown={closeLoginFormDropdown} />}
        </>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
    coalitionRequests: state.admin.coalitionRequests,
  }
}
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps)(LoginAndUserProfileBar)
