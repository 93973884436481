import React from 'react'
import CookieConsent from 'react-cookie-consent'

const CookieDisclaimer = () => (
  <CookieConsent
    location="bottom"
    buttonText="Accept"
    cookieName="AACWEBSITE-COOKIE-DISCLAMER"
    style={{background: 'black'}}
    buttonStyle={{background: '#eb7346', fontSize: '13px'}}
    expires={150}
  >
    <span> This website uses cookies to enhance the user experience.</span>
  </CookieConsent>
)

export default CookieDisclaimer
