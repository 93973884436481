import {CLOSE_MODAL, ModalsActionTypes, SET_ACTIVE_MODAL, MODAL_NAMES} from './types'

export function setActiveModal(activeModal: MODAL_NAMES): ModalsActionTypes {
  return {
    type: SET_ACTIVE_MODAL,
    payload: activeModal,
  }
}
export function closeModal(): ModalsActionTypes {
  return {
    type: CLOSE_MODAL,
  }
}
