import React from 'react'
import cn from 'classnames'
import {FormikErrors} from 'formik'
import st from './ErrorMessage.module.scss'

interface ErrorMessageProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
  className?: string
}

const ErrorMessage = ({error, className}: ErrorMessageProps) => {
  return <span className={cn(st.error, className)}>{error}</span>
}

export default ErrorMessage
