export const ourWorkDescriptionText = `Allied Against Cancer works to improve access to and the quality of cancer treatment by gathering, connecting,
          and supporting projects and working groups that are focused on practical approaches. Core to our approach is
          that we work in partnership with health ministries while leveraging technology in order to scale and sustain
          effective cancer treatment.`

export const treatmentGuidlinesText = `Treatment guidelines developed in collaboration with African oncologists to reflect the resource
                  variations unique to practicing on the continent.`
export const handlingOfChemotherapyText = `Trainings and assessments to improve safe handling of chemotherapy in a cancer center.`

export const painManagementText = `Technical support to hospitals and health ministries to improve patient access to essential pain
                  medicines.`

export const cancerAccessPartnershipText = `Increasing the availability of high-quality, affordable cancer medications`
