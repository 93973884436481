import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {useStyles} from '../../styles'
import {ChangeLog, ChemosafeDocument} from '../../../../../../../../../../../services/types'

interface ReferenceTemplateProps {
  reference: any
  classes: any
  type?: string
}

interface NoReferenceProps {
  title?: string
  classes: any
}

interface ReferenceProps {
  changelog: ChangeLog
  documents: ChemosafeDocument[]
}
const NoReference = ({title, classes}: NoReferenceProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {title && title} Reference
      </Typography>
      <Typography>No reference</Typography>
    </Box>
  )
}

const ReferenceTemplate = ({reference, classes, type}: ReferenceTemplateProps) => {
  return (
    <>
      {reference && (
        <>
          <Box className={classes.bodySection}>
            <Typography className={classes.bodyTitle} variant="body2">
              {type && type} Reference Name
            </Typography>
            <Typography>{reference.name}</Typography>
          </Box>
          <Box className={classes.bodySection}>
            <Typography className={classes.bodyTitle} variant="body2">
              {type && type} Reference Url
            </Typography>
            <Typography>{reference.url ? reference.url : 'No reference url'}</Typography>
          </Box>
        </>
      )}
      {!reference && <NoReference classes={classes} title={type} />}
    </>
  )
}

const References = ({changelog, documents}: ReferenceProps) => {
  const classes = useStyles()
  const reference = () => {
    return (
      changelog.newQuestion && documents.find((document) => document.id === changelog.newQuestion.resources.referenceId)
    )
  }
  const oldReference = () => {
    return (
      changelog.oldQuestion && documents.find((document) => document.id === changelog.oldQuestion.resources.referenceId)
    )
  }
  switch (changelog.type) {
    case 'EDIT_REFERENCE_ID': {
      return (
        <>
          <ReferenceTemplate type="New" classes={classes} reference={reference()} />
          <ReferenceTemplate type="Old" classes={classes} reference={oldReference()} />
        </>
      )
    }
    case 'DELETE_QUESTION':
      return <ReferenceTemplate classes={classes} reference={oldReference()} type="Old" />
    default:
      return <ReferenceTemplate classes={classes} reference={reference()} />
  }
}
export default References
