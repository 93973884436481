import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {AppActions, AppState} from '../../../../../redux'
import {setUser} from '../../../../../redux/user/actions'
import {getTokens, setTokens} from '../../../../../utils/tokens'
interface Props extends AppReduxInjectedProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConfirmUserForm?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  MainForm?: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ApprovedDialog?: any
  approvedText: string
}

const FormsFlow: React.FunctionComponent<Props> = ({
  ConfirmUserForm,
  MainForm,
  ApprovedDialog,
  approvedText,
  setUser,
  user,
}) => {
  const [isUserConfirmed, setIsUserConfirmed] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [confirmationPassword, setConfirmationPassword] = useState('')
  const set = (func: (value: boolean) => void) => (value: boolean) => {
    func(value)
  }

  const keepPassword = (password: string) => {
    setConfirmationPassword(password)
  }

  return (
    <div>
      {!isUserConfirmed && !isApproved && (
        <ConfirmUserForm
          handleResponse={set(setIsUserConfirmed)}
          setUserInState={setUser}
          user={user}
          setTokensInState={setTokens}
          keepPassword={keepPassword}
        />
      )}
      {isUserConfirmed && !isApproved && (
        <MainForm
          handleResponse={set(setIsApproved)}
          setUserInState={setUser}
          setTokensInState={setTokens}
          tokens={getTokens()}
          confirmationPassword={confirmationPassword}
        />
      )}
      {isUserConfirmed && isApproved && <ApprovedDialog text={approvedText} />}
    </div>
  )
}
const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  }
}
const mapDispatchToProps = (dispatch: Dispatch<AppActions>) => bindActionCreators({setUser}, dispatch)
type AppReduxInjectedProps = ReturnType<typeof mapDispatchToProps> & ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps, mapDispatchToProps)(FormsFlow)
