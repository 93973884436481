const latestTrainings = [
  {
    name: 'Introduction to Hazardous Drugs in an Occupational Setting',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/1_HD_Introduction.pptx',
  },
  {
    name: 'Personal Protective Equipment (PPE)',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/2_Personal_Protective_Equipment.pptx',
  },
  {
    name: 'Receiving Hazardous drugs ',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/3_Receiving.pptx',
  },
  {
    name: 'Transport of Hazardous drugs',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/4_Transport_of_hazardous_drugs.pptx',
  },
  {
    name: 'Spill Control',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/5_Spill_control.pptx',
  },
  {
    name: 'Cleaning and Decontamination Overview',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/6_Cleaning_DDCD1-Overview.pptx',
  },
  {
    name: 'Cleaning and Decontamination the Ventilation Tool',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/7_Cleaning_DDCD2-Ventilation_Tool.pptx',
  },
  {
    name: 'Cleaning and Decontamination: Clean Room',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/8_Cleaning_DDCD3-Clean_Room.pptx',
  },
  {
    name: 'Drug Compounding',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/9_Drug_Compounding.pptx',
  },
  {
    name: 'Administration: Pre-Treatment Assessment',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/10_Administration_1-Pre-Treatment_Assessment.pptx',
  },
  {
    name: 'Administration: Treatment Goals and Strategies',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/11_Administration_2-Goals_and_Dosing.pptx',
  },
  {
    name: 'Administration: Handling Excreta',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/12_Administration_4-Handling_Excreta.pptx',
  },
  {
    name: 'Administration: Extravasations',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/13_Administration_3-Extravasations.pptx',
  },
  {
    name: 'Laundry and Housekeeping',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/14_Laundry_and_housekeeping.pptx',
  },
  {
    name: 'Waste Collection and Disposal',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/15_Waste_collection_and_disposal.pptx',
  },
  {
    name: 'Medical Surveillance',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/16_Medical_surveillance.pptx',
  },
]

export {latestTrainings}
