import React, {useState} from 'react'
import cx from 'classnames'
import {Box, Checkbox, FormControl, TextField, Typography} from '@material-ui/core'
import {Close as CloseIcon} from '@material-ui/icons'
import Modal from 'react-modal'
import Loader from 'react-loader-spinner'
import {Button} from '../../../../../components/buttons'
import {MidtierService} from '../../../../../services'
import {ChemosafeDocument} from '../../../../../services/types'
import {validator} from '../../../../../utils/validation/validator'
import {useStyles} from './styles'

const modal = {
  content: {
    top: '5%',
    left: '30%',
    right: '20%',
    height: 'auto',
    minHeight: '390px',
  },
}

interface EditModalProps {
  isOpen: boolean
  closeModal: () => void
  data: ChemosafeDocument
  type: 'resource' | 'reference' | undefined
  updateTable: () => void
}

interface EditReferenceProps {
  closeModal: () => void
  data: ChemosafeDocument | undefined
  updateTable: () => void
  classes: any
}
interface ErrorProps {
  resourceName?: string
  resourceFile?: string
  referenceName?: string
  referenceUrl?: string
}
const EditReference = ({data, updateTable, classes, closeModal}: EditReferenceProps) => {
  const [referenceName, setReferenceName] = useState<string | undefined>(data?.name)
  const [referenceUrl, setReferenceUrl] = useState<string | undefined>(data?.url)
  const [isLoading, setIsLoading] = useState(false)

  const errors: ErrorProps = validator.rules({
    referenceName: validator.validate(referenceName).required(),
    referenceUrl: validator.validate(referenceUrl).required().url(),
  })

  const updateReference = async () => {
    setIsLoading(true)
    await MidtierService.editReference({
      id: data?.id || '',
      fileLocation: referenceUrl,
      fileName: referenceName,
    })
    setIsLoading(false)
    closeModal()
    updateTable()
  }

  return (
    <Box>
      <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.referenceName})}>
        Reference Name
      </Typography>
      <FormControl className={classes.inputContainer}>
        <TextField
          className={classes.input}
          variant="outlined"
          defaultValue={referenceName}
          onChange={(e: any) => setReferenceName(e.target.value)}
        />
      </FormControl>
      <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.referenceUrl})}>
        Reference Url
      </Typography>
      <FormControl className={classes.inputContainer}>
        <TextField
          className={classes.input}
          variant="outlined"
          defaultValue={referenceUrl}
          onChange={(e: any) => setReferenceUrl(e.target.value)}
        />
      </FormControl>
      <Box className={classes.buttonBox}>
        <Button
          className={classes.button}
          disabled={Object.keys(errors).some((fieldName) => errors[fieldName])}
          onClick={updateReference}
          label={
            isLoading ? (
              <Loader className={classes.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
            ) : (
              <p className={classes.button}>Update Reference</p>
            )
          }
        />
      </Box>
    </Box>
  )
}

const EditResource = ({data, updateTable, classes, closeModal}: EditReferenceProps) => {
  const [resourceName, setResourceName] = useState<string | undefined>(data?.name)
  const [isChangeResourceFile, setIsChangeResourceFile] = useState(false)
  const [resourceFile, setResourceFile] = useState<File | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const errors: ErrorProps = validator.rules({
    resourceName: validator.validate(resourceName).required(),
    resourceFile: validator.validate(resourceFile).required(),
  })

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file) {
      setResourceFile(file)
    }
  }

  const updateResource = async () => {
    setIsLoading(true)
    try {
      if (resourceFile) {
        await MidtierService.editResourceWithNewFile({
          id: data?.id || '',
          file: resourceFile,
          fileName: resourceName || '',
        })
      } else {
        await MidtierService.editResourceName({
          id: data?.id || '',
          fileName: resourceName || '',
        })
      }
      setIsLoading(false)
      closeModal()
      updateTable()
    } catch (e) {
      setIsLoading(false)
    }
  }
  return (
    <Box>
      <Box className={classes.contentContainer}>
        <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.resourceName})}>
          Resource Name
        </Typography>
        <FormControl className={classes.inputContainer}>
          <TextField
            className={classes.input}
            variant="outlined"
            defaultValue={resourceName}
            onChange={(e: any) => setResourceName(e.target.value)}
          />
        </FormControl>
        <Typography variant="body2" className={classes.checkboxTitle}>
          Change Resource File ?
        </Typography>
        <Checkbox
          className={classes.checkBox}
          value={isChangeResourceFile}
          inputProps={{'aria-label': 'Checkbox A'}}
          onClick={() => {
            setIsChangeResourceFile(!isChangeResourceFile)
            isChangeResourceFile && setResourceFile(undefined)
          }}
        />
        {isChangeResourceFile && (
          <>
            <Typography variant="body2" className={cx(classes.typeTitle, {error: !!errors.resourceFile})}>
              Resource File
            </Typography>
            <FormControl className={classes.inputContainer}>
              <input type="file" accept="application/pdf" onChange={onFileChange} />
            </FormControl>
          </>
        )}
      </Box>
      <Box className={classes.buttonBox}>
        <Button
          disabled={isChangeResourceFile ? !resourceName?.length || !resourceFile : !resourceName?.length}
          onClick={updateResource}
          label={
            isLoading ? (
              <Loader className={classes.spinner} type="TailSpin" color="white" height={24} width={24} visible={true} />
            ) : (
              <p className={classes.button}>Update Resource</p>
            )
          }
        />
      </Box>
    </Box>
  )
}

const EditModal = ({isOpen, closeModal, data, type, updateTable}: EditModalProps) => {
  const classes = useStyles()
  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal} style={modal} contentLabel="Edit" ariaHideApp={false}>
      <Box className={classes.container}>
        <button className={classes.iconButtonStyle} onClick={closeModal}>
          <CloseIcon />
        </button>
        {(() => {
          switch (type) {
            case 'reference':
              return <EditReference closeModal={closeModal} classes={classes} updateTable={updateTable} data={data} />
            case 'resource':
              return <EditResource closeModal={closeModal} classes={classes} updateTable={updateTable} data={data} />

            default:
              return null
          }
        })()}
      </Box>
    </Modal>
  )
}
export default EditModal
