import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  changelogTitle: {
    color: '#9a9a9a',
    fontWeight: 600,
  },
  thead: {
    fontSize: '0.9rem',
    color: '#000',
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
    width: '12.5%',
  },
  cell: {
    borderBottom: 0,
    color: '#9a9a9a',
    fontWeight: 600,
    padding: '16px 16px 0px 16px',
    textAlign: 'center',
    '&:first-child': {
      textAlign: 'left',
    },
    width: '12.5%',
  },
  text: {
    color: '#524545',
  },
  row: {
    borderBottom: '1px solid #ddd',
  },
  iconButtonStyle: {
    border: 'none',
    background: 'none',
  },
  circle: {
    marginLeft: '45%',
  },
})
