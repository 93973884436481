const objectives = [
  {
    name: 'Adapt international standards and integrate them into national policies.',
    url: '',
  },
  {
    name:
      'Ensure that high-quality, aﬀordable personal protective equipment (PPE) and engineering controls are consistently available to all workers with potential exposure to hazardous drugs.',
    url: '',
  },
  {
    name: 'Strengthen infrastructure, standard operating procedures, and documentation systems in cancer centers.',
    url: '',
  },
  {
    name: 'Train health workers.',
    url: '',
  },
]

export {objectives}
