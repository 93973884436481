import './Footer.css'

import React from 'react'

import {ContentWrap, Link, Logo, NavBar} from '../'
import st from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={st.footer}>
      <ContentWrap styles={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
        <Link to="/">
          <Logo />
        </Link>
        <NavBar className={st.footerLinkWrap}>
          <Link to="/contact-us" className={st.footerLinks} rel="noopener noreferrer">
            Contact us
          </Link>
          <Link
            to="https://www.cancer.org/about-us/policies/user-agreement.html"
            external
            className={st.footerLinks}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </Link>
          <Link
            to="https://www.cancer.org/about-us/policies/privacy-statement.html"
            className={st.footerLinks}
            external
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </NavBar>
      </ContentWrap>
    </footer>
  )
}

export default Footer
