export function validPasswordConfirmation(this: {
  value: {password: string; confirmPassword: string}
}): {
  errorMessage: string
  isValid: boolean
} {
  // eslint-disable-next-line
  const {password, confirmPassword} = this.value
  const isValid = password === confirmPassword
  return {
    errorMessage: isValid ? '' : 'Confirm password must match with password',
    isValid,
  }
}
