import React, {ChangeEvent} from 'react'
import {FormControl, TextField} from '@material-ui/core'
import {useStyles} from './styles'

interface GlobalSearchProps {
  setGlobalSearch: (str: string) => void
}

const GlobalSearch = ({setGlobalSearch}: GlobalSearchProps) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.value === '') {
      setGlobalSearch('')
    }
    setGlobalSearch(e.target.value)
  }
  const classes = useStyles()

  return (
    <FormControl className={classes.searchContainer}>
      <TextField
        variant="outlined"
        onChange={(e: any) => changeHandler(e)}
        label="Type for search by name..."
        className={classes.search}
      />
    </FormControl>
  )
}

export default GlobalSearch
