import cn from 'classnames'
import React from 'react'

import st from './ContentWrap.module.scss'

interface ContentWrapProps {
  children?: React.ReactNode
  className?: string
  styles?: React.CSSProperties
  attrs?: React.ReactNodeArray
}

const ContentWrap = ({children, className = '', styles}: ContentWrapProps) => {
  return (
    <div className={cn(st.contentWrap, className)} style={styles}>
      {children}
    </div>
  )
}

export default ContentWrap
