import React, {useEffect, useState} from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {MidtierService} from '../../../../../../../../../services'
import {ChangeLog} from '../../../../../../../../../services/types'
import {useStyles} from './styles'
import {
  Recommendation,
  Requirement,
  Resources,
  Question,
  References,
  Condition,
  ShowInAssessment,
  ActionPlanType,
} from './components'
export interface ChemosafeDocument {
  id?: string
  url?: string
  name?: string
  type?: 'resource' | 'reference'
}

export interface ChangelogModalProps {
  changelog: ChangeLog
  isOpened: boolean
  handleClose: () => void
  version: number
}

const ChangelogModal = ({changelog, isOpened, handleClose, version}: ChangelogModalProps) => {
  const classes = useStyles()
  const [cfDocuments, setCFDocuments] = useState<ChemosafeDocument[]>([])
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    const getAllCFDocuments = async () => {
      const cfDocuments = await MidtierService.getAllChemosafeDocuments()
      setCFDocuments(cfDocuments)
      setIsLoading(false)
    }
    getAllCFDocuments()
  }, [])

  return (
    <Dialog
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      classes={{paper: classes.dialog}}
    >
      <DialogTitle id="customized-dialog-title">
        <Button aria-label="close" onClick={handleClose} className={classes.closeButton}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        {!isLoading && (
          <Container>
            <Box className={classes.modalHeader}>
              <Box className={classes.questionInfo}>
                <Typography className={classes.modalTitle}>{changelog.section}</Typography>
                <Typography className={classes.modalTitle}>&gt;</Typography>
                <Typography className={classes.modalTitle}>{changelog.subSection}</Typography>
                <Typography className={classes.modalTitle}>&gt;</Typography>
                <Typography className={classes.modalTitle}>Question {changelog.questionId}</Typography>
              </Box>
              <Box className={classes.release}>Release {version}</Box>
            </Box>
            <Box />
            <Box>
              <Question changelog={changelog} />
              <Recommendation classes={classes} changelog={changelog} />
              <Resources changelog={changelog} documents={cfDocuments} />
              <References changelog={changelog} documents={cfDocuments} />
              <Requirement changelog={changelog} />
              <Condition changelog={changelog} />
              <ShowInAssessment changelog={changelog} />
              <ActionPlanType changelog={changelog} classes={classes} />
            </Box>
          </Container>
        )}
        {isLoading && (
          <Box my={1} height="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}
export default ChangelogModal
