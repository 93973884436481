import React, {useEffect, useState} from 'react'
import {Grid, Paper, makeStyles, Container, Typography, Box, CircularProgress} from '@material-ui/core'
import {Switch, Redirect} from 'react-router-dom'
import {useSelector} from 'react-redux'
import {PrivateRoute} from '../../components'
import {USER_ROLES} from '../../constants/userRoles'
import {AppState} from '../../redux'
import {MidtierService} from '../../services'
import {ChemosafeDocument, CSTRelease, CSTReleaseMeta} from '../../services/types'
import {ChemoSafeToolChangelog} from './ChemoSafeToolChangelog'
import ChemoSafeToolDashboard from './ChemoSafeToolDashboard'
import ChemoSafeToolSideBar from './components/ChemoSafeToolSideBar'
import {ResourcesAndReferences} from './ResourcesAndReferences'
import {useCSTContext} from './context/chemosafeToolContext'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexGrow: 1,
    padding: 0,
  },
  paper: {
    height: '100%',
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  'appBar-title': {
    flexGrow: 1,
    textTransform: 'uppercase',
  },
  adminBox: {
    width: '100%',
    padding: '24px',
  },
  adminTitle: {
    color: '#eb7643',
  },
})

interface CRData {
  currentRelease?: CSTRelease
  currentActiveRelease?: CSTReleaseMeta
  blacklist?: string[]
  resources?: ChemosafeDocument[]
}
const SwitchRouter = ({isChemosafeToolAdmin}: {isChemosafeToolAdmin: boolean}) => (
  <Switch>
    <PrivateRoute path="/chemosafe-tool/dashboard" auth={isChemosafeToolAdmin} Component={ChemoSafeToolDashboard} />
    <PrivateRoute path="/chemosafe-tool/changelog" auth={isChemosafeToolAdmin} Component={ChemoSafeToolChangelog} />
    <PrivateRoute path="/chemosafe-tool/resources" auth={isChemosafeToolAdmin} Component={ResourcesAndReferences} />
    <Redirect to="/chemosafe-tool/dashboard" />
  </Switch>
)

const CSTApp = () => {
  const classes = useStyles()
  const user = useSelector((state: AppState) => state.user)
  const value = useCSTContext()

  const [isLoading, setIsLoading] = useState(true)
  const isChemosafeToolAdmin = user !== null && user?.roles.includes(USER_ROLES.CHEMOSAFE_TOOL_ADMIN)

  useEffect(() => {
    async function getReleases() {
      const resources = await MidtierService.getAllChemosafeDocuments()
      const {currentRelease, currentActiveRelease, blacklist} = await MidtierService.getCurrentRelease()
      const data: CRData = {resources, currentRelease, currentActiveRelease, blacklist}
      value.actions?.loadData(data)
      setIsLoading(false)
    }

    getReleases()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.wrapper}>
      <Paper className={classes.contentContainer} elevation={4}>
        <Grid className={classes.contentContainer} container alignItems="stretch">
          <Grid item xs={12} md={3}>
            <ChemoSafeToolSideBar />
          </Grid>
          <Grid item xs={12} md={9}>
            <Box className={classes.adminBox}>
              <Typography variant="h1" className={classes.adminTitle}>
                ADMIN
              </Typography>
            </Box>
            <Container>
              <Box my={1} height="100%" display="flex" alignItems="center" justifyContent="center">
                {isLoading ? (
                  <CircularProgress color="primary" />
                ) : (
                  <SwitchRouter isChemosafeToolAdmin={isChemosafeToolAdmin} />
                )}
              </Box>
            </Container>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
}

export default CSTApp
