export const ourMissionText = ` Our vision is for every person diagnosed with cancer to receive the highest possible standard of specialty
          care, in an efficient system that delivers maximum outcomes for the patient while minimizing costs. We are
          working to advance the practitioners, products, and processes that will improve cancer care and control in
          Sub-Saharan Africa.`

export const ourStoryColoredText = `Cancer in Africa is characterized by late diagnosis, limited access to treatment, and poor patient outcomes,
          even for cancers that are highly treatable. Most patients seeking treatment are already in the advanced stages
          of the disease, and it is estimated that fewer than 10% of patients receive treatment, including chemotherapy,
          radiotherapy, or even pain relief. As a result, cancer is twice as lethal in Sub-Saharan Africa as in the
          United States.`

export const practitionersText = ` Sub-Saharan Africa faces an extreme shortage in the oncology workforce – from oncologists, to
                radiotherapy technicians, to pharmacists, nurses, medical physicists, and pathologists. We work with
                health ministries and hospitals to expand, train, and support the oncology workforce, including
                oncologists, radiotherapy technicians, and pathologists.`

export const productsText = `Often high-quality, affordable treatments and diagnostics are lacking at hospitals due to market
                failures and gaps in management systems. As a result, patients often go without treatment, or providers
                are forced to implement sub-optimal treatment plans. We work to improve access to chemotherapy,
                radiotherapy, surgery, diagnostics, and pain relief by shaping the market to function better.`

export const processesText = `Guidelines adapted to available clinical treatment resources that promote delivery of evidence-based
                care, improve patient and health worker safety, and ensure effective use of resources were largely absent
                across the region. We work with experts on the African continent and around the world to reduce the
                asymmetry of information between low- and high-resource settings by developing appropriate and
                actionable processes to improve the quality of cancer care.`

export const whoWeArePartOneText = `Allied Against Cancer is an alliance of partners that work to support the needs of the African oncology
          community. Our agenda is driven by African practitioners and governments seeking to address access and quality
          disparities in cancer care and control.`

export const whoWeArePartTwoText = `At the center of the work is the African Cancer Coalition, a growing consortium of clinical and public health
          experts from 13 countries across the continent. The Coalition partners with us and sets the priorities for our
          work.`

export const whoWeArePartThreeText = `Supporting the African Cancer Coalition are the founding partners of Allied Against Cancer: the American
          Cancer Society, National Comprehensive Cancer Network and Clinton Health Access Initiative. The partners
          focus on practically and directly mitigating the growing problem of cancer in Sub-Saharan Africa, each
          delivering unique assets to joint initiatives that enable the conditions for a sustainable cancer care system.`

export const howWeWorkText = `The African oncology community sets the priorities to advance the practitioners, products, and processes
          affecting cancer care. Allied Against Cancer brings together the necessary technical assistance partners and
          resources to work on these projects. A working group of experts from the African Cancer Coalition member are
          an integral part of every project.`
export const disclaimerText = `Disclaimer: Any member's mere participation in this Alliance is not an endorsement nor promotion of other
          members' commercial products or services.`

export const ourWork = [
  {
    name:
      'Organizing regional working group sessions to set standards for cancer treatment and create space for innovation.',
    url: '/',
    target: '',
  },
  {
    name:
      'Developing digital platforms for clinical decision support to facilitate treatment planning and adoption of clinical guidelines that raise the standard of care.',
    url: '/',
    target: '',
  },
  {
    name:
      'Establishing agreements with pharmaceutical manufacturers to increase access and availability of essential, high-quality cancer medicines.',
    url: '/',
    target: '',
  },
  {
    name: 'Developing and maintaining regional NCCN Harmonized Guidelines™.',
    url: '/',
    target: '',
  },
]

export const additionalInformation = [
  {
    text: 'Achieving the cancer moonshot in Africa. Ecancer (Nov 10, 2022)',
    link: 'https://ecancer.org/en/journal/editorial/126-achieving-the-cancer-moonshot-in-africa',
  },
  {
    text: 'Opinion: It’s time for a new US cancer initiative in Africa. Devex (Mar 17, 2021)',
    link: 'https://www.devex.com/news/opinion-it-s-time-for-a-new-us-cancer-initiative-in-africa-99418',
  },
  {
    text:
      'Clinton Health Access Initiative and American Cancer Society announce agreement to expand Cancer Access Partnership. PRNewswire (Mar 08, 2021).',
    link:
      'https://www.prnewswire.com/news-releases/clinton-health-access-initiative-and-american-cancer-society-announce-agreement-to-expand-cancer-access-partnership-301241892.html',
  },
  {
    text:
      'Toward Optimization of Cancer Care in Sub-Saharan Africa: Development of National Comprehensive Cancer Network Harmonized Guidelines for Sub-Saharan Africa. JCO Global Oncology. (Sept 24, 2020)',
    link:
      'https://ascopubs.org/doi/10.1200/GO.20.00091?url_ver=Z39.88-2003&rfr_id=ori:rid:crossref.org&rfr_dat=cr_pub%20%200pubmed',
  },
  {
    text:
      'NCCN Harmonized Guidelines for Sub-Saharan Africa: A Collaborative Methodology for Translating Resource-Adapted Guidelines Into Actionable In-Country Cancer Control Plans. JCO Global Oncology. (Sept 24, 2020)',
    link: 'https://ascopubs.org/doi/full/10.1200/GO.20.00436',
  },
  {
    text: 'Allied Against Cancer recognized as a World Changing Idea. Fast Company. (Apr 28, 2020)',
    link:
      'https://www.fastcompany.com/90492169/world-changing-ideas-awards-2020-europe-middle-east-and-africa-finalists-and-honorable-mentions',
  },
  {
    text:
      'Nigeria launches public-private partnership to ensure affordable access to high-quality chemotherapies at seven teaching hospitals. CHAI News and Press Releases. (Oct 29, 2019)',
    link:
      'https://clintonhealthaccess.org/nigeria-launches-public-private-partnership-to-ensure-affordable-access-to-high-quality-chemotherapies-at-seven-teaching-hospitals',
  },
  {
    text: `International Collaboration to Improve Cancer Care in Sub-Saharan Africa Announces Management of Most Adult
            Cancers Covered by NCCN Harmonized Guidelines. NCCN News (Aug 28, 2019).`,
    link: 'https://www.nccn.org/about/news/newsinfo.aspx?NewsID=1665',
  },
  {
    text: `NCCN Goes Global With Guidelines Tailored for Treatment Capabilities Abroad. OncologyLive (May 20, 2019).`,
    link:
      'https://www.onclive.com/publications/Oncology-live/2019/vol-20-no-11/nccn-goes-global-with-guidelines-tailored-for-treatment-capabilities-abroad',
  },
  {
    text: `In Africa, US Cancer Guidelines Are 'Harmonized' to Locale. Medscape Medical News (Mar 25, 2019).`,
    link: 'https://www.medscape.com/viewarticle/910866',
  },
  {
    text: `Countering the rise of cancer in Sub-Saharan Africa. CancerWorld Plus (Aug 29, 2018).`,
    link: 'https://cancerworld.net/cancerworld-plus/countering-the-rise-of-cancer-in-sub-saharian-africa/',
  },
  {
    text: `Improving Cancer Care in Sub-Saharan Africa. Cancer today (Mar 22, 2018).`,
    link: 'https://www.cancertodaymag.org/Pages/Spring2018/Improving-Cancer-Care-in-Sub-Saharan-Africa.aspx',
  },
  {
    text: `Novartis, ASCP and ACS join forces to fight cancer in Ethiopia, Uganda and Tanzania. American Cancer Society
        Press Release (Nov 15, 2017).`,
    link:
      'https://www.novartis.com/news/media-releases/novartis-ascp-and-acs-join-forces-fight-cancer-ethiopia-uganda-and-tanzania',
  },
  {
    text: `A New, Harmonized Approach Takes a Stand against Rising Rates and Poor Outcomes for Cancer in Sub-Saharan
        Africa. NCCN News (Nov 8, 2017).`,
    link: 'https://www.nccn.org/about/news/newsinfo.aspx?NewsID=984',
  },
  {
    text: `As Cancer Tears Through Africa, Drug Makers Draw Up a Battle Plan. The New York Times (Oct 7, 2017).`,
    link:
      'https://www.nytimes.com/2017/10/07/health/africa-cancer-drugs.html?rref=collection%2Fsectioncollection%2Fscience&amp;action=click&amp;contentCollection=science&amp;region=stream&amp;module=stream_unit&amp;version=latest&amp;contentPlacement=3&amp;pgtype=sectionfront&amp;_r=0',
  },
  {
    text: `Brain scan: Meg O’Brien and therapies for all. The Economist (Sept 14, 2017).`,
    link: 'https://www.economist.com/technology-quarterly/2017/09/14/brain-scan-meg-obrien-and-therapies-for-all',
  },
  {
    text: `American Cancer Society and Clinton Health Access Initiative Announce Collaborations with Pfizer and Cipla to
        Increase Access to Lifesaving Cancer Treatment in Africa. American Cancer Society Press Release (Jun 20, 2017).`,
    link:
      'https://pressroom.cancer.org/2017-06-20-American-Cancer-Society-and-Clinton-Health-Access-Initiative-Announce-Collaborations-with-Pfizer-and-Cipla-to-Increase-Access-to-Lifesaving-Cancer-Treatment-in-Africa',
  },
  {
    text: `Helping Cancer's Forgotten Victims. Scientific American (May 12, 2017).`,
    link:
      'https://blogs.scientificamerican.com/observations/helping-cancers-forgotten-victims/?WT.mc_id=SA_TW_HLTH_BLOG&amp;sf78247490=1',
  },
  {
    text: `Sharing data could help African governments reduce the cost of cancer treatment. Quartz Africa (Dec 22, 2016).`,
    link:
      'https://qz.com/africa/869284/sharing-data-could-help-african-governments-reduce-the-cost-of-cancer-treatment/',
  },
]
