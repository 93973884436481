import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {useCSTContext, ChemoSafeToolContextProps} from '../../../context/chemosafeToolContext'
import {useStyles} from './styles'

const DashboardHeader = () => {
  const {currentActiveRelease}: ChemoSafeToolContextProps = useCSTContext()
  const classes = useStyles()

  const formattedCurrentReleaseDate = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(currentActiveRelease!.releaseDate))

  return (
    <Box>
      <Typography variant="h2" className={classes.adminTitle}>
        ChemoSafe Admin
      </Typography>
      <Typography variant="body1" className={classes.adminText}>
        {`Access to the administrative back end of the ChemoSafe application allows you to create, read, update, and
    delete questions in the assessment tool. Questions are coded with an ID number and at times have
    dependencies to other questions. You can navigate by choosing a section below or searching for the question
    via a set of filters. ${
      currentActiveRelease?.id
        ? `The most current question set was published as release ${currentActiveRelease.version} on ${formattedCurrentReleaseDate}.`
        : false
    }`}
      </Typography>
    </Box>
  )
}

export default DashboardHeader
