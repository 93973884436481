import React from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, Dispatch} from 'redux'
import {MODAL_NAMES} from '../../../../redux/modals/types'
import {ModalWrapper} from '../components'
import {delUser} from '../../../../redux/user/actions'
import {Button} from '../../../buttons'
import {delTokens} from '../../../../utils/tokens'
import {AppActions} from '../../../../redux'
import {Text} from '../../../index'
import st from './LogoffModal.module.scss'

interface OwnProps {
  activeModal: string
  closeModal: () => void
}

type Props = OwnProps & AppReduxInjectedProps

const LogoffModal = ({activeModal, closeModal, delUser}: Props) => {
  return (
    <ModalWrapper isOpen={activeModal === MODAL_NAMES.LOGOFF} closeModal={closeModal}>
      <div className={st.innerWrapper}>
        <Text className={st.logoffText}>Are you sure you want to Log off?</Text>
        <div className={st.buttonsBlock}>
          <Button
            label="Cancel"
            secondary
            className={st.logoffButton}
            onClick={() => {
              closeModal()
            }}
          />
          <Button
            label="Log Off"
            className={st.logoffButton}
            onClick={async () => {
              delUser()
              await delTokens()
              closeModal()
            }}
          >
            Log off
          </Button>
        </div>
      </div>
    </ModalWrapper>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) => bindActionCreators({delUser}, dispatch)

type AppReduxInjectedProps = ReturnType<typeof mapDispatchToProps>

export default connect(null, mapDispatchToProps)(LogoffModal)
