const sop = [
  {
    name: 'Hazardous Drug Designated Areas',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/HD_Designated_Areas_SOP.docx',
  },
  {
    name: 'Labeling and Storage of Hazardous Drugs',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/Labeling_and_Storage_SOP.docx',
  },
  {
    name: 'Receipt of Hazardous Drugs',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/Receipt_SOP.docx',
  },
  {
    name: 'Spill Control – Employee Decontamination ',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/Spill_Control_Employee_Decontamination_SOP.docx',
  },
  {
    name: 'Spill Control – Post Exposure Evaluation',
    url:
      'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/Spill_Control_Post_Exposure_Evaluation_SOP.docx',
  },
  {
    name: 'Transport of Hazardous Drugs',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/Transport_SOP.docx',
  },
]

export {sop}
