import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {useStyles} from '../../styles'
import {ChangeLog} from '../../../../../../../../../../../services/types'

interface QuestionProps {
  changelog: ChangeLog
}
interface QuestionTemplateProps {
  classes: any
  type?: string
  text?: string
  deleted?: boolean
}

const QuestionTemplate = ({text, classes, type, deleted}: QuestionTemplateProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {type && type} Question
      </Typography>
      {deleted ? (
        <Typography>Question is deleted</Typography>
      ) : (
        <Typography>{text ? text : 'No question text'}</Typography>
      )}
    </Box>
  )
}

const Question = ({changelog}: QuestionProps) => {
  const classes = useStyles()
  switch (changelog.type) {
    case 'ADD_QUESTION':
      return <QuestionTemplate classes={classes} text={changelog.newQuestion.questionText} />
    case 'EDIT_QUESTION_TEXT':
      return (
        <>
          <QuestionTemplate type="New" classes={classes} text={changelog.newQuestion.questionText} />
          <QuestionTemplate type="Old" classes={classes} text={changelog.oldQuestion.questionText} />
        </>
      )
    case 'DELETE_QUESTION':
      return (
        <>
          <QuestionTemplate type="New" classes={classes} deleted={true} />
          <QuestionTemplate type="Old" classes={classes} text={changelog.oldQuestion.questionText} />
        </>
      )
    default:
      return <QuestionTemplate classes={classes} text={changelog.newQuestion.questionText} />
  }
}
export default Question
