import React from 'react'
import {CSTProviders} from './context'
import CSTApp from './CSTApp'

const ChemoSafeTool = () => {
  return (
    <CSTProviders>
      <CSTApp />
    </CSTProviders>
  )
}

export default ChemoSafeTool
