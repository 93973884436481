import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  dialog: {
    backgroundColor: '#ddd',
    width: '60%',
    maxWidth: '1000px',
    border: '1px solid #eb7643',
    position: 'absolute',
    left: '30%',
    bottom: '5%',
    top: '15%',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
  },
  modalTitle: {
    fontWeight: 600,
    display: 'inline-block',
    marginRight: '15px',
  },
  questionInfo: {
    display: 'inline-block',
    width: '83%',
  },
  release: {
    display: 'inline-block',
    width: '17%',
  },
  modalHeader: {
    marginBottom: '20px',
  },
  modalBody: {},
  bodySection: {
    margin: '15px 0',
  },
  bodyTitle: {
    color: '#757575',
    textTransform: 'uppercase',
    marginBottom: '5px',
  },
})
