import cn from 'classnames'
import React from 'react'

import Text from '../Text'
import st from './ImageWithDescription.module.scss'

interface ImageWithDescriptionProps {
  className?: string
  description: string
  imageUrl: string
}

const ImageWithDescription = ({description, imageUrl, className}: ImageWithDescriptionProps) => {
  return (
    <div className={cn(st.container, className)}>
      <div className={st.imageContainer}>
        <img className={st.image} src={imageUrl} alt={description} />
      </div>
      <Text className={st.description}>{description}</Text>
    </div>
  )
}

export default ImageWithDescription
