import cn from 'classnames'
import React from 'react'

import st from './NavBar.module.scss'

interface NavBarProps {
  children?: React.ReactNode
  className?: string
}

const NavBar = ({children, className}: NavBarProps) => {
  return <nav className={cn(className || st.navBar)}>{children}</nav>
}

export default NavBar
