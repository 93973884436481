import {CLOSE_MODAL, ModalsActionTypes, ModalsState, SET_ACTIVE_MODAL} from './types'

const initialState: ModalsState = {
  activeModal: undefined,
}

export function modalsReducer(state = initialState, action: ModalsActionTypes): ModalsState {
  switch (action.type) {
    case SET_ACTIVE_MODAL:
      return {...state, activeModal: action.payload}
    case CLOSE_MODAL:
      return {...state, activeModal: undefined}
    default:
      return state
  }
}
