import React from 'react'
import {Redirect, Route} from 'react-router-dom'

interface Props {
  auth: boolean
  path: string
  Component: React.ComponentType
}

const PrivateRoute: React.FunctionComponent<Props> = ({auth, Component, path}) => {
  return auth ? <Route path={path} render={() => <Component />} /> : <Redirect to="/" />
}

export default PrivateRoute
