import cn from 'classnames'
import React from 'react'

import {ContentWrap, Text, Title} from '../../../components'
import st from './Colored.module.scss'

interface ColoredProps {
  children?: React.ReactNode
  className?: string
  color?: string
  bgImageUrl?: string
  style?: React.CSSProperties
}

const Colored = ({children, className, style, color, bgImageUrl}: ColoredProps) => {
  return (
    <div
      className={cn(st.colored, className)}
      style={{...style, ...{backgroundColor: `${color}`, backgroundImage: `url(${bgImageUrl})`}}}
    >
      <div className={st.overlay} style={{...{backgroundColor: `${'black'}`, opacity: `${bgImageUrl ? 0.6 : 0}`}}} />
      <ContentWrap className={st.content}>{children}</ContentWrap>
    </div>
  )
}

Colored.Title = ({className, ...attrs}: React.ComponentProps<typeof Title>) => {
  return <Title className={cn(className, st.title)} {...attrs} />
}

Colored.Text = ({className, emphasis, ...attrs}: React.ComponentProps<typeof Text>) => {
  return <Text className={cn(className, st.text, {[st.emphasis]: emphasis})} {...attrs} />
}

Colored.defaultProps = {
  color: '#6e0032',
  bgImageUrl: '',
}

export default Colored
