import React from 'react'

import st from './Card.module.scss'

interface CardProps {
  children?: React.ReactNode
  description: string | React.ReactNode
  imageUrl: string
}
const Card = ({description, imageUrl}: CardProps) => {
  return (
    <div className={st.card}>
      <div className={st.descriptionCol}>
        <div className={st.decsriptionInnerWrap}>{description}</div>
      </div>
      <div className={st.imgCol}>
        <div className={st.img} style={{...{content: `url(${imageUrl})`, backgroundColor: 'gray'}}} />
      </div>
    </div>
  )
}

export default Card
