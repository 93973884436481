import React, {useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Column, Columns, Descriptive, ImageItem, Link, List, PagePreview, Text, Title} from '../../components'
import st from './ChemoSafe.module.scss'
import {
  chemoSafeDescription,
  chemoSafeWorkDescription,
  literature,
  objectives,
  usefulLinks,
  latestTrainings,
  sop,
} from './constants'

enum Platforms {
  CHEMOSAFE = 'chemosafe',
  MOBILE = 'mobile',
}

const ChemoSafe = () => {
  const history = useHistory()
  const query = new URLSearchParams(useLocation().search)
  const platform = query.get('chemosafe')
  const isOpenNavigatorModal = query.get('isOpenNavigatorModal')
  const [currentPlatform, setCurrentPlatform] = useState(platform ? platform : 'mobile')
  const currentPlatformBlock = useRef<HTMLDivElement>(null)
  const scrollToPlatform = () => {
    setTimeout(
      () =>
        window.scrollTo({
          top: currentPlatformBlock.current?.offsetTop,
          left: 0,
          behavior: 'smooth',
        }),
      0,
    )
  }
  const handleClick = (name: Platforms) => () => {
    setCurrentPlatform(name)
    history.push(`/chemosafe?chemosafe=${name}`)
    scrollToPlatform()
  }
  platform && scrollToPlatform()
  return (
    <div className={st.chemoSafe}>
      <PagePreview
        title="Chemotherapy Safety and Administration"
        description="Working across the entire healthcare facility to improve safe handling and administration of chemotherapy."
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/chemosafe.jpeg`}
      />
      <div className={st.chemoSafeVideoContainer}>
        <Title type="h4">
          Listen to ChemoSafe participants talk about the importance of safe handling of hazardous medicines and
          resources you can use at your facility
        </Title>
        <iframe
          title="ChemoSafe Video"
          src="https://www.youtube.com/embed/5NU7H9TJ57U"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <Descriptive>
        <Text>{chemoSafeDescription}</Text>
        <Text>Objectives of the ChemoSafe Project are to:</Text>
        <List type="ol" styleType="decimal" items={objectives} />
        <Text>
          To date, facilities in Ethiopia, Uganda, Kenya, Tanzania, Rwanda, and Nigeria have engaged in the program.
        </Text>
      </Descriptive>
      <Descriptive>
        <Columns number="2">
          <Column>
            <Title type="h4">Download trainings</Title>
            <List type="ol" styleType="decimal" items={latestTrainings} links target="_self" />
            <Title type="h4">Download SOP templates</Title>
            <List type="ul" styleType="dash" items={sop} links target="_self" />
          </Column>
          <Column>
            <Title type="h4">Read literature and guidance</Title>
            <List type="ul" styleType="dash" items={literature} links target="_self" />
            <ImageItem path="./images/safety_and_administration/bitmap.jpg" className={st.readBlockImg} />
          </Column>
        </Columns>
      </Descriptive>
      <Descriptive id="mobile">
        <Columns number="2">
          <Column>
            <ImageItem path="./images/tools/chemosafe_xxxlarge.png" className={st.downloadBlockImgLeft} />
          </Column>
          <Column>
            <Title type="h3">Download our application</Title>
            <Text>{chemoSafeWorkDescription}</Text>
            <Link
              to="https://apps.apple.com/us/app/chemosafe/id1454417529"
              external
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageItem path="./images/safety_and_administration/appstore.png" className={st.storeImg} />
            </Link>
            <Link
              to="https://play.google.com/store/apps/details?id=org.cancer.chemosafe&hl=en_US"
              external
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageItem path="./images/safety_and_administration/googleplay.png" className={st.storeImg} />
            </Link>
          </Column>
        </Columns>
      </Descriptive>
      <Descriptive>
        <Title type="h4">Useful links</Title>
        <List type="ul" styleType="dash" items={usefulLinks} links />
      </Descriptive>
    </div>
  )
}

export default ChemoSafe
