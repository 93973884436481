import cn from 'classnames'
import React, {useState} from 'react'
import {Link, NavLink, RouteComponentProps, withRouter} from 'react-router-dom'
import {ContentWrap, LoginAndUserProfileBar, Logo, NavBar} from '../../index'
import {NavLinkListElement} from '../components'
import {BurgerButton, OurWorkSubmenu} from './components'
import st from './Header.module.scss'

interface HeaderProps extends RouteComponentProps<any> {
  openSideMenu: () => void
}

const Header = ({location, openSideMenu}: HeaderProps) => {
  const [showOurWorkSubmenu, setShowOurWorkSubmenu] = useState(false)
  const handleHoverOurWorkSubmenu = (condition: boolean) => () => {
    setShowOurWorkSubmenu(condition)
  }

  return (
    <header className={st.header}>
      <ContentWrap className={st.headerContentWrap}>
        <div className={st.mobileHeaderContent}>
          <BurgerButton onClick={openSideMenu} />
          <div className={st.mobileLogoWrapper}>
            <Link className={st.logoLink} to="/">
              <Logo />
            </Link>
          </div>
        </div>
        <div className={st.desktopHeaderContent}>
          <Link to="/">
            <Logo />
          </Link>
          <NavBar className={st.headerNavBar}>
            <ul className={st.headerLinkList}>
              <NavLinkListElement
                name="Home"
                exact
                to="/"
                listItemClassName={st.headerLinkListItem}
                navLinkClassName={st.headerNavLink}
                activeNavLinkClassName={st.active}
              />
              <NavLinkListElement
                name="Our Story"
                exact
                to="/our-story"
                listItemClassName={st.headerLinkListItem}
                navLinkClassName={st.headerNavLink}
                activeNavLinkClassName={st.active}
              />
              <li className={st.headerLinkListItem} onMouseLeave={handleHoverOurWorkSubmenu(false)}>
                <NavLink
                  onMouseEnter={handleHoverOurWorkSubmenu(true)}
                  onClick={handleHoverOurWorkSubmenu(false)}
                  to="/programs"
                  className={st.headerNavLink}
                  activeClassName={st.active}
                >
                  <span>Programs</span>
                </NavLink>
                {showOurWorkSubmenu && <OurWorkSubmenu onClick={handleHoverOurWorkSubmenu(false)} />}
              </li>
              <NavLinkListElement
                name="African Cancer Coalition"
                exact
                to="/coalition"
                listItemClassName={st.headerLinkListItem}
                navLinkClassName={st.headerNavLink}
                activeNavLinkClassName={st.active}
              />
            </ul>
          </NavBar>
          <div className={cn(st.headerNavLink)}>{!location.pathname.match('/login') && <LoginAndUserProfileBar />}</div>
        </div>
      </ContentWrap>
    </header>
  )
}

export default withRouter(Header)
