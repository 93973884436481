import cn from 'classnames'
import React from 'react'

import st from './Parted.module.scss'

interface PartedProps {
  children?: React.ReactNode
  description?: React.ReactNode
  imageUrl?: string
  reverse?: boolean
  className?: string
}

const Parted = ({description, imageUrl, reverse, className}: PartedProps) => {
  return (
    <div className={cn(st.parted, {[st.reverse]: reverse}, className)}>
      <div className={cn(st.descriptionCol, {[st.reverse]: reverse})}>
        <div className={st.decsriptionInnerWrap}>{description}</div>
      </div>
      <div className={st.imgCol}>
        <div className={st.img} style={{...{backgroundImage: `url(${imageUrl})`}}} />
      </div>
    </div>
  )
}

Parted.defaultProps = {}

export default Parted
