import cn from 'classnames'
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import st from './Tag.module.scss'

interface Props {
  name?: string
  color?: string
  onClose?: () => void
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

function Tag({name, color, onClose, onClick}: Props) {
  return (
    <div
      className={cn(st.tag, {[st.buttonStyle]: !onClose})}
      style={{...{backgroundColor: `${color}`}}}
      onClick={onClick}
    >
      <span className={st.tagText}>{name}</span>
      {onClose && (
        <div className={st.tagClose} onClick={onClose}>
          <img src={`${process.env.PUBLIC_URL}${'icons/cross.svg'}`} alt="" />
        </div>
      )}
    </div>
  )
}

Tag.defaultProps = {
  color: '#466441',
}

export default Tag
