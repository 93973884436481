import React from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Descriptive, Title, Text, Link} from '../../components'
import st from './notifications.module.scss'
interface TemplateComponentProps {
  buttonLabel: string
  buttonOnClick: () => void
  title: string
  Component: React.ComponentType
}
const TemplateComponent = ({buttonLabel, buttonOnClick, title, Component}: TemplateComponentProps) => (
  <div className={st.wholeMainContentWrapper}>
    <Descriptive>
      <div className={st.content}>
        <div className={st.descriptionContainer}>
          <Title className={st.title} type="h3">
            {title}
          </Title>
          <Component />
          <Button
            label={buttonLabel}
            onClick={() => {
              buttonOnClick()
            }}
          />
        </div>
      </div>
    </Descriptive>
  </div>
)
const EmailValidatedSuccess = () => {
  const history = useHistory()
  return (
    <TemplateComponent
      buttonLabel="Login"
      title="Thank you for verifying your email"
      Component={() => (
        <>
          <Text>Please log in now to get started.</Text>
          <Text>For access to ChemoSafe, please log in to the ChemoSafe app on your tablet or mobile.</Text>
        </>
      )}
      buttonOnClick={() => {
        history.push('/login')
      }}
    />
  )
}

const ResetPasswordSuccess = () => {
  const history = useHistory()
  return (
    <TemplateComponent
      buttonLabel="Go home"
      title="Congratulations"
      Component={() => <Text>Your password have been changed. You can login with new password</Text>}
      buttonOnClick={() => {
        history.push('/')
      }}
    />
  )
}

const EmailSentSuccess = () => {
  const history = useHistory()
  return (
    <TemplateComponent
      buttonLabel="Login"
      title="Thank you for registering"
      Component={() => (
        <>
          <Text>There are two steps to complete your account set-up:</Text>
          <Text>1) Please go to your email to verify your email account. </Text>
          <Text>2) Log in to Allied Against Cancer after verifying your email account.</Text>
        </>
      )}
      buttonOnClick={() => {
        history.push('/login')
      }}
    />
  )
}

export {ResetPasswordSuccess, EmailSentSuccess, EmailValidatedSuccess}
