import {User} from '../../services/types'
import {DEL_USER, SET_USER, UserActionTypes, REMOVE_USER_PROFILE_AVATAR} from './types'

export function setUser(user: Partial<User>): UserActionTypes {
  return {
    type: SET_USER,
    payload: user,
  }
}

export function removeUserProfileAvatar(): UserActionTypes {
  return {
    type: REMOVE_USER_PROFILE_AVATAR,
  }
}

export function delUser(): UserActionTypes {
  return {
    type: DEL_USER,
  }
}
