import cn from 'classnames'
import React, {useEffect, useState} from 'react'
import {Form as FormikForm, FormikProps, withFormik} from 'formik'
import {connect} from 'react-redux'
import Loader from 'react-loader-spinner'
import {RouteComponentProps, useHistory, useParams} from 'react-router-dom'
import {bindActionCreators, Dispatch} from 'redux'
import {Button, Checkbox, Descriptive, ImageLoader, Input, Text, TextArea, Title} from '../../../components'
import st from '../PublicProfile/PublicProfile.module.scss'
import {validator} from '../../../utils/validation/validator'
import {AppActions, AppState} from '../../../redux'
import {LoaderProps} from '../../../components/ImageLoader/ImageLoader'
import {MidtierService} from '../../../services'
import {setUser} from '../../../redux/user/actions'
import {addMessage} from '../../../redux/alerts/actions'
import generateId from '../../../utils/generateId'
import {setAdminEditUser} from '../../../redux/admin/actions'
import {USER_ROLES} from '../../../constants/userRoles'
import {PublicProfile as PublicProfileInterface, User} from '../../../services/types'

interface PublicProfileProps extends RouteComponentProps, AppReduxInjectedProps {}
const setFormikValues = (publicProfile?: PublicProfileInterface, userId?: string) => {
  return {
    name: publicProfile?.name || '',
    summary: publicProfile?.summary || '',
    interests: publicProfile?.interests || '',
    twitter: publicProfile?.twitter || '',
    linkedIn: publicProfile?.linkedIn || '',
    googleScholar: publicProfile?.googleScholar || false,
    imgUrl: publicProfile?.imgUrl || `${process.env.PUBLIC_URL}/images/avatars/not_found.png`,
    id: userId,
  }
}
// todo refactor this
// eslint-disable-next-line complexity,max-lines-per-function
const PublicProfile = (props: PublicProfileProps & FormikProps<PublicProfileInterface>) => {
  const {id} = useParams()
  const history = useHistory()
  const [anotherUser, setAnotherUser] = useState<User | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  props.status?.isBackToUserProfile === true && history.push(`/profile/adminArea/user/${anotherUser?.id}`)
  const getUser = async () => {
    try {
      if (id) {
        setIsLoading(true)
        const user = await MidtierService.getUserByIdWithAdminPermission(id)
        if (!user) {
          history.push('/404')
        }
        setAnotherUser({...user})
        props.setAdminEditUser(true)
        props.setValues({...setFormikValues(user.publicProfile, user.id)})
        setIsLoading(false)
      } else {
        props.setAdminEditUser(false)
      }
    } catch (e) {
      props.setAdminEditUser(false)
      history.push('/404')
    }
  }

  useEffect(() => {
    getUser()
    // eslint-disable-next-line
  }, [id])
  const submitForm = (event: {preventDefault: () => void}) => {
    event.preventDefault()
    props.submitForm()
  }

  const setImageUrl = (options: LoaderProps) => {
    if (!options.error && options.imageUrl) {
      props.setValues({...props.values, imgUrl: options.imageUrl})
    }
  }
  return (
    <div className={st.container}>
      {isLoading ? (
        <Loader className={st.spinner} type="TailSpin" color="#eb7346" height={300} width={300} visible={true} />
      ) : (
        <Descriptive>
          <div>
            <Title className={st.title}>
              {anotherUser ? `${anotherUser.firstName} Public Profile` : 'Public Profile'}
            </Title>
          </div>
          <h1 className={st.email}>{anotherUser && anotherUser.email}</h1>
          <Text>This is the data everyone will see when they search for you in our database.</Text>
          <FormikForm className={st.profileInfoForm}>
            <div className={cn(st.inputGroup)}>
              <Input
                id="name"
                value={props.values.name}
                onChange={props.handleChange}
                label="Profile name"
                placeholder="Name as it should appear on profile (Example: Thomas Smith, MD, MBA)"
                inputContainerClassName={st.inputContainer}
                error={(props.errors && props.touched.name && props.errors.name) || ''}
              />
            </div>
            <div className={cn(st.inputGroup)}>
              <TextArea
                id="summary"
                value={props.values.summary}
                onChange={props.handleChange}
                label="Professional Summary"
                placeholder="Provide a brief professional summary. (Max 300 characters)"
                textAreaContainerClassName={st.textAreaContainer}
                error={(props.errors && props.touched.summary && props.errors.summary) || ''}
              />
            </div>
            <div className={cn(st.inputGroup)}>
              <TextArea
                id="interests"
                value={props.values.interests}
                onChange={props.handleChange}
                label="Interests"
                placeholder="What would you like people to contact you about for collaboration? Please list here (example: specific research, clinical technique, collaboration on specific project) (max 200 characters)"
                textAreaContainerClassName={st.textAreaContainer}
                error={(props.errors && props.touched.interests && props.errors.interests) || ''}
              />
            </div>
            <div className={cn(st.inputGroup)}>
              <Input
                id="twitter"
                value={props.values.twitter}
                onChange={props.handleChange}
                label="Twitter"
                placeholder="Place here your Twitter profile link."
                inputContainerClassName={st.inputContainer}
                error={(props.errors && props.touched.twitter && props.errors.twitter) || ''}
              />
              <Input
                id="linkedIn"
                value={props.values.linkedIn}
                onChange={props.handleChange}
                label="LinkedIn"
                placeholder="Place here your Linkedin profile link."
                inputContainerClassName={st.inputContainer}
                error={(props.errors && props.touched.linkedIn && props.errors.linkedIn) || ''}
              />
            </div>
            <div className={st.checkBoxGroup}>
              <label className={st.singleLabel} htmlFor="googleScholar">
                Google Scholar
              </label>
              <div className={st.checkboxWrap}>
                <Checkbox
                  id="googleScholar"
                  checked={props.values.googleScholar}
                  onChange={props.handleChange}
                  label={
                    <span className={st.checkBoxLabel}>
                      By checking this box, you agree to let us post your publications on GoogleScholar on your public
                      profile.
                    </span>
                  }
                  inputContainerClassName={st.checkboxContainer}
                  error={(props.errors && props.touched.googleScholar && props.errors.googleScholar) || ''}
                />
              </div>
              <div className={st.uploadImageContainer}>
                <ImageLoader
                  className={st.imageLoader}
                  label="Profile Image"
                  imageUrl={props.values.imgUrl}
                  setImageUrl={setImageUrl}
                  userId={anotherUser?.id}
                />
              </div>
            </div>
            <div className={st.buttonsContainer}>
              <Button
                secondary
                label="Cancel"
                className={st.profileInfoFormButtons}
                to={props.isAdminEditUser ? `/profile/adminArea/user/${anotherUser?.id}` : '/profile/info'}
              />
              <Button
                type="submit"
                onClick={submitForm}
                label={
                  props.isSubmitting ? (
                    <Loader
                      className={st.spinner}
                      type="TailSpin"
                      color="white"
                      height={24}
                      width={24}
                      visible={true}
                    />
                  ) : (
                    'Save'
                  )
                }
                className={st.buttons}
                disabled={props.isSubmitting}
              />
            </div>
          </FormikForm>
        </Descriptive>
      )}
    </div>
  )
}

const WithFormik = withFormik<PublicProfileProps, PublicProfileInterface>({
  enableReinitialize: true,

  handleSubmit: async (values, {props, setSubmitting, setStatus}) => {
    const {name, summary, interests, twitter, linkedIn, googleScholar} = values
    const userId = values.id
    const allValues = {name, summary, interests, twitter, linkedIn, googleScholar}
    try {
      setSubmitting(true)
      const user = props.isAdminEditUser
        ? await MidtierService.updateUserByAdmin({
            publicProfile: allValues,
            userId,
          })
        : await MidtierService.updateUser({
            publicProfile: allValues,
          })
      props.isAdminEditUser === false && props.setUser(user)
      props.addMessage({message: 'Profile has been updated', id: generateId(), type: 'success'})
      props.setAdminEditUser(false)
      setSubmitting(false)
      props.isAdminEditUser && setStatus({isBackToUserProfile: true})
    } catch {
      setSubmitting(false)
    }
  },
  mapPropsToValues: ({publicProfile}) => {
    return setFormikValues(publicProfile)
  },

  validate: (values) => {
    return validator.rules({
      name: validator.validate(values.name).optional(),
      summary: validator.validate(values.summary).optional().maxLength(300, 'Maximum 300 characters are allowed'),
      interests: validator.validate(values.interests).optional().maxLength(200, 'Maximum 200 characters are allowed'),
      twitter: validator.validate(values.twitter).optional().url(),
      linkedIn: validator.validate(values.linkedIn).optional().url(),
    })
  },
})(PublicProfile)

const mapStateToProps = (state: AppState) => {
  return {
    isAdminEditUser: state.user?.roles.includes(USER_ROLES.ADMIN) && state.admin.isAdminEditUser,
    publicProfile: state.user?.publicProfile,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) =>
  bindActionCreators({setUser, addMessage, setAdminEditUser}, dispatch)
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
export default connect(mapStateToProps, mapDispatchToProps)(WithFormik)
