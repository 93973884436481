import React from 'react'

import positions from '../../../../../../constants/positions'
import countries from '../../../../../../constants/countries'
import {User} from '../../../../../../services/types'
import st from './MemberCards.module.scss'
import MemberCard from './MemberCard'

interface Props {
  membersList: Array<User>
}

const MemberCards: React.FunctionComponent<Props> = ({membersList}) => {
  return (
    <div className={st.memberCards}>
      {membersList.map((member) => {
        const {id, firstName, lastName, position, country, institution} = member
        return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <div
            key={id}
            className={st.memberCardWrap}
            onClick={() => {
              // todo this is temporary AACWEB-349
              //history.push(`/user/${id}`)
            }}
          >
            <MemberCard
              name={`${firstName} ${lastName}`}
              // todo temporary solution for newMember.ts delete || country / || positions
              position={positions.find((positionFromArray) => positionFromArray.value === position)?.name || position}
              country={countries.find((countryFromArray) => countryFromArray.value === country)?.name || country}
              institution={institution}
              imgUrl={member.publicProfile?.imgUrl}
              id={id}
            />
          </div>
        )
      })}
    </div>
  )
}

MemberCards.defaultProps = {
  membersList: [],
}

export default MemberCards
