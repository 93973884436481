import React from 'react'
import {Descriptive, Title} from '../../../../components'
import {SmallForms, FormsFlow} from '../components'
import st from './ChangePassword.module.scss'

const ChangeEmail = () => {
  return (
    <Descriptive>
      <Title className={st.title}>Change Password</Title>
      <FormsFlow
        ConfirmUserForm={SmallForms.ConfirmUser}
        MainForm={SmallForms.ChangePassword}
        ApprovedDialog={SmallForms.ApprovedDialog}
        approvedText="Your password has been changed."
      />
    </Descriptive>
  )
}

export default ChangeEmail
