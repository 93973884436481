import {useLocation} from 'react-router-dom'
import {useEffect} from 'react'
import {seotags} from '../../../constants/seotags'

// TODO: Rename
const usePageViews = () => {
  const location = useLocation()
  const replaceElementById = (id: string, tagType: string) => {
    const element = document.getElementById(id)
    if (element) element.remove()
    const newElement = document.createElement(tagType)
    newElement.id = id
    return newElement
  }
  useEffect(() => {
    const addCanonicalLink = (path: string) => {
      const canonicalLink = replaceElementById('canonical', 'link')
      canonicalLink.setAttribute('rel', 'canonical')
      canonicalLink.setAttribute(
        'href',
        path === '/' ? `https://alliedagainstcancer.org` : `https://alliedagainstcancer.org${path}.html`,
      )
      document.getElementsByTagName('head')[0].appendChild(canonicalLink)
    }
    const addDescription = (path: string) => {
      const descriptionMeta = replaceElementById('description', 'meta')
      descriptionMeta.setAttribute('name', 'description')
      descriptionMeta.setAttribute(
        'content',
        path === '/' ? seotags.home.descriptionContent : seotags[path.substring(1)].descriptionContent,
      )
      document.getElementsByTagName('head')[0].appendChild(descriptionMeta)
    }

    const addTitle = (path: string) => {
      const title = replaceElementById('title', 'title')
      title.innerText = path === '/' ? seotags.home.title : seotags[path.substring(1)].title
      document.getElementsByTagName('head')[0].appendChild(title)
    }

    const routeHandler = (path: string) => {
      if (path === '/' || Object.keys(seotags).indexOf(path.substring(1)) !== -1) {
        addDescription(path)
        addTitle(path)
      }
      addCanonicalLink(path)
    }
    routeHandler(location.pathname)
  }, [location])
}

export default usePageViews
