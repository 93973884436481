import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  'add-question-select': {
    marginTop: '10px',
    marginBottom: '5px',
    width: '100%',
    '& select': {
      padding: '10px !important',
    },
  },
}))
