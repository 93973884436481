import React from 'react'

import {useDispatch, useSelector} from 'react-redux'
import {Descriptive, Title, Link} from '../../../components'
import {MidtierService} from '../../../services'
import {setUser} from '../../../redux/user/actions'
import {AppState} from '../../../redux'
import st from './AccountSettings.module.scss'

const AccountSettingsMenu = () => {
  const user = useSelector((state: AppState) => state.user)
  const dispatch = useDispatch()

  const sendChemoquantRequest = () => {
    MidtierService.sendRequestToChemoquantAccess().then((x) => {
      dispatch(setUser({isRequestAccessToChemoquant: true}))
    })
  }

  if (!user) return null

  return (
    <div>
      <ul>
        <li className={st.linkListItem}>
          {user.roles.includes('chemoquant admin') ? (
            <div />
          ) : user.isRequestAccessToChemoquant ? (
            <div className={st.text}>Your request to ChemoQuant was successfully sent!</div>
          ) : (
            <button className={st.button} onClick={sendChemoquantRequest}>
              Request Access to ChemoQuant{' '}
            </button>
          )}
        </li>
        <li className={st.linkListItem}>
          <Link className={st.link} to="/profile/settings/changeEmail">
            Change Email{' '}
            <img className={st.arrow} src={`${process.env.PUBLIC_URL}${'../icons/arrow-black.svg'}`} alt="" />
          </Link>
        </li>
        <li className={st.linkListItem}>
          <Link className={st.link} to="/profile/settings/changePassword">
            Change Password{' '}
            <img className={st.arrow} src={`${process.env.PUBLIC_URL}${'../icons/arrow-black.svg'}`} alt="" />
          </Link>
        </li>
        <li className={st.linkListItem}>
          <Link className={st.link} to="/profile/settings/deleteAccount">
            Delete Account{' '}
            <img className={st.arrow} src={`${process.env.PUBLIC_URL}${'../icons/arrow-black.svg'}`} alt="" />
          </Link>
        </li>
      </ul>
    </div>
  )
}

const AccountSettings = () => {
  return (
    <>
      <Descriptive>
        <Title>Account Settings</Title>
      </Descriptive>
      <Descriptive>
        <AccountSettingsMenu />
      </Descriptive>
    </>
  )
}

export default AccountSettings
