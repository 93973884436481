import React from 'react'
import Tree, {RenderItemParams, TreeData, TreeSourcePosition, TreeDestinationPosition} from '@atlaskit/tree'
import Question from './components/Question'
import {useStyles, PADDING_PER_LEVEL} from './styles'
import {getDisplayNumber} from './utils'

export interface DashboardProps {
  treeStructure: TreeData
  onDragEnd: (source: TreeSourcePosition, destination?: TreeDestinationPosition) => void
  expandedQuestion: string | null
  editableQuestion: string | null
  onToggleQuestion: (id: string, isExpanded: boolean) => void
  onRemoveQuestion: (id: string) => void
  onEditQuestion: (id: string | null) => void
}

const Dashboard = ({
  treeStructure,
  onDragEnd,
  expandedQuestion,
  editableQuestion,
  onToggleQuestion,
  onRemoveQuestion,
  onEditQuestion,
}: DashboardProps) => {
  const classNames = useStyles()

  const handleDragEnd = (source: TreeSourcePosition, destination?: TreeDestinationPosition) => {
    onDragEnd?.(source, destination)
  }

  const renderTreeItem = ({item, provided, snapshot}: RenderItemParams) => {
    const {
      data: {id, conditionalPredicate},
    } = item

    const isShowInAssessmentDisabled = conditionalPredicate
      ? !treeStructure.items[conditionalPredicate?.value.questionId].data?.showInAssessment
      : false

    return (
      <div ref={provided.innerRef} {...provided.draggableProps} className={classNames['question-wrapper']}>
        <Question
          key={id}
          data={item.data}
          displayNumber={getDisplayNumber(treeStructure, item.data.id) || 'uknown'}
          toggleShowInAssessmentDisabled={isShowInAssessmentDisabled}
          isExpanded={id === expandedQuestion}
          isDragging={snapshot.isDragging}
          dragHandleProps={provided.dragHandleProps}
          disableToggle={id === editableQuestion || !!editableQuestion}
          onToggle={onToggleQuestion}
          onRemove={onRemoveQuestion}
          onEdit={onEditQuestion}
          mode={id === editableQuestion ? 'edit' : 'display'}
        />
      </div>
    )
  }

  return (
    <Tree
      tree={treeStructure}
      renderItem={renderTreeItem}
      onDragEnd={handleDragEnd}
      offsetPerLevel={PADDING_PER_LEVEL}
      isDragEnabled={!editableQuestion}
      isNestingEnabled
    />
  )
}

export default Dashboard
