import {AdminActionTypes, AdminState, DEL_USERS, SET_ADMIN_EDIT_USER, SET_COALITION_REQUESTS, SET_USERS} from './types'

const initialState: AdminState = {
  users: [],
  coalitionRequests: 0,
  isAdminEditUser: false,
}

export function adminReducer(state = initialState, action: AdminActionTypes): AdminState {
  switch (action.type) {
    case SET_USERS:
      return {...state, users: [...action.payload]}
    case DEL_USERS:
      return {...state, users: []}
    case SET_COALITION_REQUESTS:
      return {...state, coalitionRequests: action.payload}
    case SET_ADMIN_EDIT_USER:
      return {...state, isAdminEditUser: action.payload}
    default:
      return state
  }
}
