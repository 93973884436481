import cn from 'classnames'
import React from 'react'

import st from './Columns.module.scss'

interface ColumnsProps {
  children?: React.ReactNode
  number?: string | number
}

const Columns = ({children, number}: ColumnsProps) => {
  const n = +(number || 1)
  const num = n === 1 ? 'one' : n === 2 ? 'two' : n === 3 ? 'three' : n === 4 ? 'four' : n === 5 ? 'five' : 'one'
  return <div className={cn(st.columns, st[`${num}`])}>{children}</div>
}

Columns.defaultProps = {
  number: 1,
}

export default Columns
