import React from 'react'
import {connect} from 'react-redux'
import Text from '../../../../components/Text'
import Title from '../../../../components/Title'
import {Button, Link, Parted} from '../../../../components'
import {AppState} from '../../../../redux'
import {MODAL_NAMES} from '../../../../redux/modals/types'
import {ModalWrapper} from '../components'
import st from './LoginOrSignupModal.module.scss'

interface CloseButtonProps {
  close: () => void
}

interface OwnProps {
  activeModal: string
  closeModal: () => void
}

const CloseButton = ({close}: CloseButtonProps) => {
  return (
    <button
      className={st.buttonClose}
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        close()
      }}
    >
      <img src={`${process.env.PUBLIC_URL}${'icons/black-cross.svg'}`} alt="close" />
    </button>
  )
}

const LoginOrSignupModal = ({activeModal, user, closeModal}: AppReduxInjectedProps & OwnProps) => {
  return (
    <ModalWrapper isOpen={activeModal === MODAL_NAMES.NAVIGATOR} closeModal={closeModal}>
      <CloseButton close={closeModal} />
      <Parted
        reverse
        imageUrl="./images/guidelines/modal.jpg"
        description={
          <div className={st.modalContent}>
            <Title type="h4">Log in or create an account to get access to Cancer Guidelines Navigator. </Title>
            <Text>
              Please note: Cancer Guidelines Navigator is only for use by cancer-treating clinicians in Sub-Saharan
              Africa.* For more information, read the Terms of Use{' '}
              <Link to="https://ibm.box.com/s/7qfb3d8eimsw7nxxucsc98dlu3dueo27" external target="_blank">
                {' '}
                here{' '}
              </Link>
            </Text>
            <Text>*South Africa is excluded at this time.</Text>
            <div className={st.buttonsBlock}>
              <Button
                label="Log in"
                to="https://che-gateway.watsonplatform.net/watson-oncology/cancerguidelinesnavigator/wdao"
                external
                target="_blank"
              />
              {user === null && <Button label="Sign up" to="/sign-up" secondary />}
            </div>
          </div>
        }
      />
    </ModalWrapper>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  }
}

type AppReduxInjectedProps = ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps)(LoginOrSignupModal)
