import {ADD_MESSAGE, DEL_MESSAGE, AlertsActionTypes, AlertsState} from './types'

export const initialState: AlertsState = []

export function alertsReducer(state = initialState, action: AlertsActionTypes): AlertsState {
  switch (action.type) {
    case ADD_MESSAGE:
      return [...state, action.payload]
    case DEL_MESSAGE:
      return state.filter((error) => error.id !== action.payload.id)
    default:
      return state
  }
}
