import React from 'react'
import {Box, Typography} from '@material-ui/core'
import {ChangeLog} from '../../../../../../../../../../../services/types'

interface ActionPlanTypeProps {
  changelog: ChangeLog
  classes: any
}

interface ActionPlanTypeTemplateProps {
  classes: any
  text?: string
  type?: string
}

const ActionPlanTypeTemplate = ({classes, text, type}: ActionPlanTypeTemplateProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {type && type} Action Plan type
      </Typography>
      <Typography>{text ? text : 'No Action Plan type'}</Typography>
    </Box>
  )
}
const ActionPlanType = ({changelog, classes}: ActionPlanTypeProps) => {
  switch (changelog.type) {
    case 'CHANGE_ACTION_PLAN_TYPE':
      return (
        <>
          <ActionPlanTypeTemplate classes={classes} text={changelog.oldQuestion.planType} type="Old" />
          <ActionPlanTypeTemplate classes={classes} text={changelog.newQuestion.planType} type="New" />
        </>
      )
    case 'DELETE_QUESTION':
      return <ActionPlanTypeTemplate classes={classes} text={changelog.oldQuestion.planType} type="Old" />
    default:
      return <ActionPlanTypeTemplate classes={classes} text={changelog.newQuestion.planType} />
  }
}
export default ActionPlanType
