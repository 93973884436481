import React, {useRef, useEffect, FunctionComponent} from 'react'

import st from './DropdownContainer.module.scss'

interface Props {
  closeDropdown: () => void
  className?: string
}

const DropdownContainer: FunctionComponent<Props> = ({closeDropdown, className, children}) => {
  const dropdownContainerRef = useRef<HTMLInputElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(event.target as Node)) {
      closeDropdown()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  return (
    <div className={className} ref={dropdownContainerRef}>
      <div className={st.dropdown}>{children}</div>
    </div>
  )
}

export default DropdownContainer
