import React from 'react'
import {connect} from 'react-redux'
import cn from 'classnames'
import {AppState} from '../../redux'
import st from './Alerts.module.scss'

const Alert = ({type, message}: {type: string; message: string}) => {
  return (
    <div className={cn(st.error, st[type])}>
      <p className={st.errorText}>{message}</p>
    </div>
  )
}

const Alerts = ({messages}: AppReduxInjectedProps) => {
  return messages.length > 0 ? (
    <div className={st.alertsContainer}>
      {messages.map((message, index) => {
        const lastMessage = messages[messages.length - (index + 1)]
        switch (lastMessage.type) {
          case 'error':
            return <Alert key={lastMessage.id} type="danger" message={lastMessage.message} />
          case 'success':
            return <Alert key={lastMessage.id} type="success" message={lastMessage.message} />
          default:
            return null
        }
      })}
    </div>
  ) : null
}

const mapStateToProps = (state: AppState) => {
  return {
    messages: state.alerts,
  }
}
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps)(Alerts)
