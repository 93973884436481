import React, {ChangeEvent} from 'react'
import {Input} from '../../../../../components'
import st from './GlobalSearch.module.scss'

interface GlobalSearchProps {
  setGlobalSearch: (str: string) => void
  globalSearch: string
}

const GlobalSearch = ({setGlobalSearch, globalSearch}: GlobalSearchProps) => {
  const changeHandler = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.value === '') {
      setGlobalSearch('')
    }
    setGlobalSearch(e.target.value)
  }

  return (
    <Input
      inputContainerClassName={st.search}
      onChange={changeHandler}
      value={globalSearch}
      placeholder="Type for search..."
    />
  )
}

export default GlobalSearch
