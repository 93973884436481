import React, {ReactNode} from 'react'
import {ThemeProvider} from '@material-ui/core'
import customMuiTheme from '../theme'
import ChemoSafeToolProvider from './chemosafeToolContext'
import {ConfirmationServiceProvider} from './confirmationServiceContext'

interface AppProvidersProps {
  children: ReactNode
}

function CSTProviders({children}: AppProvidersProps) {
  return (
    <ThemeProvider theme={customMuiTheme}>
      <ConfirmationServiceProvider>
        <ChemoSafeToolProvider>{children} </ChemoSafeToolProvider>
      </ConfirmationServiceProvider>
    </ThemeProvider>
  )
}

export {CSTProviders}
