export const questions = [
  {},
  {
    question: 'How can I purchase products under this agreement? Do I need to buy all of the products?',
    answer:
      'Products are available to purchasers who provide high quality cancer treatment, such as governments and hospitals, who are committed to passing savings on to patients. It is not necessary to purchase the entire portfolio via CAP - purchasers can choose the products that provide the best value for their own programs. To learn more, please contact us using this ',
    linkTitle: 'form.',
    link: {
      to: '/contact-us',
    },
  },
  {
    question: 'How much could I save?',
    answer:
      'Through this partnership, companies have individually committed to provide governments and hospitals with substantial savings on essential cancer treatments while improving the availability of quality treatment. Each partner will liaise with its distributors to limit the markups applied between seller and buyer. Therefore, the savings to purchasers is a combination of access prices, more buying choices, and reduced charges from in-country distributors. The exact savings to be gained will vary depending on context, but are estimated to average 60 percent, compared to past prices in sub-Saharan Africa.',
  },
  {
    question: 'If these prices are higher than what I can access in my country, is there room to negotiate further?',
    answer:
      'These prices have been developed to make them comparable with other products of similar quality. While the pricing may not be the same as the products in your country, their quality is guaranteed. To note, there might be room to negotiate prices further if the volumes being procured are substantial.',
  },  
  {
    question: 'Will product be donated? Can individual patients buy product?',
    answer:
      'No, the CAP does not provide any donated product or support individual patients. All products must be purchased from the companies through institutions with appropriate pharmacy licenses. Patients can buy through their hospitals while governments will buy either from the distributors or directly from the partner companies, once they meet certain criteria.',
  },
  {
    question: 'How can I find out what prices are charged for the medications included?',
    answer: [
      'If you represent an institution and you would like to know the pricing, you can request a quotation for specific products in specific countries. Please contact us using this ',
      ' All prices provided are confidential.',
    ],
    linkTitle: 'form.',
    link: {
      to: '/contact-us',
    },
  },
  {
    question:
      'What is the relationship between the Cancer Access Partnership and the former Chemotherapy Access Partnership?',
    answer: [
      'The Cancer Access Partnership was ',
      ' and expands the reach of the ',
      ' launched in 2017 by including a broader array of products, new pharmaceutical company partners, and serving additional countries. With this new suite of offerings, we also renew our commitment to effectively serving the purchasers of quality chemotherapies, including by learning from our prior experience and feedback received from purchasers, oncologists, and patients.',
    ],
    links: [
      {
        linkTitle: 'announced in June 2020',
        link: {
          to: 'https://www.clintonhealthaccess.org/agreements-to-increase-access-to-cancer-treatment-in-africa',
          external: true,
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
      {
        linkTitle: 'Chemotherapy Access Partnership',
        link: {
          to: 'https://www.nytimes.com/2017/10/07/health/africa-cancer-drugs.html',
          external: true,
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
    ],
  },
  {
    question:
      'What is the relationship between the Cancer Access Partnership and the Nigeria Chemotherapy Access Partnership (N-CAP)?',
    answer:
      'N-CAP is an initiative of the Federal Ministry of Health in Nigeria to provide quality medicines to cancer patients at designated cancer treatment centers. Some of the products provided through the N-CAP in hospitals designated by the FMOH are part of the Cancer Access Partnership; all products provided through N-CAP meet either the country or CAP standards of quality and affordability. For more information, please see ',
    linkTitle: 'here.',
    link: {
      to:
        'https://www.clintonhealthaccess.org/nigeria-launches-public-private-partnership-to-ensure-affordable-access-to-high-quality-chemotherapies-at-seven-teaching-hospitals/',
      external: true,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    question: 'Is Allied Against Cancer looking at expanding this work with other companies or other products?',
    answer:
      'Yes. Allied Against Cancer plans to continue to pursue additional market access collaborations with other companies as well as looking at other medicines, supplies, and equipment. Companies that are interested in partnering with us are invited to contact us via this ',
    linkTitle: 'form.',
    link: {
      to: '/contact-us',
    },
  },
]
