import cn from 'classnames'
import React, {ChangeEvent} from 'react'
import {FormikErrors} from 'formik'
import {ErrorMessage} from '../../index'
import st from '../inputs.module.scss'

interface InputProps {
  className?: string
  inputContainerClassName?: string
  id?: string
  label?: string
  labelClassName?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: string | FormikErrors<any> | string[] | FormikErrors<any>[] | undefined
  leftIcon?: JSX.Element
  rightIcon?: JSX.Element
  placeholder?: string
  value?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  type?: string
  disabled?: boolean
}

const Input = ({
  className,
  value,
  onChange,
  onBlur,
  inputContainerClassName,
  label,
  error,
  id,
  leftIcon,
  rightIcon,
  type,
  disabled,
  labelClassName,
  ...attrs
}: InputProps) => {
  return (
    <div className={cn(st.commonContainer, inputContainerClassName)}>
      {label && (
        <label htmlFor={id} className={cn(st.label, labelClassName)}>
          {label}
        </label>
      )}
      <div className={st.commonWrap}>
        {leftIcon && <div className={st.leftIconWrap}>{leftIcon}</div>}
        <input
          type={type || 'text'}
          className={cn(st.common, className)}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          name={id}
          id={id}
          disabled={disabled || false}
          {...attrs}
        />
        {rightIcon && <div className={st.rightIconWrap}>{rightIcon}</div>}
      </div>
      {error && <ErrorMessage error={error} />}
    </div>
  )
}

export default Input
