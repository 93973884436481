import cn from 'classnames'
import React from 'react'
import {NavLink, Route, Switch, Redirect} from 'react-router-dom'

import {connect} from 'react-redux'
import {ContentWrap, NavBar, OpenModalButton, PrivateRoute, SideBar, Title} from '../../components'
import {AppState} from '../../redux'
import {USER_ROLES} from '../../constants/userRoles'
import NotFound from '../NotFound'
import {UserPublicProfile} from '../index'
import {MODAL_NAMES} from '../../redux/modals/types'
import {AccountSettings, AdminArea, ProfileInformation, PublicProfile} from './'
import st from './MyProfile.module.scss'
import {ChangeEmail, ChangePassword, DeleteAccount} from './AccountSettings'
interface SwitchRouterProps {
  isAdmin: boolean
  isChemosafeToolAdmin: boolean
}
const SwitchRouter = ({isAdmin, isChemosafeToolAdmin}: SwitchRouterProps) => (
  <Switch>
    <Redirect exact path="/profile" to="/profile/info" />
    <Route path="/profile/info" component={ProfileInformation} />
    <Route path="/profile/public" component={PublicProfile} />
    <Route exact path="/profile/settings" component={AccountSettings} />
    <Route path="/profile/settings/changeEmail" component={ChangeEmail} />
    <Route path="/profile/settings/changePassword" component={ChangePassword} />
    <Route path="/profile/settings/deleteAccount" component={DeleteAccount} />
    <Route path="/profile/settings" component={AccountSettings} />
    <PrivateRoute auth={isAdmin} path="/profile/adminArea/publicProfile/:id" Component={PublicProfile as any} />
    <PrivateRoute auth={isAdmin} path="/profile/adminArea/user/:id" Component={UserPublicProfile} />
    <PrivateRoute auth={isAdmin} path="/profile/adminArea" Component={AdminArea} />
    <Route component={NotFound} />
  </Switch>
)

const MyProfile = ({user}: AppReduxInjectedProps) => {
  const isAdmin = user !== null && user.roles.includes(USER_ROLES.ADMIN)
  const isChemosafeToolAdmin = user !== null && user?.roles.includes(USER_ROLES.CHEMOSAFE_TOOL_ADMIN)
  const isChemoquantAdmin = user !== null && user.roles.includes(USER_ROLES.CHEMOQUANT_ADMIN)

  const chemoquantUrl = process.env.REACT_APP_CHEMOQUANT_URI
  const dataExtractionPageUrl = process.env.REACT_APP_DATA_EXTRACTION_URI

  return (
    <div className={st.myProfile}>
      <ContentWrap className={st.contentWrap}>
        <SideBar className={st.sideBar}>
          <Title type="h1" className={st.myProfileHeader}>
            My Profile
          </Title>
          <NavBar>
            <NavLink exact to="/profile/info" className={st.profileNavLink} activeClassName={st.active}>
              <span>Profile Information</span>
            </NavLink>
            <NavLink to="/profile/public" className={st.profileNavLink} activeClassName={st.active}>
              <span>Public Profile</span>
            </NavLink>
            <NavLink to="/profile/settings" className={st.profileNavLink} activeClassName={st.active}>
              <span>Account Settings</span>
            </NavLink>
            {isAdmin && (
              <NavLink to="/profile/adminArea" className={st.profileNavLink} activeClassName={st.active}>
                <span>Admin Area</span>
              </NavLink>
            )}
            {isChemosafeToolAdmin && (
              <NavLink to="/chemosafe-tool" className={st.profileNavLink} activeClassName={st.active}>
                <span>Chemosafe Tool</span>
              </NavLink>
            )}
            {isChemosafeToolAdmin && (
              <a target="_blank" rel="noopener noreferrer" href={dataExtractionPageUrl} className={st.profileNavLink}>
                <span>ChemoSafe Data Extraction Page</span>
              </a>
            )}
            {isChemoquantAdmin && chemoquantUrl && (
              <a target="_blank" rel="noopener noreferrer" href={chemoquantUrl} className={st.profileNavLink}>
                <span>Chemoquant app</span>
              </a>
            )}
            <OpenModalButton
              className={cn(st.profileNavLink, st.logOffButton)}
              text="Log off"
              modalName={MODAL_NAMES.LOGOFF}
            />
          </NavBar>
        </SideBar>
        <div className={st.myProfileBlocks}>
          <SwitchRouter isAdmin={isAdmin} isChemosafeToolAdmin={isChemosafeToolAdmin} />
        </div>
      </ContentWrap>
    </div>
  )
}

MyProfile.Title = ({className, ...attrs}: React.ComponentProps<typeof Title>) => {
  return <Title type="h1" className={cn(className, st.title)} {...attrs} />
}
const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  }
}
type AppReduxInjectedProps = ReturnType<typeof mapStateToProps>
export default connect(mapStateToProps)(MyProfile)
