import React from 'react'

const BellIconWithNotifications = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Actions-/-Toggle-/-notification--new-/-24">
        <path
          d="M19.5 13.2L19.5 11.25L18 11.25L18 13.5C17.9946 13.6995 18.0769 13.8913 18.225 14.025L20.25 16.05L20.25 17.25L3.75 17.25L3.75 16.05L5.775 14.025C5.92314 13.8913 6.00536 13.6995 6 13.5L6 9.75C6 6.43629 8.68629 3.75 12 3.75C13.055 3.7502 14.0908 4.03245 15 4.5675L15 2.85C14.287 2.53061 13.5273 2.32802 12.75 2.25L12.75 0.75L11.25 0.75L11.25 2.25C7.42833 2.68567 4.53209 5.90371 4.5 9.75L4.5 13.2L2.475 15.225C2.32686 15.3587 2.24464 15.5505 2.25 15.75L2.25 18C2.23659 18.2027 2.31129 18.4014 2.45495 18.545C2.59862 18.6887 2.79727 18.7634 3 18.75L8.25 18.75C8.25 20.8211 9.92893 22.5 12 22.5C14.0711 22.5 15.75 20.8211 15.75 18.75L21 18.75C21.2027 18.7634 21.4014 18.6887 21.545 18.545C21.6887 18.4014 21.7634 18.2027 21.75 18L21.75 15.75C21.7554 15.5505 21.6731 15.3587 21.525 15.225L19.5 13.2ZM22.5 6C22.5 7.65685 21.1569 9 19.5 9C17.8431 9 16.5 7.65685 16.5 6C16.5 4.34315 17.8431 3 19.5 3C21.1569 3 22.5 4.34315 22.5 6ZM12 21C10.7574 21 9.75 19.9926 9.75 18.75L14.25 18.75C14.25 19.9926 13.2426 21 12 21Z"
          id="Fill"
          fill="#000000"
          fillRule="evenodd"
          stroke="none"
        />
      </g>
    </svg>
  )
}

export default BellIconWithNotifications
