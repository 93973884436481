/* eslint-disable max-lines-per-function */
import React, {useState} from 'react'
import {NavLink} from 'react-router-dom'
import cn from 'classnames'
import {Link, OpenModalButton} from '../../../../index'
import {NavLinkListElement} from '../../../components'
import {MODAL_NAMES} from '../../../../../redux/modals/types'
import st from './MenuContent.module.scss'
import {Collapsed} from './components'
interface MenuContentProps {
  isAuth: boolean
  isChemosafeToolAdmin: boolean
  isAdmin: boolean
  closeSideMenu: () => void
}

const MenuContent = ({closeSideMenu, isAdmin, isAuth, isChemosafeToolAdmin}: MenuContentProps) => {
  const [openings, setOpenings] = useState<{[index: string]: boolean}>({
    isOurWorkMenuOpened: false,
    isProfileMenuOpened: false,
  })
  const handleCollapsedExcept = (name: 'isOurWorkMenuOpened' | 'isProfileMenuOpened' | '') => {
    const newState = Object.keys(openings).reduce<{isOurWorkMenuOpened?: boolean; isProfileMenuOpened?: boolean}>(
      (acc, item) => {
        return {...acc, [item]: item === name ? !openings[name] : false}
      },
      {},
    )
    // @ts-ignore
    setOpenings(newState)
  }
  const handleClickOnListItem = () => {
    closeSideMenu()
    handleCollapsedExcept('')
  }
  return (
    <ul className={st.list}>
      <NavLinkListElement to="/" name="Home" exact onClick={handleClickOnListItem} />
      <NavLinkListElement to="/our-story" name="Our Story" exact onClick={handleClickOnListItem} />
      <li className={st.listItemCollapsible}>
        <Collapsed
          isOpen={openings.isOurWorkMenuOpened}
          triggerComponent={
            <NavLink
              className={st.navLinkTriggerText}
              to="/programs"
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              Programs
            </NavLink>
          }
          onClick={() => {
            handleCollapsedExcept('isOurWorkMenuOpened')
          }}
          triggerClassName={st.navLinkTrigger}
          triggerOpenedClassName={st.navLinkTriggerOpened}
          contentOuterClassName={st.collapsibleContentOuter}
        >
          <ul>
            <NavLinkListElement
              to="/guidelines"
              name="NCCN Harmonized Guidelines™ for Sub-Saharan Africa"
              exact
              onClick={closeSideMenu}
              isSubLink
            />
            <NavLinkListElement
              to="/chemosafe"
              name="Safe handling of chemotherapy"
              exact
              onClick={closeSideMenu}
              isSubLink
            />
            <NavLinkListElement
              to="/access-partnership"
              name="Cancer Access Partnership"
              exact
              onClick={closeSideMenu}
              isSubLink
            />
            <NavLinkListElement to="/pain-management" name="Pain management" exact onClick={closeSideMenu} isSubLink />
          </ul>
        </Collapsed>
      </li>
      <NavLinkListElement to="/coalition" name="African Cancer Coalition" exact onClick={handleClickOnListItem} />
      {isAuth ? (
        <li className={st.listItemCollapsible}>
          <Collapsed
            isOpen={openings.isProfileMenuOpened}
            triggerComponent={
              <Link
                className={st.navLinkTriggerText}
                to="/profile"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                Profile
              </Link>
            }
            onClick={() => {
              handleCollapsedExcept('isProfileMenuOpened')
            }}
            triggerClassName={st.navLinkTrigger}
            triggerOpenedClassName={st.navLinkTriggerOpened}
            contentOuterClassName={st.collapsibleContentOuter}
          >
            <ul>
              <NavLinkListElement
                to="/profile/info"
                name="Profile Information"
                exact
                onClick={closeSideMenu}
                isSubLink
              />
              <NavLinkListElement to="/profile/public" name="Public Profile" exact onClick={closeSideMenu} isSubLink />
              <NavLinkListElement
                to="/profile/settings"
                name="Account Settings"
                exact
                onClick={closeSideMenu}
                isSubLink
              />
              {isAdmin && (
                <NavLinkListElement to="/profile/adminArea" name="Admin Area" exact onClick={closeSideMenu} isSubLink />
              )}
              {isChemosafeToolAdmin && (
                <NavLinkListElement
                  to="/chemosafe-tool"
                  name="ChemoSafe Tool"
                  exact
                  onClick={closeSideMenu}
                  isSubLink
                />
              )}
              <li>
                <OpenModalButton
                  className={cn(st.logoffButton, st.navLink)}
                  onClick={closeSideMenu}
                  text="Log off"
                  modalName={MODAL_NAMES.LOGOFF}
                />
              </li>
            </ul>
          </Collapsed>
        </li>
      ) : (
        <NavLinkListElement to="/login" name="Login" exact onClick={handleClickOnListItem} />
      )}
      <NavLinkListElement to="/contact-us" name="Contact us" exact onClick={handleClickOnListItem} />
      <li className={st.listItem}>
        <Link
          to="https://www.cancer.org/about-us/policies/user-agreement.html"
          external
          target="_blank"
          className={st.navLink}
        >
          <span>Terms of use</span>
        </Link>
      </li>
      <li className={st.listItem}>
        <Link
          to="https://www.cancer.org/about-us/policies/privacy-statement.html"
          external
          target="_blank"
          className={st.navLink}
        >
          <span>Privacy Policy</span>
        </Link>
      </li>
    </ul>
  )
}

export default MenuContent
