const positions = [
  {
    name: 'Nurse',
    value: 'nurse',
  },
  {
    name: 'Physician',
    value: 'physician',
  },
  {
    name: 'Hospital administrator',
    value: 'hospital_administrator',
  },
  {
    name: 'Ministry of Health official',
    value: 'ministry_of_health_official',
  },
  {
    name: 'Other',
    value: 'other',
  },
]
export default positions
