import {User} from '../../services/types'

export type UserState = User | null
export const SET_USER = 'SET_USER'
export const DEL_USER = 'DEL_USER'
export const REMOVE_USER_PROFILE_AVATAR = 'REMOVE_USER_PROFILE_AVATAR'

interface SetUserAction {
  type: typeof SET_USER
  payload: Partial<User>
}

interface DelUserAction {
  type: typeof DEL_USER
}

interface RemoveUserProfileAvatar {
  type: typeof REMOVE_USER_PROFILE_AVATAR
}

export type UserActionTypes = SetUserAction | DelUserAction | RemoveUserProfileAvatar
