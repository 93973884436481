import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  searchInput: {
    '& fieldset': {
      border: '2px solid #97979796',
      borderRadius: '4px 0 0 4px',
      '&:hover': {
        borderColor: '#eb7643',
      },
    },
  },
  searchButton: {
    height: '56px',
    backgroundColor: '#97979796',
    borderRadius: '0px 5px 5px 0px',
    width: '100px',
    fontWeight: 600,
  },
  searchInputForm: {
    backgroundColor: '#fff',
    width: '60%',
  },
  searchBox: {
    backgroundColor: '#F3F3F3',
    padding: '20px 25px',
    marginTop: '25px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    '@media only screen and (max-width: 768px)': {padding: '5px 10px', marginTop: '10px'}
  },
  searchText: {
    color: '#3e3535b3',
    fontWeight: 600,
    marginBottom: '5px',
  },
}))
