import React, {useEffect, useState} from 'react'
import ReactTable from 'react-table'
import {
  Visibility as ViewIcon,
  PictureAsPdf as PdfIcon,
  Link as LinkIcon,
  Add,
  Edit as EditIcon,
} from '@material-ui/icons'
import {Box, Button} from '@material-ui/core'
import {ChemosafeDocument} from '../../../services/types'
import {MidtierService} from '../../../services'
import {useCSTContext} from '../context/chemosafeToolContext'
import {useStyles} from './styles'
import {EditModal, GlobalSearch, ResourceModal} from './components'

const ResourcesAndReferences = () => {
  const classes = useStyles()
  const {
    actions: {updateResources},
  }: any = useCSTContext()

  const [isLoading, setIsLoading] = useState(true)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [resources, serResources] = useState<ChemosafeDocument[]>([])
  const [globalSearch, setGlobalSearch] = useState<string>('')
  const [rowToEdit, setRowToEdit] = useState<ChemosafeDocument | undefined>(undefined)
  const [type, setType] = useState<'resource' | 'reference' | undefined>(undefined)

  const globalSearchFilter = (resources: ChemosafeDocument[]) =>
    resources.filter((resource: ChemosafeDocument) =>
      (resource?.name || '').toLowerCase().includes(globalSearch.toLowerCase()),
    )

  async function getAllResources() {
    const resources = await MidtierService.getAllChemosafeDocuments()
    serResources(resources)
    setIsLoading(false)
    return resources
  }

  const updateTable = async () => {
    setIsLoading(true)
    const updatedResources = await getAllResources()
    updateResources(updatedResources)
  }

  useEffect(() => {
    getAllResources()
  }, [])

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: ' Edit',
      accessor: '',
      sortable: false,
      width: 50,
      Cell: ({row}: {row: any}) => {
        return (
          <div className={classes.center}>
            <button
              className={classes.iconButtonStyle}
              onClick={() => {
                setIsOpenEditModal(true)
                setRowToEdit(row._original)
                setType(row._original.type)
              }}
            >
              <EditIcon />
            </button>
          </div>
        )
      },
    },
    {
      Header: 'Type',
      accessor: '',
      sortable: false,
      width: 50,
      Cell: ({row}: {row: any}) => {
        return (
          <div className={classes.center}>
            {row._original.type === 'reference' && <LinkIcon />}
            {row._original.type === 'resource' && <PdfIcon />}
          </div>
        )
      },
    },
    {
      Header: 'View',
      accessor: '',
      sortable: false,
      width: 50,
      Cell: ({row}: {row: any}) => {
        return (
          <div className={classes.center}>
            <button
              className={classes.iconButtonStyle}
              onClick={() => {
                window.open(row._original.url, '_blank')
              }}
            >
              <ViewIcon />
            </button>
          </div>
        )
      },
    },
  ]

  return (
    <Box className={classes.container}>
      <ResourceModal isOpen={isOpenModal} closeModal={() => setIsOpenModal(false)} updateTable={updateTable} />
      <EditModal
        isOpen={isOpenEditModal}
        closeModal={() => setIsOpenEditModal(false)}
        data={rowToEdit || {}}
        type={type}
        updateTable={updateTable}
      />
      <Box className={classes.searchAndAddButtonContainer}>
        <GlobalSearch setGlobalSearch={setGlobalSearch} />
        <Button className={classes.addItem} variant="outlined" startIcon={<Add />} onClick={() => setIsOpenModal(true)}>
          Add item
        </Button>
      </Box>
      <ReactTable
        columns={columns}
        data={globalSearchFilter(resources)}
        className={classes.reactTable}
        loading={isLoading}
      />
    </Box>
  )
}

export default ResourcesAndReferences
