/* eslint-disable max-lines-per-function */
import React from 'react'
import classNames from 'classnames'
import {Box, Typography, FormControl, Select, MenuItem, FormControlLabel, Checkbox, Button} from '@material-ui/core'
import RestoreIcon from '@material-ui/icons/Restore'
import {useRouteMatch, NavLink} from 'react-router-dom'
import {useCSTContext} from '../../context/chemosafeToolContext'
import {useStyles} from './styles'

interface ElementProps {
  sectionName?: string
}

const ChemoSafeToolSideBar = () => {
  const classes = useStyles()
  const {
    forms,
    filterValues,
    actions: {changeFilters},
  }: any = useCSTContext()
  const match = useRouteMatch<{id: string}>('/chemosafe-tool/:id')
  const location = match?.params.id

  return (
    <Box className={classes.root}>
      <NavLink
        to="/chemosafe-tool/dashboard"
        className={classes.link}
        activeClassName={`${location}` === 'dashboard' ? 'activeLink' : ''}
      >
        <Button className={classes.button}>
          <Typography
            variant="h2"
            className={classNames(classes.header, `${location}` === 'dashboard' ? 'activeLink' : '')}
          >
            Home
          </Typography>
        </Button>
      </NavLink>
      {location === 'dashboard' ? (
        <Box className={classes.filterBox}>
          <Typography variant="h2" className={classes.filter}>
            FILTER QUESTIONS BY
          </Typography>
          <Box className={classes.filterSubBox}>
            <Typography variant="subtitle1" className={classes.title}>
              Section
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.select}
                value={forms.filters.section}
                onChange={(e) => {
                  changeFilters({
                    section: e.target.value,
                    subSection: filterValues.find((el: ElementProps) => el.sectionName === e.target.value)
                      ?.subSections[0],
                  })
                }}
                inputProps={{'aria-label': 'Without label'}}
              >
                {filterValues?.map((element: ElementProps, index: number) => {
                  return (
                    <MenuItem key={index} value={element.sectionName} className={classes.menuItem}>
                      {element.sectionName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.filterSubBox}>
            <Typography variant="subtitle1" className={classes.title}>
              Sub-section
            </Typography>
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                className={classes.select}
                value={forms.filters.subSection}
                onChange={(e) => {
                  changeFilters({subSection: e.target.value})
                }}
                inputProps={{'aria-label': 'Without label'}}
              >
                {filterValues
                  .find((el: ElementProps) => el.sectionName === forms.filters.section)
                  ?.subSections.map((subSectionName: string, index: number) => {
                    return (
                      <MenuItem key={index} value={subSectionName} className={classes.menuItem}>
                        {subSectionName}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box className={classes.checkboxes}>
            <FormControlLabel
              checked={forms?.filters.showRecommendations}
              style={{color: 'white'}}
              control={<Checkbox style={{color: 'white'}} name="first" />}
              onChange={(e: React.ChangeEvent<any>) => changeFilters({showRecommendations: e.target.checked})}
              label="Show Recommendations"
            />
            <FormControlLabel
              checked={forms?.filters.showResources}
              style={{color: 'white'}}
              control={<Checkbox style={{color: 'white'}} name="second" />}
              onChange={(e: React.ChangeEvent<any>) => changeFilters({showResources: e.target.checked})}
              label="Show Resources"
            />
            <FormControlLabel
              checked={forms?.filters.showReferences}
              style={{color: 'white'}}
              control={<Checkbox style={{color: 'white'}} name="third" />}
              onChange={(e: React.ChangeEvent<any>) => changeFilters({showReferences: e.target.checked})}
              label="Show References"
            />
          </Box>
        </Box>
      ) : null}
      <NavLink to="/chemosafe-tool/changelog" className={classes.link} activeClassName={classes.activeLink}>
        <Button className={classes.button}>
          <RestoreIcon
            style={{fontSize: '2rem'}}
            className={classNames(classes.header, `${location}` === 'changelog' ? 'activeLink' : '')}
          />
          <Typography
            variant="h2"
            className={classNames(classes.header, `${location}` === 'changelog' ? 'activeLink' : '')}
          >
            Version Control
          </Typography>
        </Button>
      </NavLink>
      <NavLink to="/chemosafe-tool/resources" className={classes.link} activeClassName={classes.activeLink}>
        <Button className={classes.button}>
          <Typography
            variant="h2"
            className={classNames(classes.header, `${location}` === 'resources' ? 'activeLink' : '')}
          >
            Resources & References
          </Typography>
        </Button>
      </NavLink>
    </Box>
  )
}

export default ChemoSafeToolSideBar
