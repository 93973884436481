import {User} from '../../services/types'

export type AdminState = {
  users: User[]
  coalitionRequests: number
  isAdminEditUser: boolean
}

export const SET_USERS = 'SET_USERS'
export const DEL_USERS = 'DEL_USERS'
interface SetUsersAction {
  type: typeof SET_USERS
  payload: User[]
}
interface DelUsersAction {
  type: typeof DEL_USERS
}

export const SET_COALITION_REQUESTS = 'SET_COALITION_REQUESTS'

interface SetCoalitionRequests {
  type: typeof SET_COALITION_REQUESTS
  payload: number
}

export const SET_ADMIN_EDIT_USER = 'SET_ADMIN_EDIT_USER'

interface SetAdminEditUser {
  type: typeof SET_ADMIN_EDIT_USER
  payload: boolean
}

export type AdminActionTypes = SetUsersAction | DelUsersAction | SetCoalitionRequests | SetAdminEditUser
