export const calculateNewQuestionId = (
  questions: string[],
  blacklist: string[],
  section: string,
  subsection: string,
): string => {
  const ids = [...questions, ...blacklist]
  const numbers = ids.map((id) => (id ? parseInt(id.match(/\d+/g)?.[0] as string, 10) : 0))

  const firstLetters = (str: string, uppercase = true) => {
    let result = ''
    for (let i = 0; i < str.length; i++) {
      if (i === 0) result += str.charAt(i)
      if (str.charAt(i) === ' ') result += str.charAt(i + 1)
    }
    return uppercase ? result.toUpperCase() : result
  }

  return `${firstLetters(section)}_${firstLetters(subsection)}${Math.max(...numbers) + 1}`
}
