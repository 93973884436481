export const commentType = [
  {name: 'General inquiry', value: 'general-inquiry'},
  {name: 'Feedback', value: 'feedback'},
  {name: 'Technical support', value: 'technical-support'},
  {name: 'Chemotherapy safety and administration', value: 'chemotherapy-safety-and-administration'},
  {name: 'Cancer treatment guidelines', value: 'cancer-treatment-guidelines'},
  {name: 'Cancer Access Partnership', value: 'cancer-access-partnership'},
  {name: 'Pain management', value: 'pain-management'},
  {name: 'African Cancer Coalition', value: 'african-cancer-coalition'},
  {name: 'Other', value: 'other'},
  {name: 'Report a problem', value: 'report-a-problem'},
]
