import React from 'react'

import st from './Main.module.scss'

interface Props {
  id?: string
}
const Main: React.FunctionComponent<Props> = (props) => (
  <main className={st.main} id={props.id}>
    {props.children}
  </main>
)

export default Main
