import {makeStyles} from '@material-ui/core/styles'

export const useStyles = makeStyles({
  inputContainer: {
    width: '100%',
  },
  input: {
    '& input': {
      width: '100%',
    },
    '& fieldset': {
      border: '1px solid lightgray',
      borderRadius: '4px',
      '&:hover': {
        borderColor: '#eb7643',
      },
    },
    marginBottom: '10px',
  },
  select: {
    border: '1px solid lightgray',
    borderRadius: '5px',
  },
  typeTitle: {
    fontSize: '20px',
    margin: '10px 0',
    '&.error': {
      '&::after': {content: '" *"', color: 'red'},
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    fontFamily: 'Quicksand',
  },
  h2: {
    color: 'black',
  },
  iconButtonStyle: {
    border: 'none',
    background: 'none',
    width: '25px',
    height: '25px',
    alignSelf: 'flex-end',
  },

  button: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
  },

  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
  label: {
    display: 'block',
    marginBottom: '1rem',
    fontSize: '20px',
    marginTop: '20px',
  },
})
