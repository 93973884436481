export const resources = [
  {
    name: 'Log into Cancer Guidelines Navigator ',
    url: 'https://che-gateway.watsonplatform.net/watson-oncology/cancerguidelinesnavigator/wdao',
  },
  {
    name: 'One-pager',
    url: 'https://s3.us-east.cloud-object-storage.appdomain.cloud/aac-public/CGN_1-pager.pdf',
  },
  {
    name: 'Quick Guide',
    url: 'https://acs-public.s3.us-south.cloud-object-storage.appdomain.cloud/1582697675076CGN_QuickGuide.pdf',
  },
  {
    name: 'User Guide (extended version)',
    url: 'https://s3.us-east.cloud-object-storage.appdomain.cloud/aac-public/CancerGuidelinesNavigator_UserGuide.pdf',
  },
  {
    name: 'Cancer Guidelines Navigator demonstration video',
    url: 'https://www.youtube.com/watch?v=cssjCXCUUuw',
  },
  {
    name: 'FAQ / help document',
    url:
      'https://s3.us-east.cloud-object-storage.appdomain.cloud/aac-public/CancerGuidelinesNavigator_FrequentlyAskedQuestions.pdf',
  },
]

export const textPartOne = `A significant challenge in fighting cancer in Africa is the limited number of oncologists in the region, and
          the burden they face to practice the best care possible given shortages in and unevenly-distributed resources.
          A survey conducted by the African Cancer Coalition found that 65% of oncologists are treating more than 10
          types of cancer, and while they refer to guidelines, these guidelines are often written for resource
          environments dissimilar to their own.`

export const textPartTwo = `The African Cancer Coalition has collaborated with the National Comprehensive Cancer Network (NCCN®), a
          not-for-profit alliance of leading U.S. cancer centers devoted to patient care, research, and education, to
          develop the NCCN Harmonized Guidelines™ for Sub-Saharan Africa. Together, clinical professionals at NCCN
          Member Institutions and Coalition experts worked side by side to outline approaches to managing common cancer
          cases to achieve the highest standard of care with available resources. To date, 55 NCCN Guidelines® have been
          adapted to the African setting.`

export const pagePreviewDescription = `NCCN Harmonized Guidelines™ developed by National Comprehensive Cancer Network® (NCCN®) in collaboration with African oncologists to reflect the resource variations unique to practicing on the continent.`
