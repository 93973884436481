import React from 'react'
import st from '../../Tools.module.scss'

interface PlatformVariantTileProps {
  title: string
  description: string
  imageUrl: string
}
const Separator = () => {
  return <div className={st.separator} />
}

export default Separator
