import React from 'react'

import {connect} from 'react-redux'
import {Descriptive, ImageWithDescription, Link, PagePreview, Text, Title} from '../../components'
import {AppState} from '../../redux'
import {UserState} from '../../redux/user/types'
import st from './Guidelines.module.scss'
import {endorsements, pagePreviewDescription, textPartOne, textPartTwo} from './constants'

interface GuidelinesProps {
  user: UserState
}
const Guidelines = (props: GuidelinesProps) => {
  return (
    <div className={st.guidelines}>
      <PagePreview
        title="Cancer Treatment Guidelines for Sub-Saharan Africa"
        description={pagePreviewDescription}
        bgImageUrl={`${process.env.PUBLIC_URL}./images/headers/guidelines.jpg`}
      />
      <Descriptive>
        <Text>{textPartOne}</Text>
        <Text>
          {textPartTwo} Read a press release about the collaboration{' '}
          <Link
            to="https://www.nccn.org/about/news/newsinfo.aspx?NewsID=1665"
            external
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </Link>
          .
        </Text>
        <Text>
          The NCCN Harmonized Guidelines™ for Sub-Saharan Africa are available at no cost to registered users in PDF
          format through the{' '}
          <Link to="https://www.nccn.org/harmonized/" external target="_blank" rel="noopener noreferrer">
            National Comprehensive Cancer Network website
          </Link>{' '}
          and the{' '}
          <Link to="https://www.nccn.org/apps/default.aspx" external target="_blank" rel="noopener noreferrer">
            Virtual Library of NCCN Guidelines® App
          </Link>
          .
        </Text>
      </Descriptive>
      <Descriptive className={st.endorsementSection}>
        <Title subtitle type="h4">
          Endorsements
        </Title>
        <Text>
          The NCCN Harmonized Guidelines™ for Sub-Saharan Africa have been endorsed by the following governments and
          leading cancer centers.
        </Text>
        <div className={st.endorsementsImagesContainer}>
          {endorsements.map((endorsement) => (
            <ImageWithDescription
              className={st.imageWithDescription}
              key={endorsement.id}
              description={endorsement.description}
              imageUrl={endorsement.imageUrl}
            />
          ))}
        </div>
      </Descriptive>
    </div>
  )
}
const mapStateToProps = (state: AppState) => {
  return {
    user: state.user,
  }
}

export default connect(mapStateToProps)(Guidelines)
