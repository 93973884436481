import React, {RefObject, useState} from 'react'
import cn from 'classnames'
import imageCompression from 'browser-image-compression'
import {connect, useDispatch} from 'react-redux'
import Loader from 'react-loader-spinner'
import {bindActionCreators, Dispatch} from 'redux'
import {Button, Text} from '../'
import {MidtierService} from '../../services'
import {removeUserProfileAvatar, setUser} from '../../redux/user/actions'
import {addMessage} from '../../redux/alerts/actions'
import generateId from '../../utils/generateId'
import {AppActions} from '../../redux'
import st from './ImageLoader.module.scss'
export interface LoaderProps {
  error: boolean
  imageUrl?: string
}

interface ImageLoaderProps extends AppReduxInjectedProps {
  imageUrl?: string
  setImageUrl: ({error, imageUrl}: LoaderProps) => void
  label?: string
  className?: string
  userId?: string
  isSignUp?: boolean
}

const ImageLoader = ({
  isSignUp,
  imageUrl,
  setImageUrl,
  label,
  userId,
  className,
  setUser,
  addMessage,
}: ImageLoaderProps) => {
  const inputRef: RefObject<HTMLInputElement> = React.createRef()
  const [isLoad, setIsLoad] = useState<boolean>(false)
  const onButtonClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (inputRef && inputRef.current) {
      inputRef.current.click()
    }
  }

  const dispatch = useDispatch()

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null
    if (file) {
      const compressFile = await imageCompression(file, {maxSizeMB: 1, maxWidthOrHeight: 500, useWebWorker: true})
      const reader = new FileReader()
      reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
        const result = loadEvent.target ? loadEvent.target.result : ''
        if (typeof result === 'string') {
          setImageUrl({error: false, imageUrl: result})
        }
      }
      reader.onerror = () => {
        setImageUrl({error: true})
      }
      reader.readAsDataURL(compressFile)
      try {
        setIsLoad(true)
        if (isSignUp) {
          const imageUrl = await MidtierService.upload({file: compressFile})
          setImageUrl({error: false, imageUrl})
        } else {
          const user = userId
            ? await MidtierService.updateAvatarByAdmin({file: compressFile, userId})
            : await MidtierService.updateAvatar({file: compressFile})
          addMessage({message: 'Public profile image was updated', id: generateId(), type: 'success'})
          userId === undefined && setUser(user)
        }
      } catch (e) {
        setImageUrl({error: true})
      }
      setIsLoad(false)
    }
  }

  const removeImage = (e: any) => {
    e.preventDefault()
    MidtierService.deleteAvatar()
      .then(() => dispatch(removeUserProfileAvatar()))
      .then(() => addMessage({message: 'Public profile image was removed', id: generateId(), type: 'success'}))
  }

  return (
    <div className={cn(st.container, className)}>
      {label && <label className={st.label}>{label}</label>}
      <div className={st.innerContainer}>
        <div className={st.imageContainer}>
          <img className={st.image} src={imageUrl} alt="Profile" />
        </div>
        <div className={st.detailsContainer}>
          <Text className={st.description}>Please upload a head-shot photo of yourself to display</Text>
          <Button
            className={st.button}
            secondary
            onClick={onButtonClick}
            label={
              isLoad ? (
                <Loader className={st.spinner} type="TailSpin" color="#eb7346" height={24} width={24} visible={true} />
              ) : (
                'Change image'
              )
            }
          />
          {!isSignUp && <Button className={st.button} secondary onClick={removeImage} label="Remove image" />}
        </div>
      </div>
      <input id="photo" type="file" accept="image/*" className={st.input} onChange={onFileChange} ref={inputRef} />
    </div>
  )
}
const mapDispatchToProps = (dispatch: Dispatch<AppActions>) => bindActionCreators({setUser, addMessage}, dispatch)
type AppReduxInjectedProps = ReturnType<typeof mapDispatchToProps>
export default connect(null, mapDispatchToProps)(ImageLoader)
