import {Box, Typography} from '@material-ui/core'
import React from 'react'
import {ChangeLog} from '../../../../../../../../../../../services/types'

interface RecommendationTemplateProps {
  classes: any
  text: string
  type?: string
}
interface RecommendationProps {
  changelog: ChangeLog
  classes: any
}

const RecommendationTemplate = ({classes, text, type}: RecommendationTemplateProps) => {
  return (
    <Box className={classes.bodySection}>
      <Typography className={classes.bodyTitle} variant="body2">
        {type && type} Recommendation
      </Typography>
      <Typography>{text ? text : 'No Recommendation'}</Typography>
    </Box>
  )
}

const Recommendation = ({classes, changelog}: RecommendationProps) => {
  switch (changelog.type) {
    case 'EDIT_QUESTION_RECOMMENDATION_TEXT':
      return (
        <>
          <RecommendationTemplate classes={classes} text={changelog.oldQuestion.recommendationText} type="Old" />
          <RecommendationTemplate classes={classes} text={changelog.newQuestion.recommendationText} type="New" />
        </>
      )
    case 'DELETE_QUESTION':
      return <RecommendationTemplate classes={classes} text={changelog.oldQuestion.recommendationText} type="Old" />
    default:
      return <RecommendationTemplate classes={classes} text={changelog.newQuestion.recommendationText} />
  }
}
export default Recommendation
