import React from 'react'
import {ArrayHelpers} from 'formik'
import {Checkbox, ErrorMessage} from '../../index'
import st from './Checkboxes.module.scss'

interface CheckboxesProps {
  label?: string
  arrayHelpers: ArrayHelpers
  selectedValues: string[]
  error?: string
  tips?: string
  options: {
    name: string
    value: string
  }[]
}

const Checkboxes = (props: CheckboxesProps) => {
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    const isSelected = event.target.checked
    if (isSelected) {
      if (props.selectedValues.length < 3) props.arrayHelpers.push(value)
    } else {
      const index = props.selectedValues.indexOf(value)
      props.arrayHelpers.remove(index)
    }
  }

  return (
    <div className={st.container}>
      {props.label && <label className={st.label}>{props.label}</label>}
      {props.tips && <p className={st.tips}>* Tip: {props.tips}</p>}
      {props.error && <ErrorMessage error={props.error} className={st.errorMessage} />}
      {props.options.map((option) => (
        <div key={option.value} className={st.checkboxContainer}>
          <Checkbox
            checked={props.selectedValues.includes(option.value)}
            label={option.name}
            id={option.value}
            onChange={(event) => onChangeHandler(event, option.value)}
          />
        </div>
      ))}
    </div>
  )
}

export default Checkboxes
