import {makeStyles} from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  sectionBox: {
    padding: '16px 0',
    borderBottom: '2px solid #EB7346',
    display: 'flex',
    alignItems: 'center',
  },
  sectionSubBox: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    flex: 3,
    '@media only screen and (max-width: 768px)': {fontSize: '0.8rem'}
  },
  saveButton:{
    alignSelf: 'flex-end',
    marginRight: '20px', 
    minWidth: '150px',
    '@media only screen and (max-width: 768px)': {minWidth: '80px', fontSize: '0.8rem', marginRight: '5px'}
  },
  addNewQuesButton:{
    textTransform: 'none', 
    color: '#EB7346',
    '@media only screen and (max-width: 768px)': {fontSize: '0.8rem'}
  },
  adminBox: {
    borderBottom: '1px solid #eee',
  },
  adminTitle: {
    color: '#000000',
    marginBottom: '14px',
  },
  adminText: {
    textAlign: 'justify',
    lineHeight: '1.6',
  },
  dashboard: {
    height: '70vh !important' as string,
    overflowX: 'auto !important' as 'auto',
    transition: 'background-color 0.2s ease, opacity 0.1s ease',
    paddingBottom: 0,
    userSelect: 'none !important' as 'none',
    width: '100%',
    padding: '8px',
    border: '8px',
  },
}))
