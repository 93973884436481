import React, {Fragment} from 'react'
import {Typography} from '@material-ui/core'
import {useCSTContext} from '../../../context/chemosafeToolContext'
import {Question} from '../../../../../services/types'

interface SearchResultsProps {
  renderItem: (question: Question) => React.ReactNode | null
}

export const getSearchResults = (searchValue: string, values: Question[], matchField: string): Question[] | null => {
  const checkName = (name: string, str: string) => {
    const pattern = str
      .split('')
      .map((x) => {
        return `(?=.*${x})`
      })
      .join('')
    const regex = new RegExp(`${pattern}`, 'g')
    return name.match(regex)
  }

  const str = searchValue.toLowerCase().substring(0, 3)
  const filteredArr = values.filter((x) => {
    const xValue = x[matchField]
    const xSub = xValue.substring(0, 3).toLowerCase()
    return xValue.toLowerCase().includes(str) || checkName(xSub, str)
  })

  return filteredArr?.length > 0 ? filteredArr : null
}

const DashboardSearchResults = ({renderItem}: SearchResultsProps) => {
  const {
    dashboard,
    forms: {
      filters: {questionName},
    },
  }: any = useCSTContext()
  const searchResults = getSearchResults(questionName || '', dashboard?.questions || [], 'questionText')
  return (
    <Fragment>
      {questionName && searchResults?.length && searchResults?.map((question: Question) => renderItem(question))}
      {questionName && !searchResults?.length && <DashboardSearchResults.Fallback />}
    </Fragment>
  )
}

DashboardSearchResults.Fallback = () => <Typography>No search results</Typography>

export default DashboardSearchResults
