import cn from 'classnames'
import React from 'react'

import st from './BurgerButton.module.scss'

interface BurgerButtonProps {
  onClick: () => void
}

const BurgerButton = ({onClick}: BurgerButtonProps) => {
  return (
    <div className={st.burgerButton}>
      <span>
        <span className={cn(st.line, st.first)} />
        <span className={cn(st.line, st.second)} />
        <span className={cn(st.line, st.third)} />
      </span>
      <button onClick={onClick} />
    </div>
  )
}

export default BurgerButton
