import React, {ReactElement, useEffect} from 'react'
import {connect} from 'react-redux'

import {bindActionCreators, Dispatch} from 'redux'
import {AppActions, AppState} from '../../../redux'
import {setActiveModal, closeModal} from '../../../redux/modals/actions'
import {MODAL_NAMES} from '../../../redux/modals/types'
import {ModalConductor} from '../..'

interface OwnProps {
  className: string
  text: string | ReactElement
  modalName: MODAL_NAMES
  onClick?: () => void
}

const OpenModalButton = ({
  modalName,
  setActiveModal,
  className,
  text,
  onClick,
  closeModal,
}: AppReduxInjectedProps & OwnProps) => {
  useEffect(() => {
    return () => {
      closeModal()
    }
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <button
        className={className}
        onClick={() => {
          onClick && onClick()
          setActiveModal(modalName)
        }}
      >
        {text}
      </button>
      <ModalConductor />
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    modals: state.modals,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<AppActions>) =>
  bindActionCreators(
    {
      setActiveModal,
      closeModal,
    },
    dispatch,
  )

type AppReduxInjectedProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>

export default connect(mapStateToProps, mapDispatchToProps)(OpenModalButton)
